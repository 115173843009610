import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import boardTypeApi from "../../api/boardTypeApi";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import companyKpiApi from "../../api/companyKpiApi";
import structureApi from "../../api/structureApi";
import CompanyKpiModal from "../../components/common/CompanyKpiModal";
import assets from "../../assets/index";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Moment from "moment";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EmojiPicker from "../../components/common/EmojiPicker";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

let timer;
const timeout = 500;

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "icon",
    numeric: false,
    disablePadding: true,
    label: "Icon",
  },
  {
    id: "indicator",
    numeric: false,
    disablePadding: true,
    label: "Indicator",
  },
  {
    id: "percentageWeight",
    numeric: false,
    disablePadding: true,
    label: "Weight",
  },
  {
    id: "annualTarget",
    numeric: false,
    disablePadding: true,
    label: "Target",
  },
  {
    id: "annualRealization",
    numeric: false,
    disablePadding: true,
    label: "Current Realization",
  },
  {
    id: "formula",
    numeric: false,
    disablePadding: true,
    label: "Formula",
  },
  // {
  //   id: "users",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Verified",
  // },
  {
    id: "jan",
    numeric: false,
    disablePadding: true,
    label: "Jan",
  },
  {
    id: "feb",
    numeric: false,
    disablePadding: true,
    label: "Feb",
  },
  {
    id: "mar",
    numeric: false,
    disablePadding: true,
    label: "Mar",
  },
  {
    id: "apr",
    numeric: false,
    disablePadding: true,
    label: "Apr",
  },
  {
    id: "may",
    numeric: false,
    disablePadding: true,
    label: "May",
  },
  {
    id: "jun",
    numeric: false,
    disablePadding: true,
    label: "Jun",
  },
  {
    id: "jul",
    numeric: false,
    disablePadding: true,
    label: "Jul",
  },
  {
    id: "aug",
    numeric: false,
    disablePadding: true,
    label: "Aug",
  },
  {
    id: "sep",
    numeric: false,
    disablePadding: true,
    label: "Sep",
  },
  {
    id: "oct",
    numeric: false,
    disablePadding: true,
    label: "Oct",
  },
  {
    id: "nov",
    numeric: false,
    disablePadding: true,
    label: "Nov",
  },
  {
    id: "dec",
    numeric: false,
    disablePadding: true,
    label: "Dec",
  },
  {
    id: "score",
    numeric: false,
    disablePadding: true,
    label: "Score",
  },
  {
    id: "totalScore",
    numeric: false,
    disablePadding: true,
    label: "Total Score",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showDialogDeleteKpi, selected, requestSearch, searchFilter } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormatListBulletedIcon sx={{ mr: 1 }} /> List Key Performance
          Indicator
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => showDialogDeleteKpi(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? assets.colors.inputTypeDark
                  : assets.colors.inputTypeLight,
              mt: 2,
              mr: 2,
              width: "300px",
            }}
            value={searchFilter}
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Search indicator..."
            type="search"
            onInput={(e) => requestSearch(e.target.value)}
          />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CompanyKpis = () => {
  const theme = useTheme();
  const [finalScore, setFinalScore] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [selectedKpi, setSelectedKpi] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [annualTargetNumber, setAnnualTargetNumber] = useState(false);
  const [annualTargetPercentage, setAnnualTargetPercentage] = useState(false);
  const [icon, setIcon] = useState("📃");

  const [showDiv, setShowDiv] = useState(false);
  const [year, setYear] = useState(dayjs(Moment()));

  const [boardTypes, setBoardTypes] = useState([]);
  const [boardType, setBoardType] = useState("");
  const [loading, setLoading] = useState(false);
  const [boardTypeErrText, setBoardTypeErrText] = useState("");
  const [indicatorErrText, setIndicatorErrText] = useState("");
  const [annualTargetErrText, setAnnualTargetErrText] = useState("");
  const [annualTargetTypeErrText, setAnnualTargetTypeErrText] = useState("");
  const [formulaErrText, setFormulaErrText] = useState("");
  const [percentageWeightErrText, setPercentageWeightErrText] = useState("");

  const [percentageWeightVal, setPercentageWeightVal] = useState(0);
  const [tresholdPercentageWeight, setTresholdPercentageWeight] = useState(0);
  const [lastPercentageWeight, setLastPercentageWeight] = useState(0);

  const [copyList, setCopyList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const requestSearch = (searched) => {
    setCopyList(rows.filter((item) => item.indicator.toString().toLowerCase().includes(searched)));
    setSearchFilter(searched);
  };

  const onUpdateKpi = (kpi) => {
    console.log(kpi);

    console.log(tresholdPercentageWeight);
    console.log(kpi.lastPercentageWeight);
    console.log(kpi.percentageWeight);
    if (kpi.lastPercentageWeight !== undefined) {
      if (lastPercentageWeight !== kpi.percentageWeight) {
        const tresholdAbs = Math.abs(
          parseInt(tresholdPercentageWeight) +
            parseInt(kpi.lastPercentageWeight)
        );
        setTresholdPercentageWeight(
          parseInt(tresholdAbs) - parseInt(kpi.percentageWeight)
        );
        setLastPercentageWeight(kpi.percentageWeight);
      }
    }
    const newData = [...rows];
    const kpiIndex = newData.findIndex((e) => e._id === kpi.id);
    newData[kpiIndex].indicator = kpi.indicator;
    newData[kpiIndex].icon = kpi.icon;
    newData[kpiIndex].percentageWeight = kpi.percentageWeight;
    newData[kpiIndex].annualTarget = kpi.annualTarget;
    newData[kpiIndex].annualTargetType = kpi.annualTargetType;
    newData[kpiIndex].additionType = kpi.additionType;
    newData[kpiIndex].kpiType = kpi.kpiType;

    let realizationDone = 0;
    let realizationTotal = 0;
    let lastNumber = [];
    let progjan = "";
    let progfeb = "";
    let progmar = "";
    let progapr = "";
    let progmay = "";
    let progjun = "";
    let progjul = "";
    let progaug = "";
    let progsep = "";
    let progoct = "";
    let prognov = "";
    let progdec = "";

    if (kpi.janType === 0) {
      const kpiNum = kpi.janNum;
      progjan = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progjan = kpi.progjan;
      if (kpi.progjan !== undefined && kpi.progjan !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progjan.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progjan.split("/ ")[1]);
        if (parseInt(kpi.progjan.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progjan.split(" /")[0]));
        }
      }
    }

    if (kpi.augType === 0) {
      const kpiNum = kpi.augNum;
      progaug = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progaug = kpi.progaug;
      if (kpi.progaug !== undefined && kpi.progaug !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progaug.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progaug.split("/ ")[1]);
        if (parseInt(kpi.progaug.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progaug.split(" /")[0]));
        }
      }
    }
    if (kpi.febType === 0) {
      const kpiNum = kpi.febNum;
      progfeb = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progfeb = kpi.progfeb;
      if (kpi.progfeb !== undefined && kpi.progfeb !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progfeb.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progfeb.split("/ ")[1]);
        if (parseInt(kpi.progfeb.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progfeb.split(" /")[0]));
        }
      }
    }
    if (kpi.marType === 0) {
      const kpiNum = kpi.marNum;
      progmar = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progmar = kpi.progmar;
      if (kpi.progmar !== undefined && kpi.progmar !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progmar.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progmar.split("/ ")[1]);
        if (parseInt(kpi.progmar.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progmar.split(" /")[0]));
        }
      }
    }
    if (kpi.aprType === 0) {
      const kpiNum = kpi.aprNum;
      progapr = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progapr = kpi.progapr;
      if (kpi.progapr !== undefined && kpi.progapr !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progapr.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progapr.split("/ ")[1]);
        if (parseInt(kpi.progapr.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progapr.split(" /")[0]));
        }
      }
    }
    if (kpi.mayType === 0) {
      const kpiNum = kpi.mayNum;
      progmay = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progmay = kpi.progmay;
      if (kpi.progmay !== undefined && kpi.progmay !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progmay.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progmay.split("/ ")[1]);
        if (parseInt(kpi.progmay.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progmay.split(" /")[0]));
        }
      }
    }
    if (kpi.junType === 0) {
      const kpiNum = kpi.junNum;
      progjun = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progjun = kpi.progjun;
      if (kpi.progjun !== undefined && kpi.progjun !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progjun.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progjun.split("/ ")[1]);
        if (parseInt(kpi.progjun.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progjun.split(" /")[0]));
        }
      }
    }
    if (kpi.julType === 0) {
      const kpiNum = kpi.julNum;
      progjul = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progjul = kpi.progjul;
      if (kpi.progjul !== undefined && kpi.progjul !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progjul.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progjul.split("/ ")[1]);
        if (parseInt(kpi.progjul.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progjul.split(" /")[0]));
        }
      }
    }
    if (kpi.sepType === 0) {
      const kpiNum = kpi.sepNum;
      progsep = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progsep = kpi.progsep;
      if (kpi.progsep !== undefined && kpi.progsep !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progsep.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progsep.split("/ ")[1]);
        if (parseInt(kpi.progsep.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progsep.split(" /")[0]));
        }
      }
    }
    if (kpi.octType === 0) {
      const kpiNum = kpi.octNum;
      progoct = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progoct = kpi.progoct;
      if (kpi.progoct !== undefined && kpi.progoct !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progoct.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progoct.split("/ ")[1]);
        if (parseInt(kpi.progoct.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progoct.split(" /")[0]));
        }
      }
    }
    if (kpi.novType === 0) {
      const kpiNum = kpi.novNum;
      prognov = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      prognov = kpi.prognov;
      if (kpi.prognov !== undefined && kpi.prognov !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.prognov.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.prognov.split("/ ")[1]);
        if (parseInt(kpi.prognov.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.prognov.split(" /")[0]));
        }
      }
    }
    if (kpi.decType === 0) {
      const kpiNum = kpi.decNum;
      progdec = kpiNum + " / " + kpiNum;
      realizationDone = parseInt(realizationDone) + parseInt(kpiNum);
      realizationTotal = parseInt(realizationTotal) + parseInt(kpiNum);
      lastNumber.unshift(parseInt(kpiNum));
    } else {
      progdec = kpi.progdec;
      if (kpi.progdec !== undefined && kpi.progdec !== "") {
        realizationDone =
          parseInt(realizationDone) + parseInt(kpi.progdec.split(" /")[0]);
        realizationTotal =
          parseInt(realizationTotal) + parseInt(kpi.progdec.split("/ ")[1]);
        if (parseInt(kpi.progdec.split(" /")[0]) !== 0) {
          lastNumber.unshift(parseInt(kpi.progdec.split(" /")[0]));
        }
      }
    }

    newData[kpiIndex].jan = progjan;
    newData[kpiIndex].feb = progfeb;
    newData[kpiIndex].mar = progmar;
    newData[kpiIndex].apr = progapr;
    newData[kpiIndex].may = progmay;
    newData[kpiIndex].jun = progjun;
    newData[kpiIndex].jul = progjul;
    newData[kpiIndex].aug = progaug;
    newData[kpiIndex].sep = progsep;
    newData[kpiIndex].oct = progoct;
    newData[kpiIndex].nov = prognov;
    newData[kpiIndex].dec = progdec;

    newData[kpiIndex].perjan = kpi.progperjan;
    newData[kpiIndex].perfeb = kpi.progperfeb;
    newData[kpiIndex].permar = kpi.progpermar;
    newData[kpiIndex].perapr = kpi.progperapr;
    newData[kpiIndex].permay = kpi.progpermay;
    newData[kpiIndex].perjun = kpi.progperjun;
    newData[kpiIndex].perjul = kpi.progperjul;
    newData[kpiIndex].peraug = kpi.progperaug;
    newData[kpiIndex].persep = kpi.progpersep;
    newData[kpiIndex].peroct = kpi.progperoct;
    newData[kpiIndex].pernov = kpi.progpernov;
    newData[kpiIndex].perdec = kpi.progperdec;

    newData[kpiIndex].janType = kpi.janType;
    newData[kpiIndex].febType = kpi.febType;
    newData[kpiIndex].marType = kpi.marType;
    newData[kpiIndex].aprType = kpi.aprType;
    newData[kpiIndex].mayType = kpi.mayType;
    newData[kpiIndex].junType = kpi.junType;
    newData[kpiIndex].julType = kpi.julType;
    newData[kpiIndex].augType = kpi.augType;
    newData[kpiIndex].sepType = kpi.sepType;
    newData[kpiIndex].octType = kpi.octType;
    newData[kpiIndex].novType = kpi.novType;
    newData[kpiIndex].decType = kpi.decType;

    // if (kpi.annualTargetType === 0) {
    //   newData[kpiIndex].annualRealization = realizationDone;
    // } else {
    //   newData[kpiIndex].annualRealization =
    //     (
    //       (parseInt(realizationDone) / parseInt(kpi.annualTarget)) *
    //       100
    //     ).toFixed(2) + "%";
    // }

    // if (kpi.additionType === 0) {
    //   newData[kpiIndex].score = (
    //     parseInt(realizationDone) / parseInt(kpi.annualTarget)
    //   ).toFixed(2);
    //   newData[kpiIndex].totalScore = (
    //     (parseInt(realizationDone) / parseInt(kpi.annualTarget)) *
    //     parseInt(kpi.percentageWeight)
    //   ).toFixed(2);
    // } else if (kpi.additionType === 1) {
    //   newData[kpiIndex].score = lastNumber[0];
    //   newData[kpiIndex].totalScore = "";
    // } else {
    //   newData[kpiIndex].score = (
    //     (parseInt(realizationDone) / parseInt(realizationTotal)) *
    //     100
    //   ).toFixed(2);
    //   newData[kpiIndex].totalScore = (
    //     (parseInt(realizationDone) / parseInt(realizationTotal)) *
    //     parseInt(kpi.percentageWeight)
    //   ).toFixed(2);
    // }

    if (kpi.additionType === 0) {
      newData[kpiIndex].annualRealization = realizationDone;
      newData[kpiIndex].totalScore = (
        (parseInt(realizationDone) / parseInt(kpi.annualTarget)) *
        parseInt(kpi.percentageWeight)
      ).toFixed(2);
    } else if (kpi.additionType === 1) {
      newData[kpiIndex].annualRealization = lastNumber[0];
      newData[kpiIndex].totalScore = (
        (parseInt(realizationDone) / parseInt(kpi.annualTarget)) *
        parseInt(kpi.percentageWeight)
      ).toFixed(2);
    } else if (kpi.additionType === 2) {
      if (realizationDone === 0 && realizationTotal === 0) {
        newData[kpiIndex].annualRealization = "0%";
        newData[kpiIndex].totalScore = 0;
      } else {
        newData[kpiIndex].annualRealization =
          (
            (parseInt(realizationDone) / parseInt(realizationTotal)) *
            100
          ).toFixed(2) + "%";
        newData[kpiIndex].totalScore = (
          (((parseInt(realizationDone) / parseInt(realizationTotal)) * 100) /
            parseInt(kpi.annualTarget)) *
          parseInt(kpi.percentageWeight)
        ).toFixed(2);
      }
    }

    if (kpi.kpiType === 1) {
      if (kpi.additionType === 2) {
        if (realizationDone === 0 && realizationTotal === 0) {
          newData[kpiIndex].score = 0;
        } else {
          newData[kpiIndex].score = (
            ((parseInt(realizationDone) / parseInt(realizationTotal)) * 100) /
            parseInt(kpi.annualTarget)
          ).toFixed(2);
        }
      } else {
        newData[kpiIndex].score = (
          parseInt(realizationDone) / parseInt(kpi.annualTarget)
        ).toFixed(2);
      }
    } else if (kpi.kpiType === 0) {
      if (kpi.additionType === 2) {
        if (realizationDone !== 0) {
          newData[kpiIndex].score = (
            parseInt(kpi.annualTarget) /
            ((parseInt(realizationDone) / parseInt(realizationTotal)) * 100)
          ).toFixed(2);
        } else {
          newData[kpiIndex].score = "0.00";
        }
      } else {
        if (realizationDone !== 0) {
          newData[kpiIndex].score = (
            parseInt(kpi.annualTarget) / parseInt(realizationDone)
          ).toFixed(2);
        } else {
          newData[kpiIndex].score = "0.00";
        }
      }
    }

    setRows(newData);
    setCopyList(newData);
  };

  const onIconChange = async (newIcon) => {
    setIcon(newIcon);
  };

  const deleteCompanyKpis = (kpis) => {
    kpis.forEach(async (value) => {
      try {
        const newData = [...rows];
        const kpiIndex = newData.findIndex((e) => e._id === value);
        let totalPercentageWeight =
          parseInt(newData[kpiIndex].percentageWeight) +
          parseInt(tresholdPercentageWeight);
        setTresholdPercentageWeight(totalPercentageWeight);

        const deleteJson = await companyKpiApi.delete(value);
        if (deleteJson === "deleted") {
          const newData = [...rows].filter((e) => !kpis.includes(e.id));
          setRows(newData);
          setCopyList(newData);
          setSelected([]);
          hideDialog();
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const getCompanyKpis = async () => {
      try {
        const jsonData = [];
        const res = await companyKpiApi.getAll(year.format("YYYY"));
        let totalPercentageWeight = 0;
        let finalScore = 0;
        res.forEach((value, index) => {
          finalScore = finalScore + parseFloat(value.totalScore);
          totalPercentageWeight =
            totalPercentageWeight + value.percentageWeight;

          jsonData.push({
            _id: value.id,
            id: value.id,
            indicator: value.indicator,
            percentageWeight: value.percentageWeight,
            icon: value.icon,
            annualTarget: value.annualTarget,
            annualTargetType: value.annualTargetType,
            formula: value.formula,
            annualRealization: value.annualRealization,
            users: value.users,
            score: value.score,
            totalScore: value.totalScore,
            additionType: value.additionType,
            kpiType: value.kpiType,
            jan: value.progjan,
            feb: value.progfeb,
            mar: value.progmar,
            apr: value.progapr,
            may: value.progmay,
            jun: value.progjun,
            jul: value.progjul,
            aug: value.progaug,
            sep: value.progsep,
            oct: value.progoct,
            nov: value.prognov,
            dec: value.progdec,
            perjan: value.progperjan,
            perfeb: value.progperfeb,
            permar: value.progpermar,
            perapr: value.progperapr,
            permay: value.progpermay,
            perjun: value.progperjun,
            perjul: value.progperjul,
            peraug: value.progperaug,
            persep: value.progpersep,
            peroct: value.progperoct,
            pernov: value.progpernov,
            perdec: value.progperdec,
            janType: value.janType,
            febType: value.febType,
            marType: value.marType,
            aprType: value.aprType,
            mayType: value.mayType,
            junType: value.junType,
            julType: value.julType,
            augType: value.augType,
            sepType: value.sepType,
            octType: value.octType,
            novType: value.novType,
            decType: value.decType,
            score: value.score,
            totalScore: value.totalScore,
            createdAt: value.createdAt,
            action: (
              <Tooltip title="Edit">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedKpi(value);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ),
          });
        });

        const tresholdPercentageWeight = 100 - totalPercentageWeight;
        console.log(tresholdPercentageWeight);
        setTresholdPercentageWeight(tresholdPercentageWeight);

        setFinalScore(finalScore.toFixed(2));
        setTotalWeight(totalPercentageWeight);

        setRows(jsonData);
        setCopyList(jsonData);
      } catch (err) {
        console.log(err);
      }
      setShowDiv(true);
    };
    getCompanyKpis();

    const getBoardTypes = async () => {
      try {
        const res = await boardTypeApi.getAll();
        setBoardTypes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getBoardTypes();
  }, []);

  const updatePercentageWeightVal = async (e, lastValue) => {
    if (e.target.value === "" || e.target.value < 0) {
      e.target.value = 0;
    } else if (e.target.value > tresholdPercentageWeight) {
      e.target.value = lastValue;
    } else if (e.target.value.length > 0) {
      e.target.value = parseInt(e.target.value);
    }
    setPercentageWeightVal(e.target.value);
  };

  const selectYear = async (e) => {
    const year = e;
    setYear(year);
    timer = setTimeout(async () => {
      try {
        const jsonData = [];
        const res = await companyKpiApi.getAll(year.format("YYYY"));
        let totalPercentageWeight = 0;
        let finalScore = 0;
        res.forEach((value, index) => {
          finalScore = finalScore + parseFloat(value.totalScore);
          totalPercentageWeight =
            totalPercentageWeight + value.percentageWeight;

          jsonData.push({
            _id: value.id,
            id: value.id,
            indicator: value.indicator,
            percentageWeight: value.percentageWeight,
            icon: value.icon,
            annualTarget: value.annualTarget,
            annualTargetType: value.annualTargetType,
            formula: value.formula,
            annualRealization: value.annualRealization,
            users: value.users,
            score: value.score,
            totalScore: value.totalScore,
            additionType: value.additionType,
            kpiType: value.kpiType,
            jan: value.progjan,
            feb: value.progfeb,
            mar: value.progmar,
            apr: value.progapr,
            may: value.progmay,
            jun: value.progjun,
            jul: value.progjul,
            aug: value.progaug,
            sep: value.progsep,
            oct: value.progoct,
            nov: value.prognov,
            dec: value.progdec,
            perjan: value.progperjan,
            perfeb: value.progperfeb,
            permar: value.progpermar,
            perapr: value.progperapr,
            permay: value.progpermay,
            perjun: value.progperjun,
            perjul: value.progperjul,
            peraug: value.progperaug,
            persep: value.progpersep,
            peroct: value.progperoct,
            pernov: value.progpernov,
            perdec: value.progperdec,
            janType: value.janType,
            febType: value.febType,
            marType: value.marType,
            aprType: value.aprType,
            mayType: value.mayType,
            junType: value.junType,
            julType: value.julType,
            augType: value.augType,
            sepType: value.sepType,
            octType: value.octType,
            novType: value.novType,
            decType: value.decType,
            score: value.score,
            totalScore: value.totalScore,
            createdAt: value.createdAt,
            action: (
              <Tooltip title="Edit">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedKpi(value);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ),
          });
        });

        const tresholdPercentageWeight = 100 - totalPercentageWeight;
        console.log(tresholdPercentageWeight);
        setTresholdPercentageWeight(tresholdPercentageWeight);

        setFinalScore(finalScore.toFixed(2));
        setTotalWeight(totalPercentageWeight);

        setRows(jsonData);
        setCopyList(jsonData);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setShowDiv(true);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const visibleRowsCopy = useMemo(
    () =>
      stableSort(copyList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, copyList]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIndicatorErrText("");

    const data = new FormData(e.target);
    const indicator = data.get("indicator").trim();
    const formula = data.get("formula").trim();
    const annualTarget = data.get("annualTarget").trim();
    const annualTargetType = data.get("annualTargetType").trim();
    const percentageWeight = data.get("percentageWeight").trim();
    const additionType = data.get("additionType").trim();
    const kpiType = data.get("kpiType").trim();

    let totalPercentageWeight =
      parseInt(tresholdPercentageWeight) - parseInt(percentageWeight);
    setTresholdPercentageWeight(totalPercentageWeight);
    setPercentageWeightVal(0);

    let err = false;

    if (indicator === "") {
      err = true;
      setIndicatorErrText("Please fill this field");
    }

    if (formula === "") {
      err = true;
      setFormulaErrText("Please fill this field");
    }

    if (annualTarget === "") {
      err = true;
      setAnnualTargetErrText("Please fill this field");
    }

    if (annualTargetType === "") {
      err = true;
      setAnnualTargetTypeErrText("Please fill this field");
    }

    if (percentageWeight === "") {
      err = true;
      setPercentageWeightErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await companyKpiApi.create({
        indicator,
        year: year.format("YYYY"),
        icon,
        percentageWeight,
        annualTargetType: annualTargetType === "number" ? 0 : 1,
        annualTarget,
        formula,
        additionType:
          annualTargetType === "accumulative"
            ? 0
            : annualTargetType === "last number"
            ? 1
            : 2,
        kpiType: annualTargetType === "minimize" ? 0 : 1,
      });
      setLoading(false);
      const newJsonData = {
        _id: res.id,
        id: res.id,
        indicator: res.indicator,
        percentageWeight: res.percentageWeight,
        icon: res.icon,
        annualTarget: res.annualTarget,
        annualTargetType: res.annualTargetType,
        formula: res.formula,
        annualRealization: res.annualRealization,
        users: res.users,
        score: res.score,
        totalScore: res.totalScore,
        createdAt: res.createdAt,
        action: (
          <Tooltip title="Edit">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedKpi(res);
              }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
      };
      const newData = [...rows];
      newData.unshift(newJsonData);
      console.log(newData);
      setRows(newData);
      setCopyList(newData);
      e.target.reset();
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "name") {
          setIndicatorErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  const [openDialogDeleteKpi, setOpenDialogDeleteKpi] = useState(false);
  const [openDialogDeleteFail, setOpenDialogDeleteFail] = useState(false);
  const [idDeleteKpi, setIdDeleteKpi] = useState(false);

  const showDialogDeleteKpi = (sectionId) => {
    setOpenDialogDeleteKpi(true);
    setIdDeleteKpi(sectionId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteKpi(false);
    setOpenDialogDeleteFail(false);
  };

  const selectAnnualTarget = async (e) => {
    if (e.target.value === "number") {
      setAnnualTargetNumber(true);
      setAnnualTargetPercentage(false);
    } else if (e.target.value === "percentage") {
      setAnnualTargetNumber(false);
      setAnnualTargetPercentage(true);
    }
  };

  return (
    <>
      {openDialogDeleteKpi && (
        <Dialog
          open={openDialogDeleteKpi}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete kpi
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteCompanyKpis(idDeleteKpi)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          // width: "100%",
          margin: "5px 25px 0px 25px",
        }}
      >
        <Typography
          sx={{
            padding: 0,
            fontSize: "1.5rem",
            fontWeight: "500",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          🗒️ Manage Company's Key Performance Indicator
        </Typography>
        <Typography
          sx={{
            padding: 0,
            marginTop: "2px",
            fontSize: "14px",
            fontWeight: "400",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          <i>
            Manage all key performance indicator in the zabran scrum board
            application
          </i>
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            m: 2,
            p: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                display: "flex",
                alignItems: "center",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Select Year
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={year}
                onChange={(e) => {
                  selectYear(e);
                }}
                dateFormat="yyyy"
                views={["year"]}
                sx={{
                  width: "100%",
                  mr: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "8.5px 0px 8.5px 8.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Paper>
      </Box>
      {showDiv && (
        <Box
          sx={{
            // width: "100%",
            margin: "0px 10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Paper
            sx={{
              width: "30%",
              m: 2,
              p: 2,
              border:
                theme.palette.mode === "dark"
                  ? "1px solid " + assets.colors.borderDark
                  : "1px solid " + assets.colors.borderLight,
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                display: "flex",
                alignItems: "center",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <AddOutlinedIcon sx={{ mr: 1 }} /> Add Key Performance Indicator
            </Typography>
            {tresholdPercentageWeight === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 5,
                }}
              >
                Percentage Weight is 100%
              </Box>
            ) : (
              <Box
                component="form"
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
                noValidate
              >
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Icon <span style={{ color: "red" }}>*</span>
                </Typography>
                <EmojiPicker icon={icon} onChange={onIconChange} />
                <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
                  Indicator <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="indicator"
                    name="indicator"
                    disabled={loading}
                    error={indicatorErrText !== ""}
                    helperText={indicatorErrText}
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                  />
                </Box>
                <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
                  Formula <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="formula"
                    name="formula"
                    disabled={loading}
                    error={formulaErrText !== ""}
                    helperText={formulaErrText}
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Percentage Weight (Max {tresholdPercentageWeight}%)
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    type="number"
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    value={percentageWeightVal}
                    onChange={(e) => {
                      updatePercentageWeightVal(e, percentageWeightVal);
                    }}
                    id="percentageWeight"
                    name="percentageWeight"
                    disabled={loading}
                    error={percentageWeightErrText !== ""}
                    helperText={percentageWeightErrText}
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: "rgb(108, 108, 108)",
                  }}
                >
                  Target Type
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="annualTargetType"
                >
                  {["number", "percentage"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      onChange={(e) => {
                        selectAnnualTarget(e);
                      }}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
                {annualTargetNumber && (
                  <>
                    <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
                      Target Number <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        gap: "1px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        type="number"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="annualTarget"
                        name="annualTarget"
                        disabled={loading}
                        error={annualTargetErrText !== ""}
                        helperText={annualTargetErrText}
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                          mt: 0,
                        }}
                      />
                    </Box>
                  </>
                )}
                {annualTargetPercentage && (
                  <>
                    <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
                      Target Percentage (%)
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        gap: "1px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        type="number"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="annualTarget"
                        name="annualTarget"
                        disabled={loading}
                        error={annualTargetErrText !== ""}
                        helperText={annualTargetErrText}
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                          mt: 0,
                        }}
                      />
                    </Box>
                  </>
                )}
                <Typography
                  sx={{
                    color: "rgb(108, 108, 108)",
                  }}
                >
                  Addition Type
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="additionType"
                >
                  {["accumulative", "last number", "average"].map(
                    (value, index) => (
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    )
                  )}
                </RadioGroup>
                <Typography
                  sx={{
                    color: "rgb(108, 108, 108)",
                  }}
                >
                  KPI Type
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="kpiType"
                >
                  {["minimize", "maximize"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
                <LoadingButton
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} /> Create Kpi
                </LoadingButton>
              </Box>
            )}
          </Paper>
          <Paper
            sx={{
              width: "70%",
              overflowX: "auto",
              m: 2,
              border:
                theme.palette.mode === "dark"
                  ? "1px solid " + assets.colors.borderDark
                  : "1px solid " + assets.colors.borderLight,
            }}
          >
            <EnhancedTableToolbar
              numSelected={selected.length}
              showDialogDeleteKpi={showDialogDeleteKpi}
              requestSearch={requestSearch}
            searchFilter={searchFilter}
              selected={selected}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                {(visibleRowsCopy.length > 0 ? visibleRowsCopy : visibleRows).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.icon}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.indicator}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.percentageWeight}%
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTarget}
                          {row.annualTargetType === 1 ? "%" : ""}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualRealization}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.formula}
                        </TableCell>

                        {/* <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.users}
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.perjan !== "" && row.perjan + "%"
                            : row.janType === 0
                            ? row.jan.split("/")[0]
                            : row.jan}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.perfeb !== "" && row.perfeb + "%"
                            : row.febType === 0
                            ? row.feb.split("/")[0]
                            : row.feb}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.permar !== "" && row.permar + "%"
                            : row.marType === 0
                            ? row.mar.split("/")[0]
                            : row.mar}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.perapr !== "" && row.perapr + "%"
                            : row.aprType === 0
                            ? row.apr.split("/")[0]
                            : row.apr}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.permay !== "" && row.permay + "%"
                            : row.mayType === 0
                            ? row.may.split("/")[0]
                            : row.may}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.perjun !== "" && row.perjun + "%"
                            : row.junType === 0
                            ? row.jun.split("/")[0]
                            : row.jun}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.perjul !== "" && row.perjul + "%"
                            : row.julType === 0
                            ? row.jul.split("/")[0]
                            : row.jul}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.peraug !== "" && row.peraug + "%"
                            : row.augType === 0
                            ? row.aug.split("/")[0]
                            : row.aug}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.persep !== "" && row.persep + "%"
                            : row.sepType === 0
                            ? row.sep.split("/")[0]
                            : row.sep}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.peroct !== "" && row.peroct + "%"
                            : row.octType === 0
                            ? row.oct.split("/")[0]
                            : row.oct}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.pernov !== "" && row.pernov + "%"
                            : row.novType === 0
                            ? row.nov.split("/")[0]
                            : row.nov}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.annualTargetType === 1
                            ? row.perdec !== "" && row.perdec + "%"
                            : row.decType === 0
                            ? row.dec.split("/")[0]
                            : row.dec}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.additionType === 2 ? row.score + "" : row.score}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.totalScore}
                        </TableCell>
                        <TableCell align="right">{row.action}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Box
              sx={{
                marginTop: "-37px",
                mx: 1,
                display: "flex",
              }}
            >
              <Box sx={{ width: "200px" }}>
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    ml: 2,
                    alignItems: "center",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.rolesTextDark
                        : assets.colors.rolesTextLight,
                  }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Total Weight &nbsp;
                  <b style={{ ml: 1 }}>{100 - tresholdPercentageWeight}%</b>
                </Typography>
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    ml: 2,
                    mb: 2,
                    color: "red",
                  }}
                  id="tableTitle"
                  component="div"
                >
                  Max Weight &nbsp;<b>100%</b>
                </Typography>
              </Box>
              <Box sx={{ width: "220px" }}>
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    fontSize: "14px",
                    fontWeight: 700,
                    ml: 2,
                    alignItems: "center",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.rolesTextDark
                        : assets.colors.rolesTextLight,
                  }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Final Score
                </Typography>
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    ml: 2,
                    alignItems: "center",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.rolesTextDark
                        : assets.colors.rolesTextLight,
                  }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  {finalScore} / {totalWeight}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}

      <CompanyKpiModal
        kpi={selectedKpi}
        tresholdPercentageWeight={tresholdPercentageWeight}
        onClose={() => setSelectedKpi(undefined)}
        onUpdate={onUpdateKpi}
      />
    </>
  );
};

export default CompanyKpis;
