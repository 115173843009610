import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import authApi from "../api/authApi";
import moodApi from "../api/moodApi";
import { styled } from "@mui/material/styles";
import "../css/hide-radio.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Label = styled("label")`
  padding: 0 0 4px;
  margin-top: 10px;
  line-height: 1.5;
  display: block;
  text-align: center;
`;

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [moodErrText, setMoodErrText] = useState("");
  const [moods, setMoods] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const getMoods = async () => {
      try {
        const res = await moodApi.getAll();
        setMoods(res);
      } catch (err) {
        console.log(err);
      }
    };
    getMoods();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude)
        setLongitude(position.coords.longitude);
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameErrText("");
    setMoodErrText("");
    setPasswordErrText("");

    const data = new FormData(e.target);
    const username = data.get("username").trim();
    const password = data.get("password").trim();
    const mood = data.get("mood");

    let err = false;

    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (mood === null) {
      err = true;
      setMoodErrText("Please fill in your mood below");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await authApi.login({ username, password, mood, latitude, longitude });
      setLoading(false);
      localStorage.setItem("token", res.token);
      navigate("/");
    } catch (err) {
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "username") {
          setUsernameErrText(e.msg);
        }
        if (e.param === "mood") {
          setMoodErrText(e.msg);
        }
        if (e.param === "password") {
          setPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit} noValidate>
        <Typography fontWeight="400" sx={{ fontSize: "14px" }}>
          Username
        </Typography>
        <TextField
          sx={{
            marginTop: "2px",
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFF ",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFF ",
            },
            "& .MuiOutlinedInput-root": {
              color: "#FFF",
            },
          }}
          size="small"
          margin="normal"
          required
          fullWidth
          id="username"
          // label="Username"
          name="username"
          disabled={loading}
          error={usernameErrText !== ""}
          helperText={usernameErrText}
        />
        <Typography
          fontWeight="400"
          sx={{ fontSize: "14px", marginTop: "3px" }}
        >
          Password
        </Typography>
        <TextField
          sx={{
            marginTop: "2px",
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFF ",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFF ",
            },
            "& .MuiOutlinedInput-root": {
              color: "#FFF",
            },
          }}
          size="small"
          margin="normal"
          required
          fullWidth
          id="password"
          // label="Password"
          name="password"
          // type="password"
          type={showPassword ? "text" : "password"}
          disabled={loading}
          error={passwordErrText !== ""}
          helperText={passwordErrText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  sx={{color: "#FFF"}}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          fontWeight="700"
          sx={{ fontSize: "16px", textAlign: "center", mt: 1 }}
        >
          What's your mood?
          {moodErrText !== "" && (
            <span style={{ fontWeight: "400", color: "red", fontSize: "14px" }}>
              <br />
              {moodErrText}
            </span>
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {moods.map((mood, index) => (
            <Label key={index}>
              <input
                className="mood"
                type="radio"
                name="mood"
                value={mood.id}
                disabled={loading}
              />
              <span style={{ fontSize: "40px" }}>{mood.icon}</span>
              <p style={{ fontSize: "12px", marginTop: "-3px" }}>{mood.name}</p>
            </Label>
          ))}
        </Box>

        <LoadingButton
          sx={{ mt: 1, mb: 2 }}
          variant="contained"
          fullWidth
          color="primary"
          type="submit"
          loading={loading}
        >
          Login
        </LoadingButton>
      </Box>
      {/* <Button component={Link} to="/signup" sx={{ textTransform: "none" }}>
        Don't have an account? Signup
      </Button> */}
    </>
  );
};

export default Login;
