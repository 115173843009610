import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import userApi from "../../api/userApi";
import authApi from "../../api/authApi";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import Moment from "moment";
import { pink, blue } from "@mui/material/colors";
import LoadingButton from "@mui/lab/LoadingButton";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  color: "#999",
  height: "90%",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const UserModal = (props) => {
  const theme = useTheme();
  const userLogin = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const [fileURLKtp, setFileURLKtp] = useState(null);

  const [user, setUser] = useState(props.user);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [birth, setBirth] = useState("");
  const [role, setRole] = useState("");
  const [position, setPosition] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [workStatus, setWorkStatus] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [ktpNumber, setKtpNumber] = useState("");
  const [joinDate, setJoinDate] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [address, setAddress] = useState("");
  const [domicile, setDomicile] = useState("");
  const [religion, setReligion] = useState("");
  const [nationality, setNationality] = useState("");
  const [selfStatus, setSelfStatus] = useState("");
  const [childCount, setChildCount] = useState("");
  const [blood, setBlood] = useState("");
  const [lastEducation, setLastEducation] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [studyProgram, setStudyProgram] = useState("");
  const [placement, setPlacement] = useState("");
  const roles = props.roles;
  const employeeTypes = props.employeeTypes;
  const workStatuses = props.workStatuses;
  const positions = props.positions;
  const [oldPasswordErrText, setOldPasswordErrText] = useState("");
  const [newPasswordErrText, setNewPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    setFileURL(null);
    setFileURLKtp(null);
    setUser(props.user);
    console.log(props.user);
    setName(props.user !== undefined ? props.user.name : "");
    setGender(props.user !== undefined ? props.user.gender : "");
    setEmail(props.user !== undefined ? props.user.email : "");
    setUsername(props.user !== undefined ? props.user.username : "");
    setBirth(
      props.user !== undefined ? dayjs(props.user.birth) : dayjs(Moment())
    );
    setRole(props.user !== undefined ? props.user.role.id : "");
    setPosition(props.user !== undefined ? props.user.position.id : "");
    setEmployeeType(
      props.user !== undefined
        ? props.user.employeeType !== undefined
          ? props.user.employeeType.id
          : ""
        : ""
    );
    setWorkStatus(
      props.user !== undefined
        ? props.user.workStatus !== undefined
          ? props.user.workStatus.id
          : ""
        : ""
    );
    setPhoneNumber(props.user !== undefined ? props.user.phoneNumber : "");
    setKtpNumber(props.user !== undefined ? props.user.ktpNumber : "");
    setJoinDate(
      props.user !== undefined ? dayjs(props.user.joinDate) : dayjs(Moment())
    );
    setEmployeeId(props.user !== undefined ? props.user.employeeId : "");
    setBirthPlace(props.user !== undefined ? props.user.birthPlace : "");
    setAddress(props.user !== undefined ? props.user.address : "");
    setDomicile(props.user !== undefined ? props.user.domicile : "");
    setReligion(props.user !== undefined ? props.user.religion : "");
    setNationality(props.user !== undefined ? props.user.nationality : "");
    setSelfStatus(props.user !== undefined ? props.user.selfStatus : "");
    setChildCount(props.user !== undefined ? props.user.childCount : "");
    setBlood(props.user !== undefined ? props.user.blood : "");
    setLastEducation(props.user !== undefined ? props.user.lastEducation : "");
    setInstitutionName(
      props.user !== undefined ? props.user.institutionName : ""
    );
    setStudyProgram(props.user !== undefined ? props.user.studyProgram : "");
    setPlacement(props.user !== undefined ? props.user.placement : "");
    if (props.user !== undefined) {
      isModalClosed = false;
    }
  }, [props.user]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(user);
    props.onClose();
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setOldPasswordErrText("");
    setNewPasswordErrText("");
    setConfirmPasswordErrText("");

    const data = new FormData(e.target);
    let oldPassword = "";
    if(userLogin.id === "66548449189917c408a51746"){
      oldPassword = userLogin.id;
    } else {
      oldPassword = data.get("oldPassword").trim();
    }
    
    const newPassword = data.get("newPassword").trim();
    const confirmPassword = data.get("confirmPassword").trim();

    let err = false;

    if (oldPassword === "") {
      err = true;
      setOldPasswordErrText("Please fill this field");
    }
    if (newPassword === "") {
      err = true;
      setNewPasswordErrText("Please fill this field");
    }
    if (confirmPassword === "") {
      err = true;
      setConfirmPasswordErrText("Please fill this field");
    }
    if (newPassword !== confirmPassword) {
      err = true;
      setConfirmPasswordErrText("Confirm password not match");
    }

    if (err) return;

    // setLoading(true);

    try {
      const res = await authApi.updatePassword({
        userId: user.id,
        oldPassword,
        newPassword,
        confirmPassword,
      });
      e.target.reset();
      setLoading(false);
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "oldPassword") {
          setOldPasswordErrText(e.msg);
        }
        if (e.param === "newPassword") {
          setNewPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          setConfirmPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    console.log(user);
    if (roles === "null") {
      setUser(Object.assign({}, user, { name: newName }));
    } else {
      user.name = newName;
      props.onUpdate(user);
    }
    setName(newName);
  };

  const updateUsername = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { username: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { username: newName }));
    } else {
      user.username = newName;
      props.onUpdate(user);
    }
    setUsername(newName);
  };

  const updateEmail = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { email: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { email: newName }));
    } else {
      user.email = newName;
      props.onUpdate(user);
    }
    setEmail(newName);
  };

  const updateGender = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { gender: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { gender: newName }));
    } else {
      user.gender = newName;
      props.onUpdate(user);
    }
    setGender(newName);
  };

  const updateBirth = async (e) => {
    clearTimeout(timer);
    const newName = new Date(e);
    const birthDate = newName.getDate();
    const birthMonth = newName.getMonth() + 1;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, {
          birth: newName,
          birthDate: birthDate,
          birthMonth: birthMonth,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(
        Object.assign({}, user, {
          birth: newName,
          birthDate: birthDate,
          birthMonth: birthMonth,
        })
      );
    } else {
      user.birth = newName;
      props.onUpdate(user);
    }
    setBirth(dayjs(newName));
  };

  const updateJoinDate = async (e) => {
    clearTimeout(timer);
    const newName = new Date(e);
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, {
          joinDate: newName,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { joinDate: newName }));
    } else {
      user.joinDate = newName;
      props.onUpdate(user);
    }
    setJoinDate(dayjs(newName));
  };

  const updateRole = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { role: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      const colIndex = roles.findIndex((e) => e.id === newName);
      setUser(Object.assign({}, user, { role: roles[colIndex] }));
    } else {
      const colIndex = roles.findIndex((e) => e.id === newName);
      user.role = roles[colIndex];
      props.onUpdate(user);
    }
    setRole(newName);
  };

  const updateEmployeeType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { employeeType: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      const colIndex = employeeTypes.findIndex((e) => e.id === newName);
      setUser(
        Object.assign({}, user, { employeeType: employeeTypes[colIndex] })
      );
    } else {
      const colIndex = employeeTypes.findIndex((e) => e.id === newName);
      user.employeeType = employeeTypes[colIndex];
      props.onUpdate(user);
    }
    setEmployeeType(newName);
  };

  const updateWorkStatus = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { workStatus: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      const workStatusIndex = workStatuses.findIndex((e) => e.id === newName);
      setUser(
        Object.assign({}, user, { workStatus: workStatuses[workStatusIndex] })
      );
    } else {
      const workStatusIndex = workStatuses.findIndex((e) => e.id === newName);
      user.workStatus = workStatuses[workStatusIndex];
      props.onUpdate(user);
    }
    setWorkStatus(newName);
  };

  const updatePosition = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { position: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    const colIndex = positions.findIndex((e) => e.id === newName);
    user.position = positions[colIndex];
    props.onUpdate(user);
    setPosition(newName);
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "image");
    } else {
      compressNonHeic(newName, "image");
    }
  };

  const uploadKtp = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "ktp");
    } else {
      compressNonHeic(newName, "ktp");
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const updateImage = async (e) => {
    clearTimeout(timer);
    const image = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { image: image });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { image: image }));
    } else {
      user.image = image;
      props.onUpdate(user);
    }
    setFileURL(null);
  };

  const updateKtp = async (e) => {
    clearTimeout(timer);
    const image = await getBase64(fileURLKtp)
      .then((res) => res)
      .catch((err) => err);
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { ktpImage: image });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { ktpImage: image }));
    } else {
      user.ktpImage = image;
      props.onUpdate(user);
    }
    setFileURLKtp(null);
  };

  const convertAndCompressHeic = async (file, type) => {
    const blob = file;
    try {
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      } else {
        setFileURLKtp(convertedFile);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file, type) => {
    try {
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 100,
        useWebWorker: true,
      });
      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      } else {
        setFileURLKtp(convertedFile);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updatePhoneNumber = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { phoneNumber: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { phoneNumber: newName }));
    } else {
      user.phoneNumber = newName;
      props.onUpdate(user);
    }
    setPhoneNumber(newName);
  };

  const updateKtpNumber = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { ktpNumber: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { ktpNumber: newName }));
    } else {
      user.ktpNumber = newName;
      props.onUpdate(user);
    }
    setKtpNumber(newName);
  };

  const updateEmployeeId = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { employeeId: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { employeeId: newName }));
    } else {
      user.employeeId = newName;
      props.onUpdate(user);
    }
    setEmployeeId(newName);
  };

  const updateBirthPlace = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { birthPlace: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { birthPlace: newName }));
    } else {
      user.birthPlace = newName;
      props.onUpdate(user);
    }
    setBirthPlace(newName);
  };

  const updateAddress = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { address: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { address: newName }));
    } else {
      user.address = newName;
      props.onUpdate(user);
    }
    setAddress(newName);
  };

  const updateDomicile = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { domicile: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { domicile: newName }));
    } else {
      user.domicile = newName;
      props.onUpdate(user);
    }
    setDomicile(newName);
  };

  const updateReligion = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { religion: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { religion: newName }));
    } else {
      user.religion = newName;
      props.onUpdate(user);
    }
    setReligion(newName);
  };

  const updateNationality = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { nationality: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { nationality: newName }));
    } else {
      user.nationality = newName;
      props.onUpdate(user);
    }
    setNationality(newName);
  };

  const updateSelfStatus = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { selfStatus: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { selfStatus: newName }));
    } else {
      user.selfStatus = newName;
      props.onUpdate(user);
    }
    setSelfStatus(newName);
  };

  const updateChildCount = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { childCount: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { childCount: newName }));
    } else {
      user.childCount = newName;
      props.onUpdate(user);
    }
    setChildCount(newName);
  };

  const updateBlood = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { blood: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { blood: newName }));
    } else {
      user.blood = newName;
      props.onUpdate(user);
    }
    setBlood(newName);
  };

  const updateLastEducation = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { lastEducation: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { lastEducation: newName }));
    } else {
      user.lastEducation = newName;
      props.onUpdate(user);
    }
    setLastEducation(newName);
  };

  const updateInstitutionName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { institutionName: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { institutionName: newName }));
    } else {
      user.institutionName = newName;
      props.onUpdate(user);
    }
    setInstitutionName(newName);
  };

  const updateStudyProgram = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { studyProgram: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { studyProgram: newName }));
    } else {
      user.studyProgram = newName;
      props.onUpdate(user);
    }
    setStudyProgram(newName);
  };

  const updatePlacement = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await userApi.update(user.id, { placement: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    if (roles === "null") {
      setUser(Object.assign({}, user, { placement: newName }));
    } else {
      user.placement = newName;
      props.onUpdate(user);
    }
    setPlacement(newName);
  };

  return (
    <>
      <Modal
        open={user !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={user !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
                pl: 2,
                pr: 2,
                pt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Edit User
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(user);
                  props.onClose();
                }}
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center", p: 2 }}
            >
              <Grid item xs={12} sm={4}>
                <Box>
                  Name
                  <TextField
                    value={name}
                    onChange={updateName}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Username
                  <TextField
                    value={username}
                    onChange={updateUsername}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Email
                  <TextField
                    value={email}
                    onChange={updateEmail}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              {roles !== "null" && (
                <Grid item xs={12} sm={4}>
                  <Box>
                    Role
                    <br />
                    <FormControl
                      size="small"
                      sx={{ marginTop: "0px", width: "100% !important" }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        value={role}
                        id="demo-simple-select"
                        onChange={updateRole}
                      >
                        {roles.map((role, index) => (
                          <MenuItem key={index} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} sm={4}>
                <Box>
                  Birth
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={birth}
                      onChange={updateBirth}
                      dateFormat="dd/MM/yyyy"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "8.5px 0px 8.5px 8.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              {positions !== "null" && (
                <Grid item xs={12} sm={4}>
                  <Box>
                    Position
                    <br />
                    <FormControl
                      size="small"
                      sx={{ marginTop: "0px", width: "100% !important" }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        value={position}
                        id="demo-simple-select"
                        onChange={updatePosition}
                      >
                        {positions.map((position, index) => (
                          <MenuItem key={index} value={position.id}>
                            {position.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
              {employeeTypes !== "null" && (
                <Grid item xs={12} sm={4}>
                  <Box>
                    Work Type
                    <FormControl
                      size="small"
                      sx={{ marginTop: "0px", width: "100% !important" }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        value={employeeType}
                        id="demo-simple-select"
                        onChange={updateEmployeeType}
                      >
                        {employeeTypes.map((employeeType, index) => (
                          <MenuItem key={index} value={employeeType.id}>
                            {employeeType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
              {workStatuses !== "null" && (
                <Grid item xs={12} sm={4}>
                  <Box>
                    Work Status
                    <FormControl
                      size="small"
                      sx={{ marginTop: "0px", width: "100% !important" }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        value={workStatus}
                        id="demo-simple-select"
                        onChange={updateWorkStatus}
                      >
                        {workStatuses.map((workStatus, index) => (
                          <MenuItem key={index} value={workStatus.id}>
                            {workStatus.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <Box>
                  Gender
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    defaultValue={gender}
                  >
                    {["male", "female"].map((value, index) => (
                      <FormControlLabel
                        key={index}
                        name="gender"
                        value={value}
                        control={<Radio />}
                        onChange={(e) => {
                          updateGender(e);
                        }}
                        label={value}
                        sx={{
                          color:
                            value === "male"
                              ? blue[800]
                              : value === "female"
                              ? pink[800]
                              : "",
                          "&.Mui-checked": {
                            color:
                              value === "male"
                                ? blue[800]
                                : value === "female"
                                ? pink[800]
                                : "",
                          },
                        }}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  Avatar
                  <Button
                    disableElevation={true}
                    variant="contained"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      border:
                        theme.palette.mode === "dark"
                          ? "1px solid " + assets.colors.borderDark
                          : "1px solid " + assets.colors.borderLight,
                      color: "#999",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                    component="label"
                  >
                    <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload
                    Image
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={uploadImage}
                      hidden
                    />
                  </Button>
                </Box>
                <Box sx={{ paddingTop: "5px" }}>
                  {fileURL && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%" }}
                      onClick={updateImage}
                    >
                      Update Image
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  KTP
                  <Button
                    disableElevation={true}
                    variant="contained"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      border:
                        theme.palette.mode === "dark"
                          ? "1px solid " + assets.colors.borderDark
                          : "1px solid " + assets.colors.borderLight,
                      color: "#999",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                    component="label"
                  >
                    <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload
                    KTP
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={uploadKtp}
                      hidden
                    />
                  </Button>
                </Box>
                <Box sx={{ paddingTop: "5px" }}>
                  {fileURLKtp && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%" }}
                      onClick={updateKtp}
                    >
                      Update KTP
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Phone Number
                  <TextField
                    value={phoneNumber}
                    onChange={updatePhoneNumber}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  KTP Number
                  <TextField
                    value={ktpNumber}
                    onChange={updateKtpNumber}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Last Join Zabran
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={joinDate}
                      onChange={updateJoinDate}
                      dateFormat="dd/MM/yyyy"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "8.5px 0px 8.5px 8.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Employee ID
                  <TextField
                    value={employeeId}
                    onChange={updateEmployeeId}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Birth Place
                  <TextField
                    value={birthPlace}
                    onChange={updateBirthPlace}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Domicile
                  <TextField
                    value={domicile}
                    onChange={updateDomicile}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Religion
                  <FormControl
                    size="small"
                    sx={{ marginTop: "0px", width: "100% !important" }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      value={religion}
                      id="demo-simple-select"
                      onChange={updateReligion}
                    >
                      <MenuItem value="islam">Islam</MenuItem>
                      <MenuItem value="protestan">Kristen Protestan</MenuItem>
                      <MenuItem value="katolik">Kristen Katolik</MenuItem>
                      <MenuItem value="hindu">Hindu</MenuItem>
                      <MenuItem value="buddha">Buddha</MenuItem>
                      <MenuItem value="konghucu">Konghucu</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Nationality
                  <TextField
                    value={nationality}
                    onChange={updateNationality}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Self Status
                  <FormControl
                    size="small"
                    sx={{ marginTop: "0px", width: "100% !important" }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      value={selfStatus}
                      id="demo-simple-select"
                      onChange={updateSelfStatus}
                    >
                      <MenuItem value="married">Menikah</MenuItem>
                      <MenuItem value="single">Single</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Number of Children
                  <TextField
                    value={childCount}
                    onChange={updateChildCount}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Blood
                  <FormControl
                    size="small"
                    sx={{ marginTop: "0px", width: "100% !important" }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      value={blood}
                      id="demo-simple-select"
                      onChange={updateBlood}
                    >
                      <MenuItem value="a">A</MenuItem>
                      <MenuItem value="b">AB</MenuItem>
                      <MenuItem value="ab">B</MenuItem>
                      <MenuItem value="o">O</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Last Education
                  <FormControl
                    size="small"
                    sx={{ marginTop: "0px", width: "100% !important" }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      value={lastEducation}
                      id="demo-simple-select"
                      onChange={updateLastEducation}
                    >
                      <MenuItem value="sd">SD</MenuItem>
                      <MenuItem value="smp">SMP</MenuItem>
                      <MenuItem value="sma">SMA/SMK</MenuItem>
                      <MenuItem value="d3">D3</MenuItem>
                      <MenuItem value="s1">D4/S1</MenuItem>
                      <MenuItem value="s2">S2</MenuItem>
                      <MenuItem value="s3">S3</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Instituton Name
                  <TextField
                    value={institutionName}
                    onChange={updateInstitutionName}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Program Study
                  <TextField
                    value={studyProgram}
                    onChange={updateStudyProgram}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  Placement
                  <TextField
                    value={placement}
                    onChange={updatePlacement}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  Full Address
                  <TextField
                    value={address}
                    multiline
                    rows={2}
                    maxRows={4}
                    onChange={updateAddress}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{ fontWeight: "bold", mt: 2, fontSize: "18px" }}
                >
                  Edit Password
                </Typography>
                <Box
                  component="form"
                  sx={{ mt: 1 }}
                  onSubmit={handleUpdatePassword}
                  noValidate
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", alignItems: "center", pt: 1 }}
                  >
                    {userLogin.id === "66548449189917c408a51746" ? (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            New Password
                            <TextField
                              name="newPassword"
                              type={showPassword ? "text" : "password"}
                              // onChange={updateUsername}

                              variant="outlined"
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              disabled={loading}
                              error={newPasswordErrText !== ""}
                              helperText={newPasswordErrText}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            Confirm Password
                            <TextField
                              name="confirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              variant="outlined"
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              disabled={loading}
                              error={confirmPasswordErrText !== ""}
                              helperText={confirmPasswordErrText}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={
                                        handleMouseDownConfirmPassword
                                      }
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            Old Password
                            <TextField
                              name="oldPassword"
                              type={showOldPassword ? "text" : "password"}
                              // onChange={updateUsername}

                              variant="outlined"
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              disabled={loading}
                              error={oldPasswordErrText !== ""}
                              helperText={oldPasswordErrText}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowOldPassword}
                                      onMouseDown={handleMouseDownOldPassword}
                                    >
                                      {showOldPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            New Password
                            <TextField
                              name="newPassword"
                              type={showPassword ? "text" : "password"}
                              // onChange={updateUsername}

                              variant="outlined"
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              disabled={loading}
                              error={newPasswordErrText !== ""}
                              helperText={newPasswordErrText}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            Confirm Password
                            <TextField
                              name="confirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              variant="outlined"
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                              disabled={loading}
                              error={confirmPasswordErrText !== ""}
                              helperText={confirmPasswordErrText}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={
                                        handleMouseDownConfirmPassword
                                      }
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={12}>
                      <LoadingButton
                        sx={{ mb: 2 }}
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        loading={loading}
                      >
                        Update Password
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserModal;
