import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import positionApi from "../../api/positionApi";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  height: "30%",
  color: "#999",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const PositionModal = (props) => {
  const theme = useTheme();
  const [position, setPosition] = useState(props.position);
  const [name, setName] = useState("");
  const [boardType, setBoardType] = useState("");
  const boardTypes = props.boardTypes;

  useEffect(() => {
    setPosition(props.position);
    setBoardType(
      props.position !== undefined
        ? props.position.boardType !== undefined
          ? props.position.boardType !== null
            ? props.position.boardType.id
            : ""
          : ""
        : ""
    );
    setName(props.position !== undefined ? props.position.name : "");
    if (props.position !== undefined) {
      isModalClosed = false;
    }
  }, [props.position]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(position);
    props.onClose();
  };

  const updateBoardType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await positionApi.update(position.id, { boardType: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    const boardTypeIndex = boardTypes.findIndex((e) => e.id === newName);
    position.boardType = boardTypes[boardTypeIndex];
    setBoardType(newName);
    props.onUpdate(position);
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await positionApi.update(position.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    position.name = newName;
    setName(newName);
    props.onUpdate(position);
  };

  return (
    <>
      <Modal
        open={position !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={position !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(position);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                padding: "0rem 2rem",
              }}
            >
              <Box>
                Name
                <TextField
                  value={name}
                  onChange={updateName}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                    },
                    marginBottom: "10px",
                  }}
                />
              </Box>
              <Box sx={{ mt: 1 }}>
                Division
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiFormControl-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                >
                  <Select
                    labelId="label-position"
                    name="boardType"
                    value={boardType}
                    fullWidth
                    id="boardType"
                    onChange={updateBoardType}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiFormControl-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiInputBase-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {boardTypes.map((boardType, index) => (
                      <MenuItem key={index} value={boardType.id}>
                        {boardType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PositionModal;
