import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import companyKpiApi from "../../api/companyKpiApi";
import boardApi from "../../api/boardApi";
import EmojiPicker from "./EmojiPicker";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  height: "70%",
  color: "#999",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const KpiModal = (props) => {
  const theme = useTheme();
  const [lastPercentageWeight, setLastPercentageWeight] = useState(0);
  const [boardIds, setBoardIds] = useState([]);
  const [typeMonth, setTypeMonth] = useState([]);
  const [radioMonths, setRadioMonths] = useState([]);
  const [radioMonthsNum, setRadioMonthsNum] = useState([]);
  const [boardValIds, setBoardValIds] = useState([]);
  const [userValIds, setUserValIds] = useState([]);
  const [taskValIds, setTaskValIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [selectUsers, setSelectUsers] = useState({});
  const [selectTasks, setSelectTasks] = useState({});
  const [kpi, setKpi] = useState(props.kpi);
  const [tresholdPercentageWeight, setTresholdPercentageWeight] = useState(
    props.tresholdPercentageWeight
  );
  const [indicator, setIndicator] = useState("");
  const [percentageWeight, setPercentageWeight] = useState("");
  const [annualTarget, setAnnualTarget] = useState("");
  const [annualTargetType, setAnnualTargetType] = useState("");
  const [additionType, setAdditionType] = useState("");
  const [kpiType, setKpiType] = useState("");
  const [icon, setIcon] = useState("📃");
  const [kpiBoards, setKpiBoards] = useState([]);
  const [kpiBoardUsers, setKpiBoardUsers] = useState([]);
  const [kpiBoardTasks, setKpiBoardTasks] = useState([]);

  useEffect(() => {
    setKpi(props.kpi);
    setSelectUsers([]);
    setSelectTasks([]);
    setBoardValIds([]);
    setUserValIds([]);
    setTaskValIds([]);
    setAnnualTarget(props.kpi !== undefined ? props.kpi.annualTarget : "");
    setAnnualTargetType(
      props.kpi !== undefined
        ? props.kpi.annualTargetType === 0
          ? "number"
          : "percentage"
        : ""
    );
    setAdditionType(
      props.kpi !== undefined
        ? props.kpi.additionType === 0
          ? "accumulative"
          : props.kpi.additionType === 1
          ? "last number"
          : "average"
        : ""
    );
    setKpiType(
      props.kpi !== undefined
        ? props.kpi.kpiType === 0
          ? "minimize"
          : "maximize"
        : ""
    );
    setIcon(props.kpi !== undefined ? props.kpi.icon : "");
    setIndicator(props.kpi !== undefined ? props.kpi.indicator : "");
    setPercentageWeight(
      props.kpi !== undefined ? props.kpi.percentageWeight : ""
    );
    setLastPercentageWeight(
      props.kpi !== undefined ? props.kpi.percentageWeight : ""
    );
    if (props.kpi !== undefined) {
      isModalClosed = false;

      setRadioMonthsNum((set) => ({
        ...set,
        ["jan"]: props.kpi.janNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["feb"]: props.kpi.febNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["mar"]: props.kpi.marNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["apr"]: props.kpi.aprNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["may"]: props.kpi.mayNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["jun"]: props.kpi.junNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["jul"]: props.kpi.julNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["aug"]: props.kpi.augNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["sep"]: props.kpi.sepNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["oct"]: props.kpi.octNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["nov"]: props.kpi.novNum,
      }));
      setRadioMonthsNum((set) => ({
        ...set,
        ["dec"]: props.kpi.decNum,
      }));

      console.log(props.kpi);
      setRadioMonths((set) => ({
        ...set,
        ["jan"]: props.kpi.janType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["feb"]: props.kpi.febType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["mar"]: props.kpi.marType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["apr"]: props.kpi.aprType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["may"]: props.kpi.mayType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["jun"]: props.kpi.junType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["jul"]: props.kpi.julType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["aug"]: props.kpi.augType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["sep"]: props.kpi.sepType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["oct"]: props.kpi.octType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["nov"]: props.kpi.novType === 0 ? "manual" : "from task",
      }));
      setRadioMonths((set) => ({
        ...set,
        ["dec"]: props.kpi.decType === 0 ? "manual" : "from task",
      }));

      const boardTypeId = props.kpi.boardType;
      setTresholdPercentageWeight(
        parseInt(props.tresholdPercentageWeight) +
          parseInt(props.kpi.percentageWeight)
      );
      if (
        props.kpi.jan.length !== 0 &&
        props.kpi.feb.length !== 0 &&
        props.kpi.mar.length !== 0 &&
        props.kpi.apr.length !== 0 &&
        props.kpi.may.length !== 0 &&
        props.kpi.jun.length !== 0 &&
        props.kpi.jul.length !== 0 &&
        props.kpi.aug.length !== 0 &&
        props.kpi.sep.length !== 0 &&
        props.kpi.oct.length !== 0 &&
        props.kpi.nov.length !== 0 &&
        props.kpi.dec.length !== 0
      ) {
        setBoardValIds([]);
      }
      if (props.kpi.jan.length !== 0) {
        const month = props.kpi.jan[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("jan", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.feb.length !== 0) {
        const month = props.kpi.feb[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("feb", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.mar.length !== 0) {
        const month = props.kpi.mar[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("mar", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.apr.length !== 0) {
        const month = props.kpi.apr[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("apr", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.may.length !== 0) {
        const month = props.kpi.may[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("may", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.jun.length !== 0) {
        const month = props.kpi.jun[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("jun", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.jul.length !== 0) {
        const month = props.kpi.jul[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("jul", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.aug.length !== 0) {
        const month = props.kpi.aug[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("aug", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.sep.length !== 0) {
        const month = props.kpi.sep[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("sep", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.oct.length !== 0) {
        const month = props.kpi.oct[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("oct", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.nov.length !== 0) {
        const month = props.kpi.nov[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("nov", boardTypeId, boardId, taskId, userIdReal, userId);
      }
      if (props.kpi.dec.length !== 0) {
        const month = props.kpi.dec[0];
        const boardId = month.board;
        const taskId = month.task;
        let userIdReal = month.user;
        let userId = month.user;
        getDataMonths("dec", boardTypeId, boardId, taskId, userIdReal, userId);
      }

      const getKpiBoards = async () => {
        try {
          const boardTypeId = props.kpi.boardType;
          const boardId = 0;
          const userId = 0;
          const res = await boardApi.getKpiBoards(boardTypeId, boardId, userId);
          setKpiBoards(res);
        } catch (err) {
          console.log(err);
        }
      };
      getKpiBoards();
    }
  }, [props.kpi]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(kpi);
    props.onClose();
  };

  const selectTypeMonth = (val, value) => {
    console.log(val);
    console.log(value);
    const id = value.id;

    setRadioMonths((set) => ({
      ...set,
      [id]: val,
    }));

    const typeMonth = val === "manual" ? 0 : 1;

    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        if (id === "jan") {
          await companyKpiApi.update(kpi.id, { janType: typeMonth });
          kpi.janType = typeMonth;
        } else if (id === "feb") {
          await companyKpiApi.update(kpi.id, { febType: typeMonth });
          kpi.febType = typeMonth;
        } else if (id === "mar") {
          await companyKpiApi.update(kpi.id, { marType: typeMonth });
          kpi.marType = typeMonth;
        } else if (id === "apr") {
          await companyKpiApi.update(kpi.id, { aprType: typeMonth });
          kpi.aprType = typeMonth;
        } else if (id === "may") {
          await companyKpiApi.update(kpi.id, { mayType: typeMonth });
          kpi.mayType = typeMonth;
        } else if (id === "jun") {
          await companyKpiApi.update(kpi.id, { junType: typeMonth });
          kpi.junType = typeMonth;
        } else if (id === "jul") {
          await companyKpiApi.update(kpi.id, { julType: typeMonth });
          kpi.julType = typeMonth;
        } else if (id === "aug") {
          await companyKpiApi.update(kpi.id, { augType: typeMonth });
          kpi.augType = typeMonth;
        } else if (id === "sep") {
          await companyKpiApi.update(kpi.id, { sepType: typeMonth });
          kpi.sepType = typeMonth;
        } else if (id === "oct") {
          await companyKpiApi.update(kpi.id, { octType: typeMonth });
          kpi.octType = typeMonth;
        } else if (id === "nov") {
          await companyKpiApi.update(kpi.id, { novType: typeMonth });
          kpi.novType = typeMonth;
        } else if (id === "dec") {
          await companyKpiApi.update(kpi.id, { decType: typeMonth });
          kpi.decType = typeMonth;
        }
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    props.onUpdate(kpi);
  };

  const updatePercentageWeight = async (e, lastValue) => {
    if (e.target.value === "" || e.target.value < 0) {
      e.target.value = 0;
    } else if (e.target.value > tresholdPercentageWeight) {
      e.target.value = lastValue;
    } else if (e.target.value.length > 0) {
      e.target.value = parseInt(e.target.value);
    }
    const newData = e.target.value;
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { percentageWeight: newData });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.percentageWeight = newData;
    kpi.lastPercentageWeight = lastPercentageWeight;
    setPercentageWeight(newData);
    // props.onUpdate(kpi);
    // console.log(tresholdPercentageWeight)
    // console.log(e.target.value)
    // if(parseInt(tresholdPercentageWeight) > parseInt(e.target.value)){
    //   setTresholdPercentageWeight(parseInt(tresholdPercentageWeight)-parseInt(e.target.value));
    // } else {
    //   setTresholdPercentageWeight(parseInt(e.target.value) - parseInt(tresholdPercentageWeight));
    // }
    // setPercentageWeight(parseInt(e.target.value));
  };

  const getDataMonths = async (
    id,
    boardTypeId,
    boardId,
    taskId,
    userIdReal,
    userId
  ) => {
    const getUsers = async () => {
      setSelectUsers((set) => ({
        ...set,
        [id]: true,
      }));
      setSelectTasks((set) => ({
        ...set,
        [id]: true,
      }));
      setBoardValIds((set) => ({
        ...set,
        [id]: boardId,
      }));
      setTaskValIds((set) => ({
        ...set,
        [id]: taskId,
      }));
      setUserValIds((set) => ({
        ...set,
        [id]: userId,
      }));

      const index = boardIds.findIndex((e) => e.id === id);
      if (index === -1) {
        boardIds.unshift({ id: id, boardId: boardId });
      } else {
        boardIds[index].boardId = boardId;
      }
      setBoardIds([...boardIds]);

      const res = await boardApi.getKpiBoards(boardTypeId, boardId, 0);
      setKpiBoardUsers((set) => ({
        ...set,
        [id]: res,
      }));

      const colIdx = userIds.findIndex((e) => e.id === id);
      if (colIdx === -1) {
        userIds.unshift({ id: id, userId: userId });
      } else {
        userIds[colIdx].userId = userId;
      }
      setUserIds([...userIds]);

      const resTasks = await boardApi.getKpiBoards(
        boardTypeId,
        boardId,
        userId
      );
      setKpiBoardTasks((set) => ({
        ...set,
        [id]: resTasks,
      }));
    };
    getUsers();
  };

  const onIconChange = async (newIcon) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { icon: newIcon });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.icon = newIcon;
    setIcon(newIcon);
    props.onUpdate(kpi);
  };

  const selectBoard = async (e, value) => {
    // console.log(e.target.value);
    // console.log(value);
    const id = value.id;
    timer = setTimeout(async () => {
      try {
        const boardTypeId = props.kpi.boardType;
        const boardId = e.target.value;

        setBoardValIds((set) => ({
          ...set,
          [id]: boardId,
        }));

        setSelectTasks((set) => ({
          ...set,
          [id]: false,
        }));

        const userId = 0;

        const index = boardIds.findIndex((e) => e.id === id);
        if (index === -1) {
          boardIds.unshift({ id: id, boardId: boardId });
        } else {
          boardIds[index].boardId = boardId;
        }
        setBoardIds([...boardIds]);

        const res = await boardApi.getKpiBoards(boardTypeId, boardId, userId);
        setSelectUsers((set) => ({
          ...set,
          [id]: true,
        }));
        setKpiBoardUsers((set) => ({
          ...set,
          [id]: res,
        }));
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const selectUser = async (e, value) => {
    const id = value.id;
    timer = setTimeout(async () => {
      try {
        const index = boardIds.findIndex((e) => e.id === id);

        const boardTypeId = props.kpi.boardType;
        const boardId = boardIds[index].boardId;
        const userId = e.target.value;

        setUserValIds((set) => ({
          ...set,
          [id]: userId,
        }));

        const colIdx = userIds.findIndex((e) => e.id === id);
        if (colIdx === -1) {
          userIds.unshift({ id: id, userId: userId });
        } else {
          userIds[colIdx].userId = userId;
        }
        setUserIds([...userIds]);

        const res = await boardApi.getKpiBoards(boardTypeId, boardId, userId);
        setSelectTasks((set) => ({
          ...set,
          [id]: true,
        }));
        setKpiBoardTasks((set) => ({
          ...set,
          [id]: res,
        }));
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const selectTask = async (e, value) => {
    const id = value.id;
    timer = setTimeout(async () => {
      try {
        const indexBoard = boardIds.findIndex((e) => e.id === id);
        const indexUser = userIds.findIndex((e) => e.id === id);
        const boardId = boardIds[indexBoard].boardId;
        const userId = userIds[indexUser].userId;
        const taskId = e.target.value;

        setTaskValIds((set) => ({
          ...set,
          [id]: taskId,
        }));

        const monthTask = { board: boardId, user: userId, task: taskId };

        timer = setTimeout(async () => {
          try {
            if (id === "jan") {
              await companyKpiApi.update(kpi.id, { jan: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.jan = [monthTask];
              kpi.progjan = task.totalCheckDone + " / " + task.totalCheck;
              kpi.progperjan = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progjandone = task.totalCheckDone;
              kpi.progjantotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "feb") {
              await companyKpiApi.update(kpi.id, { feb: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progfeb = task.totalCheckDone + " / " + task.totalCheck;
              kpi.feb = [monthTask];
              kpi.progperfeb = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progfebdone = task.totalCheckDone;
              kpi.progfebtotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "mar") {
              await companyKpiApi.update(kpi.id, { mar: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progmar = task.totalCheckDone + " / " + task.totalCheck;
              kpi.mar = [monthTask];
              kpi.progpermar = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progmardone = task.totalCheckDone;
              kpi.progmartotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "apr") {
              await companyKpiApi.update(kpi.id, { apr: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progapr = task.totalCheckDone + " / " + task.totalCheck;
              kpi.apr = [monthTask];
              kpi.progperapr = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progaprdone = task.totalCheckDone;
              kpi.progaprtotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "may") {
              await companyKpiApi.update(kpi.id, { may: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progmay = task.totalCheckDone + " / " + task.totalCheck;
              kpi.may = [monthTask];
              kpi.progpermay = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progmaydone = task.totalCheckDone;
              kpi.progmaytotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "jun") {
              await companyKpiApi.update(kpi.id, { jun: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progjun = task.totalCheckDone + " / " + task.totalCheck;
              kpi.jun = [monthTask];
              kpi.progperjun = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progjundone = task.totalCheckDone;
              kpi.progjuntotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "jul") {
              await companyKpiApi.update(kpi.id, { jul: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progjul = task.totalCheckDone + " / " + task.totalCheck;
              kpi.jul = [monthTask];
              kpi.progperjul = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progjuldone = task.totalCheckDone;
              kpi.progjultotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "aug") {
              await companyKpiApi.update(kpi.id, { aug: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progaug = task.totalCheckDone + " / " + task.totalCheck;
              kpi.aug = [monthTask];
              kpi.progperaug = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progaugdone = task.totalCheckDone;
              kpi.progaugtotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "sep") {
              await companyKpiApi.update(kpi.id, { sep: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progsep = task.totalCheckDone + " / " + task.totalCheck;
              kpi.sep = [monthTask];
              kpi.progpersep = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progsepdone = task.totalCheckDone;
              kpi.progseptotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "oct") {
              await companyKpiApi.update(kpi.id, { oct: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progoct = task.totalCheckDone + " / " + task.totalCheck;
              kpi.oct = [monthTask];
              kpi.progperoct = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progoctdone = task.totalCheckDone;
              kpi.progocttotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "nov") {
              await companyKpiApi.update(kpi.id, { nov: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.prognov = task.totalCheckDone + " / " + task.totalCheck;
              kpi.nov = [monthTask];
              kpi.progpernov = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.prognovdone = task.totalCheckDone;
              kpi.prognovtotal = task.totalCheck;
              props.onUpdate(kpi);
            } else if (id === "dec") {
              await companyKpiApi.update(kpi.id, { dec: monthTask });
              const task = await companyKpiApi.getOneTask(taskId);
              kpi.progdec = task.totalCheckDone + " / " + task.totalCheck;
              kpi.dec = [monthTask];
              kpi.progperdec = (
                (parseInt(task.totalCheckDone) / parseInt(task.totalCheck)) *
                100
              ).toFixed(2);
              kpi.progdecdone = task.totalCheckDone;
              kpi.progdectotal = task.totalCheck;
              props.onUpdate(kpi);
            }
          } catch (err) {
            console.log(err);
          }
        }, timeout);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateIndicator = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { indicator: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.indicator = newName;
    setIndicator(newName);
    props.onUpdate(kpi);
  };

  const updateMonthNum = async (e, value) => {
    const id = value.id;
    setRadioMonthsNum((set) => ({
      ...set,
      [id]: e.target.value,
    }));
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        if (id === "jan") {
          await companyKpiApi.update(kpi.id, { janNum: newName });
          kpi.janNum = newName;
          kpi.progperjan = newName;
        } else if (id === "feb") {
          await companyKpiApi.update(kpi.id, { febNum: newName });
          kpi.febNum = newName;
          kpi.progperfeb = newName;
        } else if (id === "mar") {
          await companyKpiApi.update(kpi.id, { marNum: newName });
          kpi.marNum = newName;
          kpi.progpermar = newName;
        } else if (id === "apr") {
          await companyKpiApi.update(kpi.id, { aprNum: newName });
          kpi.aprNum = newName;
          kpi.progperapr = newName;
        } else if (id === "may") {
          await companyKpiApi.update(kpi.id, { mayNum: newName });
          kpi.mayNum = newName;
          kpi.progpermay = newName;
        } else if (id === "jun") {
          await companyKpiApi.update(kpi.id, { junNum: newName });
          kpi.junNum = newName;
          kpi.progperjun = newName;
        } else if (id === "jul") {
          await companyKpiApi.update(kpi.id, { julNum: newName });
          kpi.julNum = newName;
          kpi.progperjul = newName;
        } else if (id === "aug") {
          await companyKpiApi.update(kpi.id, { augNum: newName });
          kpi.augNum = newName;
          kpi.progperaug = newName;
        } else if (id === "sep") {
          await companyKpiApi.update(kpi.id, { sepNum: newName });
          kpi.sepNum = newName;
          kpi.progpersep = newName;
        } else if (id === "oct") {
          await companyKpiApi.update(kpi.id, { octNum: newName });
          kpi.octNum = newName;
          kpi.progperoct = newName;
        } else if (id === "nov") {
          await companyKpiApi.update(kpi.id, { novNum: newName });
          kpi.novNum = newName;
          kpi.progpernov = newName;
        } else if (id === "dec") {
          await companyKpiApi.update(kpi.id, { decNum: newName });
          kpi.decNum = newName;
          kpi.progperdec = newName;
        }
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    props.onUpdate(kpi);
  };

  const updateAnnualTarget = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { annualTarget: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.annualTarget = newName;
    setAnnualTarget(newName);
    props.onUpdate(kpi);
  };

  const updateAnnualTargetType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value === "number" ? 0 : 1;
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { annualTargetType: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.annualTargetType = newName;
    setAnnualTargetType(e.target.value);
    props.onUpdate(kpi);
  };

  const updateAdditionType = async (e) => {
    clearTimeout(timer);
    const newName =
      e.target.value === "accumulative"
        ? 0
        : e.target.value === "last number"
        ? 1
        : 2;
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { additionType: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.additionType = newName;
    setAdditionType(e.target.value);
    props.onUpdate(kpi);
  };

  const updateKpiType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value === "minimize" ? 0 : 1;
    timer = setTimeout(async () => {
      try {
        await companyKpiApi.update(kpi.id, { kpiType: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    kpi.kpiType = newName;
    setKpiType(e.target.value);
    props.onUpdate(kpi);
  };

  return (
    <>
      <Modal
        open={kpi !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={kpi !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
                pl: 2,
                pr: 2,
                pt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Edit Key Performance Indicator
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(kpi);
                  props.onClose();
                }}
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                padding: "2rem 2rem",
              }}
            >
              <Grid item xs={12} sm={4}>
                Icon
                <EmojiPicker icon={icon} onChange={onIconChange} />
              </Grid>
              <Grid item xs={12} sm={4}>
                Indicator
                <TextField
                  value={indicator}
                  onChange={updateIndicator}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Percentage Weight (Max {tresholdPercentageWeight}%)
                <TextField
                  type="number"
                  value={percentageWeight}
                  onChange={(e) => {
                    updatePercentageWeight(e, percentageWeight);
                  }}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Target Type
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="annualTargetType"
                  value={annualTargetType}
                >
                  {["number", "percentage"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      onChange={(e) => {
                        updateAnnualTargetType(e);
                      }}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={8}>
                Target
                <TextField
                  type="number"
                  value={annualTarget}
                  onChange={updateAnnualTarget}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Addition Type
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="additionType"
                  value={additionType}
                >
                  {["accumulative", "last number", "average"].map(
                    (value, index) => (
                      <FormControlLabel
                        key={index}
                        value={value}
                        onChange={(e) => {
                          updateAdditionType(e);
                        }}
                        control={<Radio />}
                        label={value}
                      />
                    )
                  )}
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                KPI Type
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="kpiType"
                  value={kpiType}
                >
                  {["minimize", "maximize"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      onChange={(e) => {
                        updateKpiType(e);
                      }}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              {[
                { id: "jan", name: "Jan" },
                { id: "feb", name: "Feb" },
                { id: "mar", name: "Mar" },
                { id: "apr", name: "Apr" },
                { id: "may", name: "May" },
                { id: "jun", name: "Jun" },
                { id: "jul", name: "Jul" },
                { id: "aug", name: "Aug" },
                { id: "sep", name: "Sep" },
                { id: "oct", name: "Oct" },
                { id: "nov", name: "Nov" },
                { id: "dec", name: "Dec" },
              ].map((value, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  {value.name}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    defaultValue={radioMonths[value.id]}
                  >
                    {["manual", "from task"].map((val, idx) => (
                      <FormControlLabel
                        key={idx}
                        value={val}
                        onChange={(e) => {
                          selectTypeMonth(val, value);
                        }}
                        control={<Radio />}
                        label={val}
                      />
                    ))}
                  </RadioGroup>
                  {radioMonths[value.id] === "manual" ? (
                    <TextField
                      value={radioMonthsNum[value.id]}
                      onChange={(e) => {
                        updateMonthNum(e, value);
                      }}
                      placeholder=""
                      variant="outlined"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "8.5px 0px 8.5px 8.5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                        },
                      }}
                    />
                  ) : (
                    <>
                      <FormControl
                        size="small"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "8.5px 0px 8.5px 8.5px",
                          },
                          "& .MuiFormControl-root": {
                            background:
                              theme.palette.mode === "dark"
                                ? assets.colors.inputTypeDark
                                : assets.colors.inputTypeLight,
                          },
                          "& .MuiOutlinedInput-root": {
                            background:
                              theme.palette.mode === "dark"
                                ? assets.colors.inputTypeDark
                                : assets.colors.inputTypeLight,
                          },
                        }}
                      >
                        <Select
                          labelId="label-position"
                          name="boardType"
                          fullWidth
                          value={boardValIds[value.id]}
                          id="boardType"
                          onChange={(e) => {
                            selectBoard(e, value);
                          }}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: "8.5px 0px 8.5px 8.5px",
                            },
                            "& .MuiFormControl-root": {
                              background:
                                theme.palette.mode === "dark"
                                  ? assets.colors.inputTypeDark
                                  : assets.colors.inputTypeLight,
                            },
                            "& .MuiOutlinedInput-root": {
                              background:
                                theme.palette.mode === "dark"
                                  ? assets.colors.inputTypeDark
                                  : assets.colors.inputTypeLight,
                            },
                            "& .MuiInputBase-root": {
                              background:
                                theme.palette.mode === "dark"
                                  ? assets.colors.inputTypeDark
                                  : assets.colors.inputTypeLight,
                            },
                          }}
                        >
                          {kpiBoards.map((boardType, index) => (
                            <MenuItem key={index} value={boardType.id}>
                              {boardType.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {selectUsers && selectUsers[value.id] && (
                        <>
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                              "& .MuiOutlinedInput-input": {
                                padding: "8.5px 0px 8.5px 8.5px",
                              },
                              "& .MuiFormControl-root": {
                                background:
                                  theme.palette.mode === "dark"
                                    ? assets.colors.inputTypeDark
                                    : assets.colors.inputTypeLight,
                              },
                              "& .MuiOutlinedInput-root": {
                                background:
                                  theme.palette.mode === "dark"
                                    ? assets.colors.inputTypeDark
                                    : assets.colors.inputTypeLight,
                              },
                            }}
                          >
                            <Select
                              labelId="label-position"
                              name="boardType"
                              fullWidth
                              value={userValIds[value.id]}
                              id="boardType"
                              onChange={(e) => {
                                selectUser(e, value);
                              }}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiFormControl-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                                "& .MuiInputBase-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                            >
                              {/* {console.log(kpiBoardUsers)} */}
                              {kpiBoardUsers[value.id] &&
                                kpiBoardUsers[value.id].length !== 0 &&
                                kpiBoardUsers[value.id].map(
                                  (boardType, index) => (
                                    <MenuItem key={index} value={boardType.id}>
                                      {boardType.title}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                          {selectTasks && selectTasks[value.id] && (
                            <FormControl
                              size="small"
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-input": {
                                  padding: "8.5px 0px 8.5px 8.5px",
                                },
                                "& .MuiFormControl-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                                "& .MuiOutlinedInput-root": {
                                  background:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.inputTypeDark
                                      : assets.colors.inputTypeLight,
                                },
                              }}
                            >
                              <Select
                                labelId="label-position"
                                name="boardType"
                                fullWidth
                                id="boardType"
                                value={taskValIds[value.id]}
                                onChange={(e) => {
                                  selectTask(e, value);
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    padding: "8.5px 0px 8.5px 8.5px",
                                  },
                                  "& .MuiFormControl-root": {
                                    background:
                                      theme.palette.mode === "dark"
                                        ? assets.colors.inputTypeDark
                                        : assets.colors.inputTypeLight,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    background:
                                      theme.palette.mode === "dark"
                                        ? assets.colors.inputTypeDark
                                        : assets.colors.inputTypeLight,
                                  },
                                  "& .MuiInputBase-root": {
                                    background:
                                      theme.palette.mode === "dark"
                                        ? assets.colors.inputTypeDark
                                        : assets.colors.inputTypeLight,
                                  },
                                }}
                              >
                                {kpiBoardTasks[value.id] &&
                                  kpiBoardTasks[value.id].length !== 0 &&
                                  kpiBoardTasks[value.id].map(
                                    (boardType, index) => (
                                      <MenuItem
                                        key={index}
                                        value={boardType.id}
                                      >
                                        {boardType.title}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default KpiModal;
