import axiosClient from "./axiosClient";

const taskChecklistApi = {
  create: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/taskChecklists`, params),
  getOne: (boardId, taskId) =>
    axiosClient.get(`boards/${boardId}/taskChecklists/${taskId}`),
  delete: (boardId, taskChecklistId) =>
    axiosClient.delete(`boards/${boardId}/taskChecklists/${taskChecklistId}`),
  update: (boardId, taskChecklistId, params) =>
    axiosClient.put(
      `boards/${boardId}/taskChecklists/${taskChecklistId}`,
      params
    ),
};

export default taskChecklistApi;
