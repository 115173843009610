import axiosClient from './axiosClient'

const quoteApi = {
  create: params => axiosClient.post('quotes', params),
  getAll: () => axiosClient.get('quotes'),
  getSetupLogin: () => axiosClient.get('quotes/setupLogin'),  
  delete: (id) => axiosClient.delete(`quotes/${id}`),
  update: (id, params) => axiosClient.put(`quotes/${id}`, params),
}

export default quoteApi