import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import iconApi from "../../api/iconApi";
import AssessmentModal from "../../components/common/AssessmentModal";
import roleApi from "../../api/roleApi";
import employeeTypeApi from "../../api/employeeTypeApi";
import workStatusApi from "../../api/workStatusApi";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Badge,
  Avatar,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "scale",
    numeric: false,
    disablePadding: false,
    label: "Scale",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Avatar",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showDialogDeleteAssessment, selected, requestSearch, searchFilter } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormatListBulletedIcon sx={{ mr: 1 }} /> List Assessment
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => showDialogDeleteAssessment(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? assets.colors.inputTypeDark
                  : assets.colors.inputTypeLight,
              mt: 2,
              mr: 2,
              width: "300px",
            }}
            value={searchFilter}
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Search name..."
            type="search"
            onInput={(e) => requestSearch(e.target.value)}
          />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Assessments = () => {
  const theme = useTheme();
  const [selectedAssessment, setSelectedAssessment] = useState(undefined);
  const [selectedAssessmentLog, setSelectedAssessmentLog] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(true);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [imageErrText, setImageErrText] = useState("");

  const [fileURL, setFileURL] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openDialogDeleteFail, setOpenDialogDeleteFail] = useState(false);
  const [deleteFailCount, setDeleteFailCount] = useState(0);
  const [nameErrText, setNameErrText] = useState("");
  const [scaleErrText, setScaleErrText] = useState("");

  const [copyList, setCopyList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const requestSearch = (searched) => {
    setCopyList(rows.filter((item) => item.name.toString().toLowerCase().includes(searched)));
    setSearchFilter(searched);
  };

  const onUpdateAssessment = (assessment) => {
    const newData = [...rows];
    const assessmentIndex = newData.findIndex((e) => e._id === assessment.id);
    newData[assessmentIndex].name = assessment.name;
    newData[assessmentIndex].scale = assessment.scale;
    newData[assessmentIndex].icon_url = (
      <Avatar alt={assessment.name} src={assessment.icon_url} />
    );
    setRows(newData);
    setCopyList(newData);
  };

  const deleteAssessments = (assessments) => {
    assessments.forEach(async (value) => {
      try {
        const deleteJson = await iconApi.delete(value);
        if (deleteJson === "deleted") {
          const newData = [...rows].filter((e) => !assessments.includes(e.id));
          setRows(newData);
          setCopyList(newData);
          setSelected([]);
          hideDialog();
        } else {
          setDeleteFailCount(deleteJson);
          setOpenDialogDeleteFail(true);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const getAssessments = async () => {
      try {
        const jsonData = [];
        const res = await iconApi.getAll();
        res.forEach((value, index) => {
          jsonData.push({
            _id: value.id,
            id: value.id,
            name: value.name,
            scale: value.scale,
            icon_url: <Avatar alt={value.name} src={value.icon_url} />,
            action: (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedAssessment(value);
                    }}
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          });
        });
        setRows(jsonData);
        setCopyList(jsonData);
        console.log(jsonData);
      } catch (err) {
        console.log(err);
      }
    };
    getAssessments();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const visibleRowsCopy = useMemo(
    () =>
      stableSort(copyList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, copyList]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameErrText("");
    setScaleErrText("");

    const data = new FormData(e.target);
    const name = data.get("name").trim();
    const scale = data.get("scale").trim();
    const icon_url = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);

    let err = false;

    if (name === "") {
      err = true;
      setNameErrText("Please fill this field");
    }
    if (scale === "") {
      err = true;
      setScaleErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await iconApi.create({
        name,
        scale,
        icon_url,
      });
      setLoading(false);
      const newJsonData = {
        _id: res._id,
        id: res.id,
        name: res.name,
        scale: res.scale,

        icon_url: <Avatar alt={res.name} src={res.icon_url} />,
        action: (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedAssessment(res);
                }}
              >
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      };
      console.log(newJsonData);
      const newData = [...rows];
      newData.unshift(newJsonData);
      console.log(newData);
      setRows(newData);
      setCopyList(newData);
      e.target.reset();
      setFileURL(null);
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "name") {
          setNameErrText(e.msg);
        }
        if (e.param === "scale") {
          setScaleErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const [openDialogDeleteAssessment, setOpenDialogDeleteAssessment] =
    useState(false);
  const [idDeleteAssessment, setIdDeleteAssessment] = useState(false);

  const showDialogDeleteAssessment = (sectionId) => {
    setOpenDialogDeleteAssessment(true);
    setIdDeleteAssessment(sectionId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteAssessment(false);
    setOpenDialogDeleteFail(false);
  };

  const convertAndCompressHeic = async (file, type) => {
    const blob = file;
    try {
      setLoading(true);
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      } else {
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file, type) => {
    try {
      setLoading(true);
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 100,
        useWebWorker: true,
      });
      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      } else {
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "image");
    } else {
      compressNonHeic(newName, "image");
    }
  };

  return (
    <>
      {openDialogDeleteFail && (
        <Dialog
          open={openDialogDeleteFail}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There are still {deleteFailCount} tasks using this assessment,
              please check tasks
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={hideDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openDialogDeleteAssessment && (
        <Dialog
          open={openDialogDeleteAssessment}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete assessment
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteAssessments(idDeleteAssessment)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          // width: "100%",
          margin: "5px 25px 0px 25px",
        }}
      >
        <Typography
          sx={{
            padding: 0,
            fontSize: "1.5rem",
            fontWeight: "500",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          📝 Manage Assessment
        </Typography>
        <Typography
          sx={{
            padding: 0,
            marginTop: "2px",
            fontSize: "14px",
            fontWeight: "400",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          <i>Manage all assessments in the zabran scrum board application</i>
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            width: "50%",
            m: 2,
            p: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              display: "flex",
              alignItems: "center",
              mb: 2,
              color:
                theme.palette.mode === "dark"
                  ? assets.colors.rolesTextDark
                  : assets.colors.rolesTextLight,
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <AddOutlinedIcon sx={{ mr: 1 }} /> Add Assessment
          </Typography>
          <Box
            component="form"
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
            noValidate
          >
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                width: "100%",
                gap: "1px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                size="small"
                disabled={loading}
                error={nameErrText !== ""}
                helperText={nameErrText}
              />
            </Box>
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Scale <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                width: "100%",
                gap: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
                type="number"
                size="small"
                margin="normal"
                required
                fullWidth
                id="scale"
                name="scale"
                disabled={loading}
                error={scaleErrText !== ""}
                helperText={scaleErrText}
              />
            </Box>
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Icon <span style={{ color: "red" }}>*</span>
            </Typography>
            <Button
              disableElevation={true}
              variant="contained"
              sx={{
                background:
                  theme.palette.mode === "dark"
                    ? assets.colors.inputTypeDark
                    : assets.colors.inputTypeLight,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
                color: "#999",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                },
                mb: 1,
              }}
              component="label"
            >
              <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload Icon
              <input
                type="file"
                name="image"
                disabled={loading}
                error={imageErrText !== ""}
                helperText={imageErrText}
                accept="image/*"
                onChange={uploadImage}
                required
                hidden
              />
            </Button>
            {fileURL && (
              <Typography sx={{ fontSize: "14px", mb: 1 }}>
                Upload Icon Successfully
              </Typography>
            )}
            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              <AddOutlinedIcon sx={{ mr: 1 }} /> Create Assessment
            </LoadingButton>
          </Box>
        </Paper>
        <Paper
          sx={{
            width: "100%",
            m: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            showDialogDeleteAssessment={showDialogDeleteAssessment}
            requestSearch={requestSearch}
            searchFilter={searchFilter}
            selected={selected}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
              {(visibleRowsCopy.length > 0 ? visibleRowsCopy : visibleRows).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.scale}</TableCell>
                      <TableCell align="left">{row.icon_url}</TableCell>
                      <TableCell align="left">{row.action}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <AssessmentModal
        assessment={selectedAssessment}
        onClose={() => setSelectedAssessment(undefined)}
        onUpdate={onUpdateAssessment}
      />
    </>
  );
};

export default Assessments;
