import { useEffect, useState } from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import Moment from "moment";

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    // margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    width: 25,
    height: 25,
  },
  header: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5,
    textAlign: "center",
    color: "black",
  },
  subHeader: {
    fontSize: 12,
    marginBottom: 15,
    fontWeight: 700,
    marginBottom: 5,
    textAlign: "center",
    color: "black",
  },
  subSubHeader: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "left",
    color: "black",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    width: "100%",
    borderWidth: 2,
    display: "flex",
    flexDirection: "column",
    marginVertical: 0,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
    fontSize: 10,
  },
  h2: {
    display: "block",
    fontSize: 30,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  left: {
    // width: '33%',//<- working alternative
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 200,
  },

  right: {
    padding: 5,
    // width: '66%', //<- working alternative
    flexShrink: 1,
    flexGrow: 2,
  },
});

const Table = ({ children, col, th }) => (
  <View style={styles.table}>
    {children.map((row, ind) => (
      <View
        key={ind}
        style={[styles.tableRow, th && ind === 0 ? styles.em : {}]}
      >
        {row.map((cell, j) => (
          <View key={j} style={[styles.cell, { width: col[j], height: 15 }]}>
            {typeof cell === "string" || typeof cell === "number" ? (
              <Text>{cell}</Text>
            ) : (
              cell
            )}
          </View>
        ))}
      </View>
    ))}
  </View>
);

const PDFFile = (props) => {
  const result = props.result;
  const board = props.board;
  const daysNum = props.days.daysNum;
  const daysCol = props.days.daysCol;
  const monthYearName = props.monthYearName;
  const [sections, setSections] = useState([]);
  const [pieSections, setPieSections] = useState("");
  const [icon, setIcon] = useState("");
  const [lineA, setLineA] = useState([]);
  const [lineB, setLineB] = useState([]);
  const [tableSprint, setTableSprint] = useState([]);
  const [tableTimeline, setTableTimeline] = useState([]);
  const [usersPosition, setUsersPosition] = useState([]);
  // console.log(result)

  const getAllDaysInMonth = (month, year) =>
    Array.from(
      { length: new Date(year, month, 0).getDate() },
      (_, i) => new Date(year, month - 1, i + 1)
    );

  useEffect(() => {
    let jsonTimelines = [];
    let jsonPositions = [];
    let jsonPieSections = [];
    let jsonTableSprint = [];
    let jsonTableTimeline = [];
    //   Prediction
    let jsonLineA = [];
    //   Actuallyy
    let jsonLineB = [];
    let jsonTasks = [];
    result.forEach((value) => {
      const userColIndex = jsonPositions.findIndex(
        (e) => e === value.position.name
      );
      if (userColIndex === -1) {
        jsonPositions.push(value.position.name);
      }

      let taskCount = 0
      value.tasks.forEach((task, idx) => {
        if (task.section.title !== "GOALS") {
          const jsonTasksCheckerIndex = jsonTasks.findIndex(
            (e) => e.id === task.id
          );
          if (jsonTasksCheckerIndex === -1) {
            if (
              dayjs(new Date(Moment())).format("YYYY-MM-DD") <=
              dayjs(new Date(task.dueDate)).format("YYYY-MM-DD")
            ) {
              if (task.section.isDone != 1) {
                const jsonTableSprintIndex = jsonTableSprint.findIndex(
                  (e) => e.id === task.id
                );
                if (jsonTableSprintIndex === -1) {
                  jsonTableSprint.push(task);
                }
              }
            }
            taskCount = taskCount + 1;
            jsonTasks.push(task);
          }
        }
      });
      jsonTableTimeline.push({
        name: value.name,
        timelines: [],
      });
    });
    let sortedJsonTableSprint = jsonTableSprint.sort(
      (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
    );

    setTableSprint(sortedJsonTableSprint.slice(0, 20));

    console.log(jsonTasks)
    const momentMonthYear = Moment(monthYearName, "MMM YYYY");
    getAllDaysInMonth(Moment(momentMonthYear).format("M"), Moment(momentMonthYear).format("Y")).map((x) => {
      jsonLineA.push(
        jsonTasks
          .map((e, idx) =>
            dayjs(new Date(x)).format("YYYY-MM-DD") <=
            dayjs(new Date(e.dueDate)).format("YYYY-MM-DD")
              ? idx
              : ""
          )
          .filter(String).length
      );
      if (new Date(Moment()) >= new Date(x)) {
        let jsonCountB = 0;
        jsonTasks.forEach((value, idx) => {
          if (
            dayjs(new Date(value.updatedPositionDate)).format("YYYY-MM-DD") <=
            dayjs(new Date(x)).format("YYYY-MM-DD")
          ) {
            if (value.section.isDone != 1) {
              jsonCountB = jsonCountB + 1;
            }
          } else {
            jsonCountB = jsonCountB + 1;
          }
        });
        jsonLineB.push(jsonCountB);
      }
      let jsonTimeline = [];
      let jsonTasksChecker = [];
      jsonTasks.forEach((value, idx) => {
        if (
          dayjs(new Date(value.startDate)).format("YYYY-MM-DD") <=
            dayjs(new Date(x)).format("YYYY-MM-DD") &&
          dayjs(new Date(value.dueDate)).format("YYYY-MM-DD") >=
            dayjs(new Date(x)).format("YYYY-MM-DD")
        ) {
          const jsonTasksCheckerIndex = jsonTasksChecker.findIndex(
            (e) => e.id === value.id
          );
          if (jsonTasksCheckerIndex === -1) {
            jsonTasksChecker.push(value);
            value.users.forEach((user) => {
              jsonTimeline.push(user.name);
            });
          }
        }
      });

      // console.log(jsonTimeline);

      jsonTableTimeline.forEach((value, idx) => {
        let checker = 0;
        let jsonName = [];
        jsonTimeline.forEach((val, i) => {
          if (val === value.name) {
            checker = 1;
            jsonName.push(val);
          }
        });
        if (checker == 0) {
          jsonTableTimeline[idx].timelines.push([]);
        } else {
          jsonTableTimeline[idx].timelines.push(jsonName);
        }
      });
    });
    console.log(jsonTableTimeline)
    console.log(jsonPositions)
    console.log(jsonPieSections)
    console.log(jsonLineA)
    console.log(jsonLineB)
    setTableTimeline(jsonTableTimeline);
    setUsersPosition(jsonPositions);
    setPieSections(jsonPieSections);
    setLineA(jsonLineA);
    setLineB(jsonLineB);
  }, [result]);

  return (
    <Document>
      {result.map((value, index) => (
        <Page key={index} style={styles.body} size="A4" wrap>
          <Text style={styles.header} fixed>
            Report {board.title} {monthYearName}
          </Text>
          <Text style={styles.subHeader} fixed>
            {board.boardType.name}
          </Text>
          {/* <Text style={styles.subSubHeader} fixed>
            {dayjs(new Date(board.startDate)).format("YYYY-MM-DD")} -{" "}
            {dayjs(new Date(board.dueDate)).format("YYYY-MM-DD")}
          </Text> */}
          <Text
            style={[
              styles.text,
              { display: "flex", alignItems: "center", gap: 2 },
            ]}
          >
            <Image style={styles.image} src={value.image} />
            &nbsp; {value.name}
          </Text>
          <Text style={styles.subSubHeader}>
            Sprint Goal Timeline {monthYearName}
          </Text>
          <Table
            th
            col={daysCol}
            children={[
              daysNum,
              ["1", "2", "3"],
              ["4", "5", "6"],
              ["7", "8", "9"],
            ]}
          />

          <View style={[styles.row, { height: 700 }]}>
            <View debug style={styles.left}>
              <Text style={styles.h2}>Headline1</Text>
            </View>
            <View debug style={styles.right}>
              <Text>...</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View debug style={styles.left}>
              <Text style={styles.h2}>Headline3</Text>
            </View>
            <View debug style={styles.right}>
              <Text>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Text>
            </View>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      ))}
    </Document>
  );
};

export default PDFFile;
