import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import boardApi from "../../api/boardApi";
import { setFavouriteList } from "../../redux/features/favouriteSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useTheme } from "@mui/material/styles";
import assets from "../../assets/index";

const FavouriteList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.favourites.value);
  const [activeIndex, setActiveIndex] = useState(0);
  const { boardId } = useParams();
  const user = useSelector((state) => state.user.value);
  const [openDialogCreateBoard, setOpenDialogCreateBoard] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getBoards = async () => {
      try {
        const res = await boardApi.getFavourites();
        dispatch(setFavouriteList(res));
      } catch (err) {
        console.log(err);
      }
    };
    getBoards();
  }, []);

  useEffect(() => {
    const index = list.findIndex((e) => e.id === boardId);
    setActiveIndex(index);
  }, [list, boardId]);

  const onDragEnd = async ({ source, destination }) => {
    const newList = [...list];
    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);

    const activeItem = newList.findIndex((e) => e.id === boardId);
    setActiveIndex(activeItem);

    dispatch(setFavouriteList(newList));

    try {
      await boardApi.updateFavouritePosition({ boards: newList });
    } catch (err) {
      console.log(err);
    }
  };

  const addBoard = async () => {
    try {
      const res = await boardApi.create();
      const newList = [res, ...list];
      dispatch(setFavouriteList(newList));
      navigate(`/boards/${res.id}`);
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const showDialogCreateBoard = () => {
    setOpenDialogCreateBoard(true);
  };

  const hideDialog = () => {
    setOpenDialogCreateBoard(false);
  };

  return (
    <>
      {openDialogCreateBoard && (
        <Dialog
          open={openDialogCreateBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create board
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={addBoard} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ListItem>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            fontWeight="700"
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? assets.colors.sidebarTextDark
                  : assets.colors.sidebarTextLight,
            }}
          >
            Boards
          </Typography>
          {user.role.isManage === 1 && (
            <IconButton onClick={showDialogCreateBoard}>
              <AddBoxOutlinedIcon
                fontSize="small"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.sidebarTextDark
                      : assets.colors.sidebarTextLight,
                }}
              />
            </IconButton>
          )}
        </Box>
      </ListItem>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          key={"list-board-droppable-key"}
          droppableId={"list-board-droppable"}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItemButton
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      selected={index === activeIndex}
                      component={Link}
                      to={`/boards/${item.id}`}
                      sx={{
                        pl: "20px",
                        cursor: snapshot.isDragging
                          ? "grab"
                          : "pointer!important",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="400"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.sidebarTextDark
                              : assets.colors.sidebarTextLight,
                        }}
                      >
                        {item.icon} {item.title}{" "}
                        {item.boardType && (
                          <>
                            <br />
                            <span
                              style={{
                                marginLeft: "22px",
                                fontSize: "12px",
                              }}
                            >
                              {item.boardType.name === ""
                                ? item.boardType
                                : item.boardType.name}
                            </span>
                          </>
                        )}
                      </Typography>
                    </ListItemButton>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default FavouriteList;
