import axiosClient from "./axiosClient";

const positionApi = {
  create: (params) => axiosClient.post("positions", params),
  getAll: () => axiosClient.get("positions"),
  delete: (id) => axiosClient.delete(`positions/${id}`),
  update: (id, params) => axiosClient.put(`positions/${id}`, params),
};

export default positionApi;
