import {
  Backdrop,
  Fade,
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import userApi from "../../api/userApi";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PDFFile from "./PDFFile";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  color: "#999",
  pt: 1,
  pr: 3,
  pb: 1,
  pl: 3,
  height: "50%",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const ReportModal = (props) => {
  const theme = useTheme();
  const boardId = props.boardId;
  let users = props.users;
  const allUsers = props.allUsers;
  const [userId, setUserId] = useState("");
  const [month, setMonth] = useState(dayjs(Moment()));
  const [result, setResult] = useState(null);
  const [board, setBoard] = useState(null);
  const [fileName, setFileName] = useState("");
  const [days, setDays] = useState([]);
  const [monthYearName, setMonthYearName] = useState("");

  // useEffect(() => {
  //   setResult(null)
  //   setUserId("")
  // }, []);

  const currentAllUsers = [];
  allUsers.forEach((user, index) => {
    const colIndex = users.findIndex((e) => e.id === user.id);
    if (colIndex === -1) {
      currentAllUsers.push(user);
    }
  });

  const onClose = () => {
    isModalClosed = true;
    setResult(null);
    setBoard(null);
    setFileName("");
    setMonthYearName("");
    setDays([]);
    setUserId("");
    props.onUpdate(users);
    props.onClose();
  };

  const createUserBoard = async () => {
    try {
      try {
        const monthYear = dayjs(month).format("YYYY-M");
        const res = await userApi.getOne(boardId, userId, monthYear);
        setResult(res.users);
        setBoard(res.board);
        setFileName(res.fileName);
        setDays(res.days);
        setMonthYearName(res.monthYearName);
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectUser = (e) => {
    setUserId(e.target.value);
    setResult(null);
    setFileName("");
    setMonthYearName("");
    setDays([]);
    setBoard(null);
  };

  const selectMonth = (e) => {
    setMonth(e);
    setResult(null);
    setFileName("");
    setMonthYearName("");
    setDays([]);
    setBoard(null);
  };

  return (
    <>
      <Modal
        open={boardId !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={boardId !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Report Monthly
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onClose();
                  setResult(null);
                  setBoard(null);
                  setFileName("");
                  setMonthYearName("");
                  setDays([]);
                  setUserId("");
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "10px 0px 30px 0",
                justifyContent: "space-between",
              }}
            >
              {/* <FormControl fullWidth sx={{ mr: 2 }} size="small">
                <InputLabel id="label-position">Select Month</InputLabel>
                <Select
                  size="small"
                  value={month}
                  sx={{ width: "100%", color: "blue" }}
                  onChange={selectMonth}
                  label="Select User"
                >
                  {Moment.months().map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={month}
                  onChange={selectMonth}
                  dateFormat="mm-yyyy"
                  views={["month", "year"]}
                  sx={{
                    width: "100%",
                    mr: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                />
              </LocalizationProvider>
              <FormControl fullWidth sx={{ mr: 2 }} size="small">
                <InputLabel id="label-position">Select User</InputLabel>
                <Select
                  size="small"
                  value={userId}
                  sx={{ width: "100%", color: "blue" }}
                  onChange={selectUser}
                  label="Select User"
                >
                  <MenuItem key="all-currentUser" value="all">
                    All Users
                  </MenuItem>
                  {users.map((user, index) => (
                    <MenuItem key={`${index}-currentUser`} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                sx={{ minWidth: "200px" }}
                onClick={() => createUserBoard()}
              >
                Generate PDF
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "20px 0px 20px 0",
                justifyContent: "center",
              }}
            >
              {result && (
                <PDFDownloadLink
                  document={
                    <PDFFile
                      result={result}
                      board={board}
                      monthYearName={monthYearName}
                      days={days}
                    />
                  }
                  fileName={fileName}
                >
                  {({ loading }) =>
                    loading ? (
                      <Button>Loading Document</Button>
                    ) : (
                      <Button color="secondary" variant="contained">
                        Download PDF
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ReportModal;
