const assets = {
  images: {
    logoLight: require('./images/logo-light.png'),
    logoDark: require('./images/logo-dark.png'),
    appIcon: require('./images/favicon.png')
  },
  help: {
    docx: require('./images/Manual_Guide_ZabranScrumBoard_beta.docx'),
    pdf: require('./images/Manual_Guide_ZabranScrumBoard_beta.pdf')
  },
  colors: {
    DarkBgOpacity7: 'rgba(0, 0, 0,0.7)',
    LightBgOpacity7: 'rgba(255, 255, 255,0.7)',
    DarkBgOpacity8: 'rgba(0, 0, 0,0.8)',
    LightBgOpacity8: 'rgba(255, 255, 255,0.8)',

    headerDarkBg: '#121212',
    headerLightBg: '#FFF',
    sidebarDarkBg: '#121212',
    sidebarLightBg: '#FFF',
    secondary: '#292929',
    light: '#FFF',
    borderLightColor: '#eeeeee',
    borderDarkColor: '#c5c5c5',
    mainLightBg: "#f5f5f5",
    mainDarkBg: "#101204",
    borderDark: "#464646",
    borderLight: "#c5c5c5",
    sidebarTextDark: "#9f9f9f",
    sidebarTextLight: "#292929",
    boardTextDark: "#FFF",
    boardTextLight: "#6c6c6c",
    inputTypeDark: "#121212",
    inputTypeLight: "#FFF",
    taskDarkBg: "#101204",
    taskLightBg: "#FFF",
    taskTextDark: "#FFF",
    taskTextLight: "#6c6c6c",
    detailTaskTextDark: "#FFF",
    detailTaskTextLight: "#6c6c6c",
    usersTextDark: "#FFF",
    usersTextLight: "#6c6c6c",
    rolesTextDark: "#FFF",
    rolesTextLight: "#6c6c6c",
    notesTextDark: "#FFF",
    notesTextLight: "#6c6c6c",
    taskHoverBorderDark: "#FFF",
    taskHoverBorderLight: "#000"
  }
}

export default assets