import axiosClient from "./axiosClient";

const taskApi = {
  create: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/tasks`, params),
  search: (boardId, searchQuery, userId, searchSort, searchFilter, searchDetailFilter) =>
    axiosClient.get(
      `boards/${boardId}/tasks/search/${searchQuery}/${userId}/${searchSort}/${searchFilter}/${searchDetailFilter}`
    ),
  updatePosition: (boardId, params) =>
    axiosClient.put(`boards/${boardId}/tasks/update-position`, params),
  delete: (boardId, taskId) =>
    axiosClient.delete(`boards/${boardId}/tasks/${taskId}`),
  update: (boardId, taskId, params) =>
    axiosClient.put(`boards/${boardId}/tasks/${taskId}`, params),
  createUser: (boardId, taskId, userId) => axiosClient.get(`boards/${boardId}/tasks/${taskId}/createUser/${userId}`),
  deleteUser: (boardId, taskId, userId) => axiosClient.get(`boards/${boardId}/tasks/${taskId}/deleteUser/${userId}`),
};

export default taskApi;
