import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import noteApi from "../../api/noteApi";
import structureApi from "../../api/structureApi";
import NoteModal from "../../components/common/NoteModal";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EmojiPicker from "../../components/common/EmojiPicker";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyCustomUploadAdapterPlugin from "../../components/common/ckImageUploader";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import dayjs from "dayjs";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

let timer;
const timeout = 500;

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "icon",
    numeric: false,
    disablePadding: true,
    label: "Icon",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  // {
  //   id: "description",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Description",
  // },
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    label: "Writer",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    showDialogDeleteNote,
    selected,
    requestSearch,
    searchFilter,
  } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.notesTextDark
                : assets.colors.notesTextLight,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormatListBulletedIcon sx={{ mr: 1 }} /> List Roadmap
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => showDialogDeleteNote(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
          sx={{
            background:
              theme.palette.mode === "dark"
                ? assets.colors.inputTypeDark
                : assets.colors.inputTypeLight,
            mt: 2,
            mr: 2,
            width: "300px",
          }}
          value={searchFilter}
          size="small"
          margin="normal"
          variant="outlined"
          placeholder="Search name..."
          type="search"
          onInput={(e) => requestSearch(e.target.value)}
        />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CompanyRoadmap = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user.value);
  const [selectedNote, setSelectedNote] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [icon, setIcon] = useState("📃");

  const [fileURL, setFileURL] = useState(null);

  const [loading, setLoading] = useState(false);
  const [nameErrText, setNameErrText] = useState("");
  const [descriptionErrText, setDescriptionErrText] = useState("");
  const [priorityErrText, setPriorityErrText] = useState("");
  const [description, setDescription] = useState("");

  const [copyList, setCopyList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const requestSearch = (searched) => {
    setCopyList(
      rows.filter((item) =>
        item.name.toString().toLowerCase().includes(searched)
      )
    );
    setSearchFilter(searched);
  };

  const onUpdateNote = (note) => {
    // console.log(note)
    const newData = [...rows];
    const noteIndex = newData.findIndex((e) => e._id === note.id);
    newData[noteIndex].name = note.name;
    newData[noteIndex].icon = note.icon;
    newData[noteIndex].description = note.description;
    newData[noteIndex].priority = note.priority;
    setRows(newData);
    setCopyList(newData);
  };

  const inputDesc = (event, editor) => {
    setDescription(editor.getData());
  };

  const deleteNotes = (notes) => {
    notes.forEach(async (value) => {
      try {
        await noteApi.delete(value);
      } catch (err) {
        console.log(err);
      }
    });
    const newData = [...rows].filter((e) => !notes.includes(e.id));
    setRows(newData);
    setCopyList(newData);
    setSelected([]);
    hideDialog();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const getNotes = async () => {
      try {
        const jsonData = [];
        const res = await noteApi.getAll();
        res.forEach((value, index) => {
          jsonData.push({
            _id: value.id,
            id: value.id,
            icon: value.icon,
            name: value.name,
            createdAt: dayjs(value.createdAt).format("DD MMM YYYY"),
            description: value.description,
            user: value.user.name,
            action: (
              <Tooltip title="Edit">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedNote(value);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ),
          });
        });
        setRows(jsonData);
        setCopyList(jsonData);
      } catch (err) {
        console.log(err);
      }
    };
    getNotes();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const visibleRowsCopy = useMemo(
    () =>
      stableSort(copyList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, copyList]
  );

  const handleSubmit = async (e, userLoggedIn) => {
    e.preventDefault();
    setNameErrText("");
    setDescriptionErrText("");
    setPriorityErrText("");

    const data = new FormData(e.target);
    const name = data.get("name").trim();
    const priority = data.get("priority");

    let err = false;

    if (name === "") {
      err = true;
      setNameErrText("Please fill this field");
    }
    if (description === "") {
      err = true;
      setDescriptionErrText("Please fill this field");
    }
    if (priority === "") {
      err = true;
      setPriorityErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    const user = userLoggedIn.id;
    const isRead = [user];
    try {
      const res = await noteApi.create({
        name,
        description,
        icon,
        user,
        priority,
        isRead,
      });
      setLoading(false);
      const newJsonData = {
        _id: res.id,
        id: res.id,
        icon: icon,
        name: res.name,
        createdAt: dayjs(res.createdAt).format("DD MMM YYYY"),
        description: res.description,
        user: userLoggedIn.name,
        action: (
          <Tooltip title="Edit">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedNote(res);
              }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
      };
      const newData = [...rows];
      newData.unshift(newJsonData);
      console.log(newData);
      setRows(newData);
      setCopyList(newData);
      e.target.reset();
      setDescription("");
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "name") {
          setNameErrText(e.msg);
        }
        if (e.param === "description") {
          setDescriptionErrText(e.msg);
        }
        if (e.param === "priority") {
          setPriorityErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  const [openDialogDeleteNote, setOpenDialogDeleteNote] = useState(false);
  const [idDeleteNote, setIdDeleteNote] = useState(false);

  const showDialogDeleteNote = (sectionId) => {
    setOpenDialogDeleteNote(true);
    setIdDeleteNote(sectionId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteNote(false);
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName);
    } else {
      compressNonHeic(newName);
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const onIconChange = async (newIcon) => {
    setIcon(newIcon);
  };

  const updateImage = async (e) => {
    clearTimeout(timer);
    const image = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);
    timer = setTimeout(async () => {
      try {
        await structureApi.create({
          image,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setFileURL(null);
  };

  const convertAndCompressHeic = async (file) => {
    const blob = file;
    try {
      setLoading(true);
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });

      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });

      setFileURL(convertedFile);

      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file) => {
    try {
      setLoading(true);
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        // maxWidthOrHeight: 100,
        useWebWorker: true,
      });

      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });

      setFileURL(convertedFile);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {openDialogDeleteNote && (
        <Dialog
          open={openDialogDeleteNote}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-desc"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-desc">
              Are you sure delete note
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteNotes(idDeleteNote)} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          // width: "100%",
          margin: "5px 25px 0px 25px",
        }}
      >
        <Typography
          sx={{
            padding: 0,
            fontSize: "1.5rem",
            fontWeight: "500",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.notesTextDark
                : assets.colors.notesTextLight,
          }}
        >
          🛣️ Manage Roadmap
        </Typography>
        <Typography
          sx={{
            padding: 0,
            marginTop: "2px",
            fontSize: "14px",
            fontWeight: "400",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.notesTextDark
                : assets.colors.notesTextLight,
          }}
        >
          <i>
            Manage all Company Roadmap in the zabran scrum board application
          </i>
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            width: "50%",
            m: 2,
            p: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              display: "flex",
              alignItems: "center",
              color:
                theme.palette.mode === "dark"
                  ? assets.colors.notesTextDark
                  : assets.colors.notesTextLight,
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <NoteAddIcon sx={{ mr: 1 }} /> Add Company Roadmap
          </Typography>
          <Box
            component="form"
            sx={{ mt: 1 }}
            onSubmit={(e) => handleSubmit(e, user)}
            noValidate
          >
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Icon <span style={{ color: "red" }}>*</span>
            </Typography>
            <EmojiPicker icon={icon} onChange={onIconChange} />
            <Typography sx={{ color: "rgb(108, 108, 108)", mt: 1 }}>
              Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                width: "100%",
                gap: "1px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                disabled={loading}
                error={nameErrText !== ""}
                helperText={nameErrText}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
              />
            </Box>
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                gap: "1px",
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <CKEditor
                data={description}
                editor={ClassicEditor}
                config={{
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "insertTable",
                      "|",
                      "undo",
                      "redo",
                    ],
                  },
                  extraPlugins: [MyCustomUploadAdapterPlugin],
                }}
                id="description"
                onChange={inputDesc}
              />
              {/* <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                multiline
                id="description"
                name="description"
                rows={2}
                maxRows={4}
                disabled={loading}
                error={descriptionErrText !== ""}
                helperText={descriptionErrText}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
              /> */}
            </Box>
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Priority Color <span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              error={priorityErrText !== ""}
              helperText={priorityErrText}
            >
              {["low", "medium", "high"].map((value, index) => (
                <FormControlLabel
                  key={index}
                  name="priority"
                  value={value}
                  control={
                    <Radio
                      sx={{
                        color:
                          value === "low"
                            ? yellow[800]
                            : value === "medium"
                            ? orange[800]
                            : red[800],
                        "&.Mui-checked": {
                          color:
                            value === "low"
                              ? yellow[800]
                              : value === "medium"
                              ? orange[800]
                              : red[800],
                        },
                      }}
                    />
                  }
                  label={value}
                />
              ))}
            </RadioGroup>
            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              <NoteAddIcon sx={{ mr: 1 }} /> Create Company Roadmap
            </LoadingButton>
          </Box>
        </Paper>
        <Paper
          sx={{
            width: "100%",
            m: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            showDialogDeleteNote={showDialogDeleteNote}
            requestSearch={requestSearch}
            searchFilter={searchFilter}
            selected={selected}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {(visibleRowsCopy.length > 0
                  ? visibleRowsCopy
                  : visibleRows
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      note="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.icon}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.createdAt}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.description}
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.user}
                      </TableCell>
                      <TableCell align="right">{row.action}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Box
        sx={{
          width: "100%",
          margin: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      ></Box>

      <NoteModal
        note={selectedNote}
        onClose={() => setSelectedNote(undefined)}
        onUpdate={onUpdateNote}
      />
    </>
  );
};

export default CompanyRoadmap;
