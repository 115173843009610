import axiosClient from "./axiosClient";

const rewardApi = {
  create: (params) => axiosClient.post("rewards", params),
  getAll: () => axiosClient.get("rewards"),
  createUserReward: (params) => axiosClient.post("rewards/createUserReward", params),
  updateBoardReward: (boardId, rewardId) => axiosClient.get(`rewards/updateBoardReward/${boardId}/${rewardId}`),
  delete: (id) => axiosClient.delete(`rewards/${id}`),
  deleteUserReward: (boardId, userId) => axiosClient.delete(`rewards/deleteUserReward/${boardId}/${userId}`),
  update: (id, params) => axiosClient.put(`rewards/${id}`, params),
};

export default rewardApi;
