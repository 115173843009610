import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  IconButton,
  Card,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Badge,
  Menu,
  MenuItem,
  Popper,
  Fade,
  Paper,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  CardHeader,
  CardActions,
  Grid,
  Collapse,
  ClickAwayListener,
  Chip,
  useAutocomplete,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
  grey,
} from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SortIcon from "@mui/icons-material/Sort";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import dayjs from "dayjs";
import sectionApi from "../../api/sectionApi";
import taskApi from "../../api/taskApi";
import userApi from "../../api/userApi";
import activityApi from "../../api/activityApi";
import TaskModal from "./TaskModal";
import TaskModalView from "./TaskModalView";
import TaskModalSection from "./TaskModalSection";
import Moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ArrowDropDown } from "@mui/icons-material";
import { ArrowDropUp } from "@mui/icons-material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../css/custom-editor.css";
import { useTheme } from "@mui/material/styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
import assets from "../../assets/index";
import Slider, { SliderThumb } from "@mui/material/Slider";
import DescriptionIcon from "@mui/icons-material/Description";
import PDFFile from "./PDFFile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const CheckMarkSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 0,
    width: 0,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .checkmark-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 5,
    background:
      theme.palette.mode === "dark"
        ? "linear-gradient(-45deg, #ccc 40%, #333 50%, #ccc 60%)"
        : "linear-gradient(-45deg, #ccc 40%, #999 50%, #ccc 60%)",
    backgroundSize: "300%",
    backgroundPositionX: "100%",
    animation: "shimmer 5s infinite linear",
  },
  "@keyframes shimmer": {
    to: {
      backgroundPositionX: "0%",
    },
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

const CheckMarkThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {/* <Typography sx={{ transform: "scale(-1, 1)", fontSize: "30px" }}>
        {other.ownerState.icon}
      </Typography> */}
      <Typography sx={{ marginLeft: "-25px" }}>
        <span
          style={{
            transform: "scale(-1, 1)",
            fontSize: "30px",
            position: "absolute",
          }}
        >
          {other.ownerState.icon}
        </span>
        <br />
        {other.ownerState.value !== 100 &&
        other.ownerState.value !== 0 &&
        other.ownerState.value !== NaN &&
        other.ownerState.value !== null &&
        other.ownerState.value !== undefined ? (
          <span
            style={{
              transform: "unset",
              fontSize: "12px",
              position: "relative",
              top: "8px",
            }}
          >
            {other.ownerState.value && other.ownerState.value.toFixed(1)}%
          </span>
        ) : (
          <span
            style={{
              transform: "unset",
              fontSize: "12px",
              position: "relative",
              top: "8px",
            }}
          >
            &nbsp;
          </span>
        )}
      </Typography>
    </SliderThumb>
  );
};

const Label = styled("label")`
  padding: 0 0 4px;
  margin-top: 10px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  marginTop: "3px",
  marginBottom: "0px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

let timer;
const timeout = 500;

const Kanban = (props) => {
  const theme = useTheme();
  const [isPDFFile, setIsPDFFile] = useState({});
  const [enableSearch, setEnableSearch] = useState(0);
  const user = useSelector((state) => state.user.value);
  const [users, setUsers] = useState([]);
  const [progTeam, setProgTeam] = useState(props.progTeam);
  const boardId = props.boardId;
  const [boardType, setBoardType] = useState(props.boardType);
  const boardDueDate = props.boardDueDate;
  const boardUser = props.boardUser;
  const boardDueDateProgress = props.boardDueDateProgress;
  const boardStartDateProgress = props.boardStartDateProgress;
  const tags = props.tags;
  const [data, setData] = useState([]);
  const [showAssessment, setShowAssessment] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const [isOpenCollapseUser, setIsOpenCollapseUser] = useState({});
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [selectedTaskView, setSelectedTaskView] = useState(undefined);
  const [selectedSection, setSelectedSection] = useState(undefined);

  const [openDialogCreateTask, setOpenDialogCreateTask] = useState(false);
  const [idCreateTaskSection, setIdCreateTaskSection] = useState(false);
  const [idCreateTaskUser, setIdCreateTaskUser] = useState(false);

  const [openDialogDeleteSection, setOpenDialogDeleteSection] = useState(false);
  const [idDeleteSection, setIdDeleteSection] = useState(false);

  const [getSearchQuery, setSearchQuery] = useState(0);
  const [getSearchSort, setSearchSort] = useState(0);
  const [getSearchFilter, setSearchFilter] = useState(0);
  const [getSearchDetailFilter, setSearchDetailFilter] = useState(0);
  const [getSearchDetailFilterTags, setSearchDetailFilterTags] = useState([]);

  const [isDropDisabled, setIsDropDisabled] = useState(false);
  const [isDropDisabledCard, setIsDropDisabledCard] = useState(false);

  const icons = props.icons;
  const speedIcons = props.speedIcons;

  const showDialogDeleteSection = (sectionId) => {
    setOpenDialogDeleteSection(true);
    setIdDeleteSection(sectionId);
  };

  const showDialogCreateTask = (sectionId, userId) => {
    setOpenDialogCreateTask(true);
    setIdCreateTaskSection(sectionId);
    setIdCreateTaskUser(userId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteSection(false);
    setOpenDialogCreateTask(false);
  };

  const ref = useRef();

  useOnClickOutside(ref, () => setShowAssessment(false));

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  useEffect(() => {
    if (props.data !== null) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    setBoardType(props.boardType);
    setUsers(props.users);
    setProgTeam(props.progTeam);
    if (user.role.isManage !== 1) {
      let jsonData = {};
      props.users.forEach((getUser) => {
        if (getUser.id === user.id) {
          jsonData[getUser.id] = true;
        }
      });
      setIsOpenCollapseUser(jsonData);
    }
  }, [props.users]);

  const onDragEnd = async ({ source, destination, type }) => {
    // console.log(type);
    // console.log(source);
    // console.log(destination);
    if (user.role.isManage === 1 || boardUser === user.id) {
      if (source.droppableId === "cards") {
        clearTimeout(timer);
        const taskId = destination.droppableId.split("-")[0];
        const sectionId = destination.droppableId.split("-")[2].split("|")[0];
        const userId = destination.droppableId.split("-")[1];
        const taskIcons = destination.droppableId.split("|||")[1];
        let jsonTaskIcons = [];
        let jsonTaskViewIcons = [];
        taskIcons.split("||").forEach((taskIcon, index) => {
          if (taskIcon.split("|")[0] !== "") {
            jsonTaskIcons.push({
              icon: taskIcon.split("|")[0],
              user: taskIcon.split("|")[2],
            });
            jsonTaskViewIcons.push({
              icon: taskIcon.split("|")[0],
              iconImage: taskIcon.split("|")[1],
              user: taskIcon.split("|")[2],
              userImage: taskIcon.split("|")[3],
            });
          }
        });
        const colIndex = jsonTaskIcons.findIndex(
          (e) => e.icon === source.index && e.user === user.id
        );
        const colIdx = icons.findIndex((e) => e.id === source.index);
        console.log(destination.droppableId);
        console.log(jsonTaskIcons);
        console.log(icons);
        console.log(source.index);
        // console.log(icons[colIdx].icon_url);
        if (colIndex === -1) {
          let jsonTaskIcon = {
            user: user.id,
            icon: icons[colIdx].id,
          };
          let jsonTaskViewIcon = {
            user: user.id,
            userImage: user.image,
            icon: icons[colIdx].id,
            iconImage: icons[colIdx].icon_url,
          };
          const newTaskIcons = [...jsonTaskIcons, jsonTaskIcon];
          const newTaskViewIcons = [...jsonTaskViewIcons, jsonTaskViewIcon];
          timer = setTimeout(async () => {
            try {
              await taskApi.update(boardId, taskId, { icons: newTaskIcons });
            } catch (err) {
              console.log(err);
            }
          }, timeout);
          const colIndexUser = users.findIndex((e) => e.id === userId);
          const colIndexSection = users[colIndexUser].sections.findIndex(
            (e) => e.id === sectionId
          );
          const colIndexTask = users[colIndexUser].sections[
            colIndexSection
          ].tasks.findIndex((e) => e.id === taskId);
          const task =
            users[colIndexUser].sections[colIndexSection].tasks[colIndexTask];
          task.icons = newTaskViewIcons;
          setUsers(users);
        }
      } else {
        let destinationDroppableId = "";
        let sourceColIndex = "";
        let destinationColIndex = "";
        let sourceCol = "";
        let destinationCol = "";
        let sourceSectionId = "";
        let destinationSectionId = "";
        let sourceTasks = [];
        let destinationTasks = [];
        let taskId = "";
        let [removed] = "";
        const sourceDroppableId = source.droppableId.split("-")[0];
        const userId = source.droppableId.split("-")[1];
        const userColIndex = users.findIndex((e) => e.id === userId);
        destinationDroppableId = destination.droppableId.split("-")[0];
        if (!destination) return;
        sourceColIndex = users[userColIndex].sections.findIndex(
          (e) => e.id === sourceDroppableId
        );
        destinationColIndex = users[userColIndex].sections.findIndex(
          (e) => e.id === destinationDroppableId
        );
        sourceCol = users[userColIndex].sections[sourceColIndex];
        destinationCol = users[userColIndex].sections[destinationColIndex];
        if (user.role.isManage !== 1 && boardUser === user.id) {
          if (destinationCol.isDone !== 1) {
            sourceSectionId = sourceCol.id;
            destinationSectionId = destinationCol.id;
            sourceTasks = [...sourceCol.tasks];
            destinationTasks = [...destinationCol.tasks];
            taskId = sourceTasks[source.index];
            sourceTasks[source.index].section = {
              board: destinationCol.board,
              color: destinationCol.color,
              createdAt: destinationCol.createdAt,
              id: destinationCol.id,
              isDone: destinationCol.isDone,
              title: destinationCol.title,
              __v: destinationCol.__v,
              _id: destinationCol._id,
            };
            if (sourceDroppableId !== destinationDroppableId) {
              [removed] = sourceTasks.splice(source.index, 1);
              destinationTasks.splice(destination.index, 0, removed);
              users[userColIndex].sections[sourceColIndex].tasks = sourceTasks;
              users[userColIndex].sections[destinationColIndex].tasks =
                destinationTasks;
            } else {
              [removed] = destinationTasks.splice(source.index, 1);
              destinationTasks.splice(destination.index, 0, removed);
              users[userColIndex].sections[destinationColIndex].tasks =
                destinationTasks;
            }

            try {
              await taskApi.updatePosition(boardId, {
                resourceList: sourceTasks,
                destinationList: destinationTasks,
                resourceSectionId: sourceSectionId,
                destinationSectionId: destinationSectionId,
              });
              if (data !== null) {
                setData(data);
              }
            } catch (err) {
              console.log(err);
            }

            if (sourceCol.isDone === 1) {
              if (taskId.taskChecklists.length === 0) {
                users[userColIndex].totalChecklistDone =
                  users[userColIndex].totalChecklistDone - 1;
                users[userColIndex].totalChecklistUndone =
                  users[userColIndex].totalChecklistUndone + 1;
                users[userColIndex].progNum =
                  (users[userColIndex].totalChecklistDone /
                    users[userColIndex].totalChecklist) *
                  100;

                let Difference_In_Time =
                  new Date(boardDueDateProgress).getTime() -
                  new Date(boardStartDateProgress).getTime();
                const totalDay = Math.round(
                  Difference_In_Time / (1000 * 3600 * 24)
                );
                let Difference_In_Time_Elapsed =
                  Date.now() - new Date(boardStartDateProgress).getTime();
                const dayElapsed = Math.round(
                  Difference_In_Time_Elapsed / (1000 * 3600 * 24)
                );

                const avgSpeedMin =
                  users[userColIndex].totalChecklist / totalDay;
                const treshold =
                  users[userColIndex].totalChecklist - avgSpeedMin * dayElapsed;
                const speed =
                  (treshold - users[userColIndex].totalChecklistUndone) /
                  avgSpeedMin;

                let progIcon = "";
                for (const speedIcon of speedIcons) {
                  if (speedIcon.speed == Math.round(speed)) {
                    progIcon = speedIcon.icon;
                  }
                }
                if (progIcon == "") {
                  if (speedIcons[0].speed >= Math.round(speed)) {
                    progIcon = speedIcons[0].icon;
                  } else {
                    if (
                      speedIcons[speedIcons.length - 1].speed <=
                      Math.round(speed)
                    ) {
                      progIcon = speedIcons[speedIcons.length - 1].icon;
                    }
                  }
                }
                users[userColIndex].progIcon = progIcon;

                progTeam.totalCheckDone = progTeam.totalCheckDone - 1;
                progTeam.totalCheckUndone = progTeam.totalCheckUndone + 1;
                const totalChecklistsTeam = progTeam.totalCheck;

                const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
                const tresholdTeam =
                  progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
                const speedTeam =
                  (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

                let progIconTeam = "";
                for (const speedIcon of speedIcons) {
                  if (speedIcon.speed == Math.round(speedTeam)) {
                    progIconTeam = speedIcon.icon;
                  }
                }
                if (progIconTeam == "") {
                  if (speedIcons[0].speed >= Math.round(speedTeam)) {
                    progIconTeam = speedIcons[0].icon;
                  } else {
                    if (
                      speedIcons[speedIcons.length - 1].speed <=
                      Math.round(speedTeam)
                    ) {
                      progIconTeam = speedIcons[speedIcons.length - 1].icon;
                    }
                  }
                }

                progTeam.icon = progIconTeam;
                progTeam.num =
                  (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
                props.onUpdateUsers([...users], progTeam);
              }
            }

            if (destinationCol.isDone === 1) {
              if (taskId.taskChecklists.length === 0) {
                users[userColIndex].totalChecklistDone =
                  users[userColIndex].totalChecklistDone + 1;
                users[userColIndex].totalChecklistUndone =
                  users[userColIndex].totalChecklistUndone - 1;
                users[userColIndex].progNum =
                  (users[userColIndex].totalChecklistDone /
                    users[userColIndex].totalChecklist) *
                  100;

                let Difference_In_Time =
                  new Date(boardDueDateProgress).getTime() -
                  new Date(boardStartDateProgress).getTime();
                const totalDay = Math.round(
                  Difference_In_Time / (1000 * 3600 * 24)
                );
                let Difference_In_Time_Elapsed =
                  Date.now() - new Date(boardStartDateProgress).getTime();
                const dayElapsed = Math.round(
                  Difference_In_Time_Elapsed / (1000 * 3600 * 24)
                );

                const avgSpeedMin =
                  users[userColIndex].totalChecklist / totalDay;
                const treshold =
                  users[userColIndex].totalChecklist - avgSpeedMin * dayElapsed;
                const speed =
                  (treshold - users[userColIndex].totalChecklistUndone) /
                  avgSpeedMin;

                let progIcon = "";
                for (const speedIcon of speedIcons) {
                  if (speedIcon.speed == Math.round(speed)) {
                    progIcon = speedIcon.icon;
                  }
                }
                if (progIcon == "") {
                  if (speedIcons[0].speed >= Math.round(speed)) {
                    progIcon = speedIcons[0].icon;
                  } else {
                    if (
                      speedIcons[speedIcons.length - 1].speed <=
                      Math.round(speed)
                    ) {
                      progIcon = speedIcons[speedIcons.length - 1].icon;
                    }
                  }
                }
                users[userColIndex].progIcon = progIcon;

                progTeam.totalCheckDone = progTeam.totalCheckDone + 1;
                progTeam.totalCheckUndone = progTeam.totalCheckUndone - 1;
                const totalChecklistsTeam = progTeam.totalCheck;

                const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
                const tresholdTeam =
                  progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
                const speedTeam =
                  (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

                let progIconTeam = "";
                for (const speedIcon of speedIcons) {
                  if (speedIcon.speed == Math.round(speedTeam)) {
                    progIconTeam = speedIcon.icon;
                  }
                }
                if (progIconTeam == "") {
                  if (speedIcons[0].speed >= Math.round(speedTeam)) {
                    progIconTeam = speedIcons[0].icon;
                  } else {
                    if (
                      speedIcons[speedIcons.length - 1].speed <=
                      Math.round(speedTeam)
                    ) {
                      progIconTeam = speedIcons[speedIcons.length - 1].icon;
                    }
                  }
                }

                progTeam.icon = progIconTeam;
                progTeam.num =
                  (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
                props.onUpdateUsers([...users], progTeam);
              }
            }

            users.forEach((value, userColIndex) => {
              if (value.id !== userId) {
                destinationDroppableId = destination.droppableId.split("-")[0];
                if (!destination) return;
                sourceColIndex = users[userColIndex].sections.findIndex(
                  (e) => e.id === sourceDroppableId
                );
                destinationColIndex = users[userColIndex].sections.findIndex(
                  (e) => e.id === destinationDroppableId
                );
                sourceCol = users[userColIndex].sections[sourceColIndex];
                destinationCol =
                  users[userColIndex].sections[destinationColIndex];

                sourceSectionId = sourceCol.id;
                destinationSectionId = destinationCol.id;

                sourceTasks = [...sourceCol.tasks];
                destinationTasks = [...destinationCol.tasks];

                const sourceIndex = sourceTasks.findIndex(
                  (e) => e.id === taskId.id
                );
                const destinationIndex = 0;

                if (sourceIndex !== -1) {
                  taskId = sourceTasks[sourceIndex];
                  // console.log(sourceTasks);
                  // console.log(destinationIndex);
                  // console.log(taskId);

                  // console.log(users[userColIndex]);

                  if (sourceDroppableId !== destinationDroppableId) {
                    [removed] = sourceTasks.splice(sourceIndex, 1);
                    // console.log(sourceTasks);
                    // console.log(destinationTasks);
                    destinationTasks.splice(destinationIndex, 0, removed);
                    // console.log(destinationTasks);
                    users[userColIndex].sections[sourceColIndex].tasks =
                      sourceTasks;
                    users[userColIndex].sections[destinationColIndex].tasks =
                      destinationTasks;
                  } else {
                    [removed] = destinationTasks.splice(sourceIndex, 1);
                    destinationTasks.splice(destinationIndex, 0, removed);
                    users[userColIndex].sections[destinationColIndex].tasks =
                      destinationTasks;
                  }
                }
              }
            });

            setUsers([...users]);

            if (destinationSectionId !== sourceSectionId) {
              const currentUserId = user.id;
              const sectionId = destinationSectionId;
              const lastSectionId = sourceSectionId;
              const message = "Update Section";
              const type = 2;
              await activityApi.create(boardId, {
                taskId,
                currentUserId,
                sectionId,
                message,
                type,
                lastSectionId,
              });
            }
          }
        } else {
          sourceSectionId = sourceCol.id;
          destinationSectionId = destinationCol.id;
          sourceTasks = [...sourceCol.tasks];
          destinationTasks = [...destinationCol.tasks];
          taskId = sourceTasks[source.index];
          sourceTasks[source.index].section = {
            board: destinationCol.board,
            color: destinationCol.color,
            createdAt: destinationCol.createdAt,
            id: destinationCol.id,
            isDone: destinationCol.isDone,
            title: destinationCol.title,
            __v: destinationCol.__v,
            _id: destinationCol._id,
          };
          if (sourceDroppableId !== destinationDroppableId) {
            [removed] = sourceTasks.splice(source.index, 1);
            destinationTasks.splice(destination.index, 0, removed);
            users[userColIndex].sections[sourceColIndex].tasks = sourceTasks;
            users[userColIndex].sections[destinationColIndex].tasks =
              destinationTasks;
          } else {
            [removed] = destinationTasks.splice(source.index, 1);
            destinationTasks.splice(destination.index, 0, removed);
            users[userColIndex].sections[destinationColIndex].tasks =
              destinationTasks;
          }

          try {
            await taskApi.updatePosition(boardId, {
              resourceList: sourceTasks,
              destinationList: destinationTasks,
              resourceSectionId: sourceSectionId,
              destinationSectionId: destinationSectionId,
            });
            if (data !== null) {
              setData(data);
            }
          } catch (err) {
            console.log(err);
          }

          if (sourceCol.isDone === 1) {
            if (taskId.taskChecklists.length === 0) {
              users[userColIndex].totalChecklistDone =
                users[userColIndex].totalChecklistDone - 1;
              users[userColIndex].totalChecklistUndone =
                users[userColIndex].totalChecklistUndone + 1;
              users[userColIndex].progNum =
                (users[userColIndex].totalChecklistDone /
                  users[userColIndex].totalChecklist) *
                100;

              let Difference_In_Time =
                new Date(boardDueDateProgress).getTime() -
                new Date(boardStartDateProgress).getTime();
              const totalDay = Math.round(
                Difference_In_Time / (1000 * 3600 * 24)
              );
              let Difference_In_Time_Elapsed =
                Date.now() - new Date(boardStartDateProgress).getTime();
              const dayElapsed = Math.round(
                Difference_In_Time_Elapsed / (1000 * 3600 * 24)
              );

              const avgSpeedMin = users[userColIndex].totalChecklist / totalDay;
              const treshold =
                users[userColIndex].totalChecklist - avgSpeedMin * dayElapsed;
              const speed =
                (treshold - users[userColIndex].totalChecklistUndone) /
                avgSpeedMin;

              let progIcon = "";
              for (const speedIcon of speedIcons) {
                if (speedIcon.speed == Math.round(speed)) {
                  progIcon = speedIcon.icon;
                }
              }
              if (progIcon == "") {
                if (speedIcons[0].speed >= Math.round(speed)) {
                  progIcon = speedIcons[0].icon;
                } else {
                  if (
                    speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
                  ) {
                    progIcon = speedIcons[speedIcons.length - 1].icon;
                  }
                }
              }
              users[userColIndex].progIcon = progIcon;

              progTeam.totalCheckDone = progTeam.totalCheckDone - 1;
              progTeam.totalCheckUndone = progTeam.totalCheckUndone + 1;
              const totalChecklistsTeam = progTeam.totalCheck;

              const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
              const tresholdTeam =
                progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
              const speedTeam =
                (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

              let progIconTeam = "";
              for (const speedIcon of speedIcons) {
                if (speedIcon.speed == Math.round(speedTeam)) {
                  progIconTeam = speedIcon.icon;
                }
              }
              if (progIconTeam == "") {
                if (speedIcons[0].speed >= Math.round(speedTeam)) {
                  progIconTeam = speedIcons[0].icon;
                } else {
                  if (
                    speedIcons[speedIcons.length - 1].speed <=
                    Math.round(speedTeam)
                  ) {
                    progIconTeam = speedIcons[speedIcons.length - 1].icon;
                  }
                }
              }

              progTeam.icon = progIconTeam;
              progTeam.num =
                (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
              props.onUpdateUsers([...users], progTeam);
            }
          }

          if (destinationCol.isDone === 1) {
            if (taskId.taskChecklists.length === 0) {
              users[userColIndex].totalChecklistDone =
                users[userColIndex].totalChecklistDone + 1;
              users[userColIndex].totalChecklistUndone =
                users[userColIndex].totalChecklistUndone - 1;
              users[userColIndex].progNum =
                (users[userColIndex].totalChecklistDone /
                  users[userColIndex].totalChecklist) *
                100;

              let Difference_In_Time =
                new Date(boardDueDateProgress).getTime() -
                new Date(boardStartDateProgress).getTime();
              const totalDay = Math.round(
                Difference_In_Time / (1000 * 3600 * 24)
              );
              let Difference_In_Time_Elapsed =
                Date.now() - new Date(boardStartDateProgress).getTime();
              const dayElapsed = Math.round(
                Difference_In_Time_Elapsed / (1000 * 3600 * 24)
              );

              const avgSpeedMin = users[userColIndex].totalChecklist / totalDay;
              const treshold =
                users[userColIndex].totalChecklist - avgSpeedMin * dayElapsed;
              const speed =
                (treshold - users[userColIndex].totalChecklistUndone) /
                avgSpeedMin;

              let progIcon = "";
              for (const speedIcon of speedIcons) {
                if (speedIcon.speed == Math.round(speed)) {
                  progIcon = speedIcon.icon;
                }
              }
              if (progIcon == "") {
                if (speedIcons[0].speed >= Math.round(speed)) {
                  progIcon = speedIcons[0].icon;
                } else {
                  if (
                    speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
                  ) {
                    progIcon = speedIcons[speedIcons.length - 1].icon;
                  }
                }
              }
              users[userColIndex].progIcon = progIcon;

              progTeam.totalCheckDone = progTeam.totalCheckDone + 1;
              progTeam.totalCheckUndone = progTeam.totalCheckUndone - 1;
              const totalChecklistsTeam = progTeam.totalCheck;

              const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
              const tresholdTeam =
                progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
              const speedTeam =
                (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

              let progIconTeam = "";
              for (const speedIcon of speedIcons) {
                if (speedIcon.speed == Math.round(speedTeam)) {
                  progIconTeam = speedIcon.icon;
                }
              }
              if (progIconTeam == "") {
                if (speedIcons[0].speed >= Math.round(speedTeam)) {
                  progIconTeam = speedIcons[0].icon;
                } else {
                  if (
                    speedIcons[speedIcons.length - 1].speed <=
                    Math.round(speedTeam)
                  ) {
                    progIconTeam = speedIcons[speedIcons.length - 1].icon;
                  }
                }
              }

              progTeam.icon = progIconTeam;
              progTeam.num =
                (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
              props.onUpdateUsers([...users], progTeam);
            }
          }

          users.forEach((value, userColIndex) => {
            if (value.id !== userId) {
              destinationDroppableId = destination.droppableId.split("-")[0];
              if (!destination) return;
              sourceColIndex = users[userColIndex].sections.findIndex(
                (e) => e.id === sourceDroppableId
              );
              destinationColIndex = users[userColIndex].sections.findIndex(
                (e) => e.id === destinationDroppableId
              );
              sourceCol = users[userColIndex].sections[sourceColIndex];
              destinationCol =
                users[userColIndex].sections[destinationColIndex];

              sourceSectionId = sourceCol.id;
              destinationSectionId = destinationCol.id;

              sourceTasks = [...sourceCol.tasks];
              destinationTasks = [...destinationCol.tasks];

              const sourceIndex = sourceTasks.findIndex(
                (e) => e.id === taskId.id
              );
              const destinationIndex = 0;

              if (sourceIndex !== -1) {
                taskId = sourceTasks[sourceIndex];
                // console.log(sourceTasks);
                // console.log(destinationIndex);
                // console.log(taskId);

                // console.log(users[userColIndex]);

                if (sourceDroppableId !== destinationDroppableId) {
                  [removed] = sourceTasks.splice(sourceIndex, 1);
                  // console.log(sourceTasks);
                  // console.log(destinationTasks);
                  destinationTasks.splice(destinationIndex, 0, removed);
                  // console.log(destinationTasks);
                  users[userColIndex].sections[sourceColIndex].tasks =
                    sourceTasks;
                  users[userColIndex].sections[destinationColIndex].tasks =
                    destinationTasks;
                } else {
                  [removed] = destinationTasks.splice(sourceIndex, 1);
                  destinationTasks.splice(destinationIndex, 0, removed);
                  users[userColIndex].sections[destinationColIndex].tasks =
                    destinationTasks;
                }
              }
            }
          });

          setUsers([...users]);

          if (destinationSectionId !== sourceSectionId) {
            const currentUserId = user.id;
            const sectionId = destinationSectionId;
            const lastSectionId = sourceSectionId;
            const message = "Update Section";
            const type = 2;
            await activityApi.create(boardId, {
              taskId,
              currentUserId,
              sectionId,
              message,
              type,
              lastSectionId,
            });
          }
        }
      }
    } else {
      let destinationDroppableId = "";
      let sourceColIndex = "";
      let destinationColIndex = "";
      let sourceCol = "";
      let destinationCol = "";
      let sourceSectionId = "";
      let destinationSectionId = "";
      let sourceTasks = [];
      let destinationTasks = [];
      let taskId = "";
      let [removed] = "";
      const sourceDroppableId = source.droppableId.split("-")[0];
      const userId = user.id;
      const userColIndex = users.findIndex((e) => e.id === userId);
      destinationDroppableId = destination.droppableId.split("-")[0];
      if (!destination) return;
      sourceColIndex = users[userColIndex].sections.findIndex(
        (e) => e.id === sourceDroppableId
      );
      destinationColIndex = users[userColIndex].sections.findIndex(
        (e) => e.id === destinationDroppableId
      );
      sourceCol = users[userColIndex].sections[sourceColIndex];
      destinationCol = users[userColIndex].sections[destinationColIndex];
      sourceSectionId = sourceCol.id;
      destinationSectionId = destinationCol.id;

      if (destinationCol.isDone !== 1) {
        sourceTasks = [...sourceCol.tasks];
        destinationTasks = [...destinationCol.tasks];
        taskId = sourceTasks[source.index];
        sourceTasks[source.index].section = {
          board: destinationCol.board,
          color: destinationCol.color,
          createdAt: destinationCol.createdAt,
          id: destinationCol.id,
          isDone: destinationCol.isDone,
          title: destinationCol.title,
          __v: destinationCol.__v,
          _id: destinationCol._id,
        };
        if (sourceDroppableId !== destinationDroppableId) {
          [removed] = sourceTasks.splice(source.index, 1);
          destinationTasks.splice(destination.index, 0, removed);
          users[userColIndex].sections[sourceColIndex].tasks = sourceTasks;
          users[userColIndex].sections[destinationColIndex].tasks =
            destinationTasks;
        } else {
          [removed] = destinationTasks.splice(source.index, 1);
          destinationTasks.splice(destination.index, 0, removed);
          users[userColIndex].sections[destinationColIndex].tasks =
            destinationTasks;
        }

        try {
          await taskApi.updatePosition(boardId, {
            resourceList: sourceTasks,
            destinationList: destinationTasks,
            resourceSectionId: sourceSectionId,
            destinationSectionId: destinationSectionId,
          });
          if (data !== null) {
            setData(data);
          }
        } catch (err) {
          console.log(err);
        }

        users.forEach((value, userColIndex) => {
          if (value.id !== userId) {
            destinationDroppableId = destination.droppableId.split("-")[0];
            if (!destination) return;
            sourceColIndex = users[userColIndex].sections.findIndex(
              (e) => e.id === sourceDroppableId
            );
            destinationColIndex = users[userColIndex].sections.findIndex(
              (e) => e.id === destinationDroppableId
            );
            sourceCol = users[userColIndex].sections[sourceColIndex];
            destinationCol = users[userColIndex].sections[destinationColIndex];

            sourceSectionId = sourceCol.id;
            destinationSectionId = destinationCol.id;

            sourceTasks = [...sourceCol.tasks];
            destinationTasks = [...destinationCol.tasks];

            const sourceIndex = sourceTasks.findIndex(
              (e) => e.id === taskId.id
            );
            const destinationIndex = 0;

            if (sourceIndex !== -1) {
              taskId = sourceTasks[sourceIndex];
              // console.log(sourceTasks);
              // console.log(destinationIndex);
              // console.log(taskId);

              // console.log(users[userColIndex]);

              if (sourceDroppableId !== destinationDroppableId) {
                [removed] = sourceTasks.splice(sourceIndex, 1);
                // console.log(sourceTasks);
                // console.log(destinationTasks);
                destinationTasks.splice(destinationIndex, 0, removed);
                // console.log(destinationTasks);
                users[userColIndex].sections[sourceColIndex].tasks =
                  sourceTasks;
                users[userColIndex].sections[destinationColIndex].tasks =
                  destinationTasks;
              } else {
                [removed] = destinationTasks.splice(sourceIndex, 1);
                destinationTasks.splice(destinationIndex, 0, removed);
                users[userColIndex].sections[destinationColIndex].tasks =
                  destinationTasks;
              }
            }
          }
        });

        setUsers([...users]);

        if (destinationSectionId !== sourceSectionId) {
          const currentUserId = user.id;
          const sectionId = destinationSectionId;
          const lastSectionId = sourceSectionId;
          const message = "Update Section";
          const type = 2;
          await activityApi.create(boardId, {
            taskId,
            currentUserId,
            sectionId,
            message,
            type,
            lastSectionId,
          });
        }
      }
    }
    setIsDropDisabled(false);
    setIsDropDisabledCard(false);
  };

  const onDragStart = async ({ source }) => {
    if (source.droppableId === "cards") {
      setIsDropDisabled(true);
      setIsDropDisabledCard(false);
    } else {
      setIsDropDisabled(false);
      setIsDropDisabledCard(true);
    }
  };

  const deleteSection = async (sectionId) => {
    try {
      await sectionApi.delete(boardId, sectionId);
      const newData = [...data].filter((e) => e.id !== sectionId);
      users.forEach((value) => {
        const newSections = [...value.sections].filter(
          (e) => e.id !== sectionId
        );
        value.sections = newSections;
      });
      if (newData !== null) {
        setData(newData);
      }
      setUsers([...users]);
      props.onUpdate(newData);
      hideDialog();
      setAnchorElAction(null);
    } catch (err) {
      console.log(err);
    }
  };

  const updateSectionTitle = async (e, sectionId) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    const newData = [...data];
    const index = newData.findIndex((e) => e.id === sectionId);
    newData[index].title = newTitle;
    if (newData !== null) {
      setData(newData);
    }
    if (user.role.isManage === 1) {
      users.forEach((value) => {
        const indexCol = value.sections.findIndex((e) => e.id === sectionId);
        value.sections[indexCol].title = newTitle;
      });
    }
    timer = setTimeout(async () => {
      try {
        await sectionApi.update(boardId, sectionId, { title: newTitle });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const createTask = async (sectionId, userId) => {
    const userAssigneeId = user.id;
    try {
      const task = await taskApi.create(boardId, {
        sectionId,
        userId,
        userAssigneeId,
      });

      let Difference_In_Time =
        new Date(boardDueDateProgress).getTime() -
        new Date(boardStartDateProgress).getTime();
      const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      let Difference_In_Time_Elapsed =
        Date.now() - new Date(boardStartDateProgress).getTime();
      const dayElapsed = Math.round(
        Difference_In_Time_Elapsed / (1000 * 3600 * 24)
      );

      if (user.role.isManage === 1) {
        const userColIndex = users.findIndex((e) => e.id === userId);
        const newData = [...users[userColIndex].sections];
        const index = newData.findIndex((e) => e.id === sectionId);
        newData[index].tasks.unshift(task);
        if (newData !== null) {
          setData(newData);
        }

        let totalChecklist = 0;
        task.users.forEach((user) => {
          const colIndexUser = users.findIndex((e) => e.id === user.id);
          users[colIndexUser].totalChecklist =
            users[colIndexUser].totalChecklist + 1;
          users[colIndexUser].totalChecklistUndone =
            users[colIndexUser].totalChecklistUndone + 1;
          totalChecklist = totalChecklist + 1;
          users[colIndexUser].progNum =
            (users[colIndexUser].totalChecklistDone /
              users[colIndexUser].totalChecklist) *
            100;

          const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
          const treshold =
            users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
          const speed =
            (treshold - users[colIndexUser].totalChecklistUndone) / avgSpeedMin;

          let progIcon = "";
          for (const speedIcon of speedIcons) {
            if (speedIcon.speed == Math.round(speed)) {
              progIcon = speedIcon.icon;
            }
          }
          if (progIcon == "") {
            if (speedIcons[0].speed >= Math.round(speed)) {
              progIcon = speedIcons[0].icon;
            } else {
              if (
                speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
              ) {
                progIcon = speedIcons[speedIcons.length - 1].icon;
              }
            }
          }

          users[colIndexUser].progIcon = progIcon;
        });
        progTeam.totalCheck = progTeam.totalCheck + totalChecklist;
        progTeam.totalCheckUndone = progTeam.totalCheckUndone + 1;
        const totalChecklistsTeam = progTeam.totalCheck;

        const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
        const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
        const speedTeam =
          (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

        let progIconTeam = "";
        for (const speedIcon of speedIcons) {
          if (speedIcon.speed == Math.round(speedTeam)) {
            progIconTeam = speedIcon.icon;
          }
        }
        if (progIconTeam == "") {
          if (speedIcons[0].speed >= Math.round(speedTeam)) {
            progIconTeam = speedIcons[0].icon;
          } else {
            if (
              speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
            ) {
              progIconTeam = speedIcons[speedIcons.length - 1].icon;
            }
          }
        }
        progTeam.icon = progIconTeam;
        progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
        props.onUpdateUsers([...users], progTeam);
      } else {
        // const newData = [...data];
        // const index = newData.findIndex((e) => e.id === sectionId);
        // newData[index].tasks.unshift(task);
        // if (newData !== null) {
        //   setData(newData);
        // }
        const userColIndex = users.findIndex((e) => e.id === userId);
        const newData = [...users[userColIndex].sections];
        const index = newData.findIndex((e) => e.id === sectionId);
        newData[index].tasks.unshift(task);
        if (newData !== null) {
          setData(newData);
        }
      }
      const taskId = task.id;
      const currentUserId = user.id;
      const message = "Create Task";
      const type = 0;
      const lastSectionId = 0;
      await activityApi.create(boardId, {
        taskId,
        currentUserId,
        sectionId,
        message,
        type,
        lastSectionId,
      });
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateTask = (task) => {
    const newData = [...data];
    const sectionIndex = newData.findIndex((e) => e.id === task.section.id);
    const taskIndex = newData[sectionIndex].tasks.findIndex(
      (e) => e.id === task.id
    );
    newData[sectionIndex].tasks[taskIndex] = task;
    if (newData !== null) {
      setData(newData);
    }
  };

  const onUpdateTaskKanban = (task, lastTaskUsers) => {
    if (user.role.isManage === 1) {
      let checker = 0;
      task.users.forEach((value) => {
        const userColIndex = users.findIndex((e) => e.id === value.id);
        if (userColIndex !== -1) {
          checker = 1;
        }
      });

      console.log(checker);

      if (checker === 1) {
        console.log(task);
        users.forEach((value, userColIndex) => {
          const newData = [...users[userColIndex].sections];
          // console.log(newData);
          const sectionIndex = newData.findIndex(
            (e) => e.id === task.section.id
          );
          const taskIndex = newData[sectionIndex].tasks.findIndex(
            (e) => e.id === task.id
          );
          // console.log(taskIndex);
          if (taskIndex !== -1) {
            newData[sectionIndex].tasks.splice(taskIndex, 1);
            users[userColIndex].sections = newData;
            setUsers(users);
          }
        });

        // console.log(task.users);

        task.users.forEach((value) => {
          const userColIndex = users.findIndex((e) => e.id === value.id);
          if (userColIndex !== -1) {
            const newData = [...users[userColIndex].sections];
            const sectionIndex = newData.findIndex(
              (e) => e.id === task.section.id
            );
            const taskIndex = newData[sectionIndex].tasks.findIndex(
              (e) => e.id === task.id
            );
            // console.log(taskIndex);
            if (taskIndex === -1) {
              newData[sectionIndex].tasks.unshift(task);
            }
            users[userColIndex].sections = newData;
            // console.log(newData);
            // console.log(users);
            setUsers(users);
          }
        });
      }
    }
  };

  const onUpdateTags = (tags) => {
    props.onUpdateTags(tags);
  };

  const onUpdateUsers = (users, progTeam) => {
    setUsers([...users]);
    console.log(progTeam);
    props.onUpdateUsers(users, progTeam);
  };

  const onUpdateSection = (section) => {
    if (user.role.isManage === 1) {
      users.forEach((value) => {
        console.log(section);
        console.log(value.sections);
        const indexCol = value.sections.findIndex((e) => e.id === section.id);
        value.sections[indexCol].title = section.title;
        value.sections[indexCol].color = section.color;
      });
    }
    // const newData = [...data];
    // const sectionIndex = newData.findIndex((e) => e.id === task.section.id);
    // const taskIndex = newData[sectionIndex].tasks.findIndex(
    //   (e) => e.id === task.id
    // );
    // newData[sectionIndex].tasks[taskIndex] = task;
    // setData(newData);
  };

  const onDeleteTask = (task) => {
    if (user.role.isManage === 1) {
      task.users.forEach((user) => {
        const userColIndex = users.findIndex((e) => e.id === user.id);
        console.log(userColIndex);
        const newData = [...users[userColIndex].sections];
        const sectionIndex = newData.findIndex((e) => e.id === task.section.id);
        const taskIndex = newData[sectionIndex].tasks.findIndex(
          (e) => e.id === task.id
        );
        newData[sectionIndex].tasks.splice(taskIndex, 1);
        if (newData !== null) {
          setData(newData);
        }
        setUsers([...users]);
      });
    } else {
      const newData = [...data];
      const sectionIndex = newData.findIndex((e) => e.id === task.section.id);
      const taskIndex = newData[sectionIndex].tasks.findIndex(
        (e) => e.id === task.id
      );
      newData[sectionIndex].tasks.splice(taskIndex, 1);
      if (newData !== null) {
        setData(newData);
      }
    }
  };

  const [anchorElAction, setAnchorElAction] = useState(null);
  const openAction = Boolean(anchorElAction);
  const handleClickAction = (index, event) => {
    setAnchorElAction({ [index]: event.currentTarget });
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElFilter(null);
  };

  const searchTask = async (e) => {
    if (enableSearch !== 0) {
      clearTimeout(timer);
      let searchQuery;
      if (e === "") {
        searchQuery = 0;
      } else {
        searchQuery = e;
      }
      const searchSort = getSearchSort;
      const searchFilter = getSearchFilter;
      let searchDetailFilter;
      if (searchFilter === "tags") {
        let tagValues = getSearchDetailFilterTags
          .map((tag) => tag.id)
          .join(",");
        if (tagValues === "") {
          tagValues = 0;
        }
        searchDetailFilter = tagValues;
      } else if (searchFilter === "startDate" || searchFilter === "dueDate") {
        searchDetailFilter = dayjs(new Date(getSearchDetailFilter)).format(
          "YYYY-MM-DD"
        );
      } else {
        searchDetailFilter = getSearchDetailFilter;
      }
      setSearchQuery(e);

      timer = setTimeout(async () => {
        try {
          if (user.role.isManage === 1) {
            const userId = user.id;
            const searchResults = await taskApi.search(
              boardId,
              searchQuery,
              userId,
              searchSort,
              searchFilter,
              searchDetailFilter
            );
            setUsers(searchResults.users.sort((a, b) => b.progNum - a.progNum));
            props.onUpdateProgTeam(
              searchResults.progTeam.num,
              searchResults.progTeam.icon
            );
          } else {
            const userId = user.id;
            const searchResults = await taskApi.search(
              boardId,
              searchQuery,
              userId,
              searchSort,
              searchFilter,
              searchDetailFilter
            );
            setData(searchResults);
          }
        } catch (err) {
          console.log(err);
        }
      }, timeout);
    } else {
      setEnableSearch(1);
    }
  };

  useEffect(() => {
    searchTask(props.searchQuery);
  }, [props.searchQuery]);

  const searchTaskSort = async (value) => {
    const searchSort = value;
    let searchQuery;
    if (getSearchQuery === "") {
      searchQuery = 0;
    } else {
      searchQuery = getSearchQuery;
    }
    const searchFilter = getSearchFilter;
    let searchDetailFilter;
    if (searchFilter === "tags") {
      let tagValues = getSearchDetailFilterTags.map((tag) => tag.id).join(",");
      if (tagValues === "") {
        tagValues = 0;
      }
      searchDetailFilter = tagValues;
    } else if (searchFilter === "startDate" || searchFilter === "dueDate") {
      searchDetailFilter = dayjs(new Date(getSearchDetailFilter)).format(
        "YYYY-MM-DD"
      );
    } else {
      searchDetailFilter = getSearchDetailFilter;
    }
    setSearchSort(value);
    try {
      if (user.role.isManage === 1) {
        const userId = user.id;
        const searchResults = await taskApi.search(
          boardId,
          searchQuery,
          userId,
          searchSort,
          searchFilter,
          searchDetailFilter
        );
        setUsers(searchResults.users.sort((a, b) => b.progNum - a.progNum));
        props.onUpdateProgTeam(
          searchResults.progTeam.num,
          searchResults.progTeam.icon
        );
      } else {
        const userId = user.id;
        const searchResults = await taskApi.search(
          boardId,
          searchQuery,
          userId,
          searchSort,
          searchFilter,
          searchDetailFilter
        );

        setData(searchResults);
      }
    } catch (err) {
      console.log(err);
    }
    setAnchorEl(null);
  };

  const handleChangeFilter = async (e) => {
    const filter = e.target.value;
    setSearchFilter(filter);
    setSearchDetailFilter(0);
    setSearchDetailFilterTags([]);
    if (filter === "0") {
      let searchQuery;
      if (getSearchQuery === "") {
        searchQuery = 0;
      } else {
        searchQuery = getSearchQuery;
      }
      const searchSort = getSearchSort;
      const searchFilter = "0";
      const searchDetailFilter = "0";
      timer = setTimeout(async () => {
        try {
          if (user.role.isManage === 1) {
            const userId = user.id;
            const searchResults = await taskApi.search(
              boardId,
              searchQuery,
              userId,
              searchSort,
              searchFilter,
              searchDetailFilter
            );
            setUsers(searchResults.users.sort((a, b) => b.progNum - a.progNum));
            props.onUpdateProgTeam(
              searchResults.progTeam.num,
              searchResults.progTeam.icon
            );
          } else {
            const userId = user.id;
            const searchResults = await taskApi.search(
              boardId,
              searchQuery,
              userId,
              searchSort,
              searchFilter,
              searchDetailFilter
            );

            setData(searchResults);
          }
        } catch (err) {
          console.log(err);
        }
      }, timeout);
      clickAwayHandler();
      setAnchorElFilter(null);
    }
  };

  const searchTaskFilter = async (value) => {
    let searchQuery;
    if (getSearchQuery === "") {
      searchQuery = 0;
    } else {
      searchQuery = getSearchQuery;
    }
    const searchSort = getSearchSort;
    const searchFilter = getSearchFilter;
    let searchDetailFilter;
    if (getSearchFilter === "startDate" || getSearchFilter === "dueDate") {
      searchDetailFilter = dayjs(new Date(value)).format("YYYY-MM-DD");
      setSearchDetailFilter(new Date(value));
    }
    if (getSearchFilter === "priority") {
      searchDetailFilter = value.target.value;
      setSearchDetailFilter(value.target.value);
    }
    if (getSearchFilter === "tags") {
      let tagValues = value.map((tag) => tag.id).join(",");
      if (tagValues === "") {
        tagValues = 0;
      }
      searchDetailFilter = tagValues;
      setSearchDetailFilterTags(value);
    }

    try {
      if (user.role.isManage === 1) {
        const userId = user.id;
        const searchResults = await taskApi.search(
          boardId,
          searchQuery,
          userId,
          searchSort,
          searchFilter,
          searchDetailFilter
        );
        setUsers(searchResults.users.sort((a, b) => b.progNum - a.progNum));
        props.onUpdateProgTeam(
          searchResults.progTeam.num,
          searchResults.progTeam.icon
        );
      } else {
        const userId = user.id;
        const searchResults = await taskApi.search(
          boardId,
          searchQuery,
          userId,
          searchSort,
          searchFilter,
          searchDetailFilter
        );

        setData(searchResults);
      }
    } catch (err) {
      console.log(err);
    }
    clickAwayHandler();
    setAnchorElFilter(null);
  };

  const handleCollapse = async (clickedIndex) => {
    // clearTimeout(timer);
    // timer = setTimeout(async () => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
    // }, timeout);
  };

  const handleCollapseUser = async (id) => {
    setIsOpenCollapseUser((set) => ({
      ...set,
      [id]: !set[id],
    }));
  };

  const handlePDFFile = async (id) => {
    setIsPDFFile((set) => ({
      ...set,
      [id]: !set[id],
    }));
  };

  function handleMouseOver() {
    setShowAssessment(true);
  }

  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [placementFilter, setPlacementFilter] = useState();

  const [isOpen, setIsOpen] = useState(false),
    clickAwayHandler = () => setIsOpen(false),
    clickHandler = (newPlacementFilter) => (event) => {
      setAnchorElFilter(event.currentTarget);
      setIsOpen((prev) => placementFilter !== newPlacementFilter || !prev);
      setPlacementFilter(newPlacementFilter);
    };

  const [popperAnc, setPopperAnc] = useState(null);

  const popperOpen = Boolean(popperAnc);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorElTags,
  } = useAutocomplete({
    id: "customized-hook-demo",
    value: getSearchDetailFilterTags,
    multiple: true,
    options: tags,
    getOptionLabel: (option) => option.name,
    onChange: (e, value) => searchTaskFilter(value),
  });

  return (
    <>
      {openDialogDeleteSection && (
        <Dialog
          open={openDialogDeleteSection}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete section
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteSection(idDeleteSection)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDialogCreateTask && (
        <Dialog
          open={openDialogCreateTask}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create task
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => createTask(idCreateTaskSection, idCreateTaskUser)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {user.role.isManage === 1 && users.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        ></Box>
      )}
      {/* <Divider sx={{ margin: "10px 0" }} /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            sx={{ padding: "3px 8px", mr: 1, fontSize: "12px" }}
            onClick={clickHandler("bottom-start")}
          >
            <SortIcon /> &nbsp;Filter
          </Button>
          {isOpen && (
            <ClickAwayListener onClickAway={clickAwayHandler}>
              <Popper
                sx={{ zIndex: 1200 }}
                open={isOpen}
                anchorEl={anchorElFilter}
                placement={placementFilter}
                transition
              >
                {({ TransitionProps }) => (
                  <>
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper sx={{ width: "400px" }}>
                        <Typography sx={{ p: 2 }}>
                          <FormControl size="small">
                            <InputLabel id="demo-simple-select-label">
                              Select Filter
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              value={getSearchFilter}
                              id="demo-simple-select"
                              label="Select Filter"
                              sx={{ width: "150px", marginRight: "10px" }}
                              onChange={handleChangeFilter}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: true,
                                disablePortal: true,
                                anchorEl: { popperAnc },
                                style: {
                                  marginTop: "40px",
                                  width: "150px",
                                  height: "350px",
                                },
                              }}
                            >
                              <MenuItem value="0">Default</MenuItem>
                              <MenuItem value="tags">Tags</MenuItem>
                              <MenuItem value="priority">Priority</MenuItem>
                              <MenuItem value="startDate">Start Date</MenuItem>
                              <MenuItem value="dueDate">Due Date</MenuItem>
                            </Select>
                          </FormControl>
                          {getSearchFilter === "dueDate" ||
                          getSearchFilter === "startDate" ? (
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              size="small"
                            >
                              {getSearchDetailFilter === 0 ? (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  onChange={searchTaskFilter}
                                  sx={{
                                    width: "170px",
                                    "& .MuiOutlinedInput-input": {
                                      padding: "9px 10px",
                                    },
                                  }}
                                />
                              ) : (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  value={dayjs(getSearchDetailFilter)}
                                  sx={{
                                    width: "170px",
                                    "& .MuiOutlinedInput-input": {
                                      padding: "9px 10px",
                                    },
                                  }}
                                  onChange={searchTaskFilter}
                                />
                              )}
                            </LocalizationProvider>
                          ) : getSearchFilter === "priority" ? (
                            <FormControl size="small">
                              <Select
                                value={getSearchDetailFilter}
                                onChange={searchTaskFilter}
                                sx={{ width: "150px" }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: true,
                                  disablePortal: true,
                                  anchorEl: { popperAnc },
                                  style: {
                                    marginTop: "40px",
                                    marginLeft: "150px",
                                    width: "150px",
                                    height: "350px",
                                  },
                                }}
                              >
                                <MenuItem value="0">Default</MenuItem>
                                <MenuItem value="low">low</MenuItem>
                                <MenuItem value="medium">medium</MenuItem>
                                <MenuItem value="high">high</MenuItem>
                              </Select>
                            </FormControl>
                          ) : getSearchFilter === "tags" ? (
                            <Box>
                              <div {...getRootProps()}>
                                <Label {...getInputLabelProps()}>Tags</Label>
                                <InputWrapper
                                  ref={setAnchorElTags}
                                  sx={{ width: "100%" }}
                                  className={focused ? "focused" : ""}
                                >
                                  {value.map((option, index) => (
                                    <StyledTag
                                      label={option.name}
                                      {...getTagProps({ index })}
                                    />
                                  ))}
                                  <input {...getInputProps()} />
                                </InputWrapper>
                              </div>
                              {groupedOptions.length > 0 ? (
                                <Listbox {...getListboxProps()}>
                                  {groupedOptions.map((option, index) => (
                                    <li {...getOptionProps({ option, index })}>
                                      <span>{option.name}</span>
                                      <CheckIcon fontSize="small" />
                                    </li>
                                  ))}
                                </Listbox>
                              ) : null}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Paper>
                    </Fade>
                  </>
                )}
              </Popper>
            </ClickAwayListener>
          )}

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            disableElevation={true}
            sx={{ padding: "3px 8px", fontSize: "12px" }}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="contained"
            color="primary"
          >
            <SwapVertIcon /> Sort By
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => searchTaskSort("default")}>
              Default
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("title|+")}>
              Task Name ASC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("title|-")}>
              Task Name DESC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("startDate|+")}>
              Start Date ASC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("startDate|-")}>
              Start Date DESC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("dueDate|+")}>
              Due Date ASC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("dueDate|-")}>
              Due Date DESC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("priority|+")}>
              Priority ASC
            </MenuItem>
            <MenuItem onClick={() => searchTaskSort("priority|-")}>
              Priority DESC
            </MenuItem>
          </Menu>
          <span style={{ fontSize: "12px", marginLeft: "10px" }}>
            <span style={{ marginRight: "10px" }}>
              {getSearchSort !== 0
                ? getSearchSort !== "default"
                  ? `Sort By : ${
                      getSearchSort.split("|")[0] === "title"
                        ? "Task Name"
                        : getSearchSort.split("|")[0] === "startDate"
                        ? "Start Date"
                        : getSearchSort.split("|")[0] === "dueDate"
                        ? "Due Date"
                        : getSearchSort.split("|")[0] === "priority"
                        ? "Priority"
                        : ""
                    }  ${
                      getSearchSort.split("|")[1] === "+"
                        ? "Ascending"
                        : getSearchSort.split("|")[1] === "-"
                        ? "Descending"
                        : ""
                    }`
                  : ""
                : ""}
            </span>
            {getSearchDetailFilter !== 0
              ? `Filter By : ${
                  getSearchFilter === "startDate"
                    ? `Start Date ${dayjs(getSearchDetailFilter).format(
                        "DD MMM YYYY"
                      )}`
                    : getSearchFilter === "dueDate"
                    ? `Due Date ${dayjs(getSearchDetailFilter).format(
                        "DD MMM YYYY"
                      )}`
                    : getSearchFilter === "priority"
                    ? `Priority ${getSearchDetailFilter.toUpperCase()}`
                    : ""
                } `
              : ""}
            {Object.keys(getSearchDetailFilterTags).length !== 0 ? (
              <span>
                Filter By : Tags{" "}
                {getSearchDetailFilterTags.map((tag) => `"${tag.name}" `)}
              </span>
            ) : (
              ""
            )}
          </span>
        </Box>
        {/* <div>
          <TextField
            placeholder="Search Task"
            onChange={(e) => searchTask(e)}
            variant="outlined"
            sx={{
              flexGrow: 1,
              "& .MuiOutlinedInput-input": { padding: "5px 10px" },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #333",
              },
              "& .MuiOutlinedInput-root": {
                fontSize: "1rem",
                fontWeight: "700",
              },
            }}
          />
        </div> */}
      </Box>

      {user.role.isManage === 1 || boardUser === user.id ? (
        <>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            {user.role.isManage === 1 && (
              <>
                <Button
                  onMouseOver={handleMouseOver}
                  onClick={() => setShowAssessment((prev) => !prev)}
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  sx={{
                    position: "fixed",
                    marginTop: "20px",
                    right: "0",
                    zIndex: "2",
                    writingMode: "vertical-rl",
                    padding: "4px 2px",
                    margin: 0,
                    borderRadius: 0,
                    height: "unset",
                    minWidth: "unset",
                    fontSize: "10px",
                  }}
                >
                  Assessment
                  {showAssessment && (
                    <div
                      ref={ref}
                      style={{
                        writingMode: "horizontal-tb",
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.DarkBgOpacity8
                            : assets.colors.LightBgOpacity8,
                        border:
                          theme.palette.mode === "dark"
                            ? "1px solid " + assets.colors.borderDark
                            : "1px solid " + assets.colors.borderLight,
                        position: "fixed",
                        zIndex: 3,
                        right: "20px",
                        opacity: !showAssessment ? "0" : "1",
                        transition: "all .2s",
                        visibility: !showAssessment ? "hidden" : "visible",
                      }}
                    >
                      <div key="cards">
                        <Droppable key="cards" droppableId="cards">
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              sx={{
                                margin: "10px",
                              }}
                            >
                              <Grid container spacing={2}>
                                {icons.map((icon, index) => (
                                  <Grid item xs={12} sm={3} key={icon.id}>
                                    <Draggable
                                      key={icon.id}
                                      draggableId={icon.id}
                                      index={icon.id}
                                    >
                                      {(provided, snapshot) => (
                                        <Card
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          sx={{
                                            backgroundColor: "#121212",
                                            width: "50px",
                                            boxShadow: "unset",
                                            padding: "0px",
                                          }}
                                        >
                                          <img
                                            src={icon.icon_url}
                                            alt={icon.id}
                                            height="50px"
                                            width="50px"
                                          />
                                        </Card>
                                      )}
                                    </Draggable>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  )}
                </Button>
              </>
            )}

            {users &&
              users.length !== 0 &&
              users.map((item, index) => (
                <Box
                  key={item.id}
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.DarkBgOpacity8
                        : assets.colors.LightBgOpacity8,
                    marginBottom: "8px",
                    borderRadius: "10px",
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid " + assets.colors.borderDark
                        : "1px solid " + assets.colors.borderLight,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // borderBottom:
                      //   theme.palette.mode === "dark"
                      //     ? "1px solid " + assets.colors.borderDark
                      //     : "1px solid " + assets.colors.borderLight,
                      padding: "10px 10px",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        display: "flex",
                        width: "400px",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <Typography>
                            {item && item.mood && item.mood.icon}
                          </Typography>
                        }
                        sx={{
                          WebkitUserSelect: "none",
                          MsUserSelect: "none",
                          userSelect: "none",
                          "& .MuiAvatar-root": {
                            border:
                              item.stAcc === "login"
                                ? "3px solid green !important"
                                : "3px solid #121212 !important",
                          },
                        }}
                      >
                        <Avatar
                          alt={item.name}
                          src={item.image}
                          sx={{
                            "&:hover": {
                              opacity: 0.9,
                              backgroundColor: "#000",
                            },
                          }}
                        />
                      </Badge>
                      <Typography
                        sx={{
                          flex: "1 1 100%",
                          fontSize: "14px",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                        }}
                        color="inherit"
                        variant="h6"
                        component="div"
                      >
                        {item.name}
                        <br />
                        <span style={{ fontWeight: "400", fontSize: "12px" }}>
                          {item && item.position && item.position.name}
                        </span>
                      </Typography>
                    </Stack>
                    {/* {isPDFFile && isPDFFile[item.id] && <PDFFile />}
                    <Tooltip title="Report">
                      <Button
                        variant="contained"
                        sx={{ mr: 2, padding: "8px 10px", minWidth: "unset" }}
                        color="error"
                        disableElevation={true}
                        onClick={(e) => {
                          handlePDFFile(item.id);
                        }}
                      >
                        <DescriptionIcon />
                      </Button>
                    </Tooltip> */}
                    <Box
                      sx={{
                        width: "100%",
                        mr: 1,
                        ml: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                        }}
                      >
                        0%
                      </span>
                      <CheckMarkSlider
                        sx={{ margin: "0 15px" }}
                        value={item.progNum}
                        icon={item.progIcon}
                        disabled
                        components={{ Thumb: CheckMarkThumbComponent }}
                      />
                      <span
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                        }}
                      >
                        100%
                      </span>
                    </Box>

                    <Box
                      sx={{
                        width: "150px",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        color:
                          theme.palette.mode === "dark"
                            ? assets.colors.taskTextDark
                            : assets.colors.taskTextLight,
                        fontWeight: 700,
                      }}
                    >
                      {/* <EmojiEventsIcon sx={{ marginRight: "3px" }} /> */}
                      {item.reward !== undefined && item.reward !== null && (
                        <>
                          {item.reward !== undefined &&
                            item.reward !== null &&
                            item.reward.icon}{" "}
                          {item.reward !== undefined &&
                            item.reward !== null &&
                            item.reward.name}
                        </>
                      )}
                    </Box>

                    <CardActions
                      sx={{
                        margin: "0px",
                        padding: "0px",
                        width: "130px",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          sx={{
                            margin: "0px",
                            padding: "0px",
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCollapseUser(item.id);
                          }}
                        >
                          {isOpenCollapseUser[item.id] === true ? (
                            <>
                              <span
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                Hide
                              </span>{" "}
                              <ArrowDropUp />
                            </>
                          ) : (
                            <>
                              <span
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                Show
                              </span>{" "}
                              <ArrowDropDown />
                            </>
                          )}
                        </IconButton>
                      </Grid>
                    </CardActions>
                  </Box>
                  {isOpenCollapseUser[item.id] && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 10px 10px 10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "calc(100vw - 10vh)",
                          overflowX: "auto",
                        }}
                      >
                        {data.map((section, idx) => (
                          <div
                            key={`${section.id}-${item.id}`}
                            style={{
                              margin: "0 3px 3px 3px",
                              width: "235px",
                              borderRadius: "5px",
                              backgroundColor:
                                section.color === "blue"
                                  ? blue[100]
                                  : section.color === "orange"
                                  ? orange[100]
                                  : section.color === "green"
                                  ? green[100]
                                  : section.color === "purple"
                                  ? purple[100]
                                  : section.color === "pink"
                                  ? pink[100]
                                  : section.color === "yellow"
                                  ? yellow[100]
                                  : red[100],
                            }}
                          >
                            <Droppable
                              key={`${section.id}-${item.id}`}
                              droppableId={`${section.id}-${item.id}`}
                              isDropDisabled={isDropDisabled}
                            >
                              {(provided) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  sx={{
                                    width: "235px",
                                    padding: "0 5px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      margin: "5px 0px",
                                    }}
                                  >
                                    <TextField
                                      value={section.title}
                                      onChange={(e) =>
                                        updateSectionTitle(e, section.id)
                                      }
                                      placeholder="Untitled"
                                      variant="outlined"
                                      sx={{
                                        flexGrow: 1,
                                        "& .MuiOutlinedInput-input": {
                                          padding: 0,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: "unset ",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          fontSize: "1rem",
                                          fontWeight: "700",
                                          color:
                                            theme.palette.mode === "dark"
                                              ? "#FFF"
                                              : "#FFF",
                                        },
                                        marginRight: "20px",
                                        borderRadius: "5px",
                                        padding: "0px 5px 0 5px !important",

                                        backgroundColor:
                                          section.color === "blue"
                                            ? blue[900]
                                            : section.color === "orange"
                                            ? orange[900]
                                            : section.color === "green"
                                            ? green[900]
                                            : section.color === "purple"
                                            ? purple[900]
                                            : section.color === "pink"
                                            ? pink[900]
                                            : section.color === "yellow"
                                            ? yellow[900]
                                            : red[900],
                                      }}
                                    />
                                    <Badge
                                      badgeContent={
                                        item &&
                                        item.sections &&
                                        item.sections[idx] &&
                                        item.sections[idx].tasks &&
                                        item.sections[idx].tasks.length
                                      }
                                      sx={{
                                        marginRight: "10px",
                                        color:
                                          section.color === "blue"
                                            ? blue[900]
                                            : section.color === "orange"
                                            ? orange[900]
                                            : section.color === "green"
                                            ? green[900]
                                            : section.color === "purple"
                                            ? purple[900]
                                            : section.color === "pink"
                                            ? pink[900]
                                            : section.color === "yellow"
                                            ? yellow[900]
                                            : red[900],
                                      }}
                                    ></Badge>
                                    {dayjs(boardDueDate).format("YYYY-MM-DD") >=
                                      dayjs(Moment()).format("YYYY-MM-DD") && (
                                      <>
                                        <IconButton
                                          variant="outlined"
                                          size="small"
                                          sx={{
                                            padding: "0px",
                                            color: "#333",
                                            "&:hover": { color: "green" },
                                          }}
                                          onClick={() =>
                                            showDialogCreateTask(
                                              section.id,
                                              item.id
                                            )
                                          }
                                        >
                                          <AddOutlinedIcon
                                            sx={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-controls={
                                            open ? "long-menu" : undefined
                                          }
                                          aria-expanded={
                                            open ? "true" : undefined
                                          }
                                          aria-haspopup="true"
                                          sx={{
                                            padding: "0px",
                                            color: "#333",
                                            "&:hover": { color: "green" },
                                          }}
                                          onClick={(e) =>
                                            handleClickAction(idx, e)
                                          }
                                        >
                                          <MoreVertIcon
                                            sx={{
                                              fontSize: "20px",
                                            }}
                                          />
                                        </IconButton>
                                        <Menu
                                          id="long-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "long-button",
                                          }}
                                          anchorEl={
                                            anchorElAction &&
                                            anchorElAction[idx]
                                          }
                                          open={Boolean(
                                            anchorElAction &&
                                              anchorElAction[idx]
                                          )}
                                          onClose={handleCloseAction}
                                          PaperProps={{
                                            style: {
                                              maxHeight: "160px",
                                              width: "20ch",
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            key={`${section.id}-${item.id}-edit`}
                                            onClick={() =>
                                              setSelectedSection(section)
                                            }
                                          >
                                            Edit Section
                                          </MenuItem>
                                          {/* {section.isDone !== 1 && (
                                            <MenuItem
                                              key={`${section.id}-${item.id}-delete`}
                                              onClick={() =>
                                                showDialogDeleteSection(
                                                  section.id
                                                )
                                              }
                                            >
                                              Delete Section
                                            </MenuItem>
                                          )} */}
                                        </Menu>
                                      </>
                                    )}
                                  </Box>
                                  {/* tasks */}
                                  {item &&
                                    item.sections &&
                                    item.sections[idx] &&
                                    item.sections[idx].tasks.map(
                                      (task, index) => (
                                        <Draggable
                                          key={task.id}
                                          draggableId={`${task.id}-${item.id}`}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <Card
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              sx={{
                                                padding: "7px",
                                                marginBottom: "5px",
                                                cursor: snapshot.isDragging
                                                  ? "grab"
                                                  : "pointer!important",
                                                "&:hover": {
                                                  border:
                                                    theme.palette.mode ===
                                                    "dark"
                                                      ? "3px solid " +
                                                        assets.colors
                                                          .taskHoverBorderDark
                                                      : "3px solid " +
                                                        assets.colors
                                                          .taskHoverBorderLight,
                                                  padding: "4.6px",
                                                },
                                              }}
                                              // onMouseOver={() =>
                                              //   handleCollapse(task.id)
                                              // }
                                              // onMouseOut={handleMouseOut}
                                              // onClick={() =>
                                              //   setSelectedTaskView(task)
                                              // }
                                              onClick={(e) => {
                                                handleCollapse(task.id);
                                              }}
                                            >
                                              <Droppable
                                                key={
                                                  task !== undefined
                                                    ? `${task.id}-${item.id}`
                                                    : ""
                                                }
                                                droppableId={
                                                  task !== undefined
                                                    ? `${task.id}-${item.id}-${
                                                        section.id
                                                      }|||${task.icons.reduce(
                                                        (acc, curr) =>
                                                          `${acc}${curr.icon}|${curr.iconImage}|${curr.user}|${curr.userImage}||`,
                                                        ""
                                                      )}`
                                                    : ""
                                                }
                                                isDropDisabled={
                                                  isDropDisabledCard
                                                }
                                              >
                                                {(provided) => (
                                                  <Box
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                  >
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        // alignItems: "center",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          // alignItems: "center",
                                                          justifyContent:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            marginRight: "5px",
                                                            padding: "3px",
                                                            borderRadius: "3px",
                                                            marginTop: "3px",
                                                            fontSize: "9px",
                                                            fontWeight: "bold",
                                                            width: "5px",
                                                            backgroundColor:
                                                              task.priority ===
                                                              "low"
                                                                ? yellow[400]
                                                                : task.priority ===
                                                                  "medium"
                                                                ? orange[400]
                                                                : orange[900],
                                                            height: "14px",
                                                          }}
                                                        ></span>
                                                        <Typography
                                                          sx={{
                                                            fontWeight: "bold",
                                                            lineHeight:
                                                              "1.3rem",
                                                          }}
                                                        >
                                                          {task.title === ""
                                                            ? "Untitled"
                                                            : task.title}
                                                        </Typography>
                                                      </Box>
                                                      {dayjs(
                                                        boardDueDate
                                                      ).format("YYYY-MM-DD") >=
                                                        dayjs(Moment()).format(
                                                          "YYYY-MM-DD"
                                                        ) && (
                                                        <Tooltip title="Edit">
                                                          <IconButton
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setSelectedTask(
                                                                task
                                                              );
                                                            }}
                                                            sx={{
                                                              marginTop: "3px",
                                                              padding: "2px",
                                                              height: "14px",
                                                            }}
                                                          >
                                                            <ModeEditOutlineOutlinedIcon
                                                              sx={{
                                                                width: "16px",
                                                                height: "16px",
                                                              }}
                                                            />
                                                          </IconButton>
                                                        </Tooltip>
                                                      )}
                                                    </Box>
                                                    <Divider
                                                      sx={{ margin: "5px 0" }}
                                                    />
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "space-between",
                                                        // marginBottom: "5px",
                                                      }}
                                                    >
                                                      <Typography
                                                        sx={{
                                                          fontSize: "11px",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            // width: "15px",
                                                            // height: "15px",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            padding:
                                                              "1px 3px 1px 3px",
                                                            marginRight: "5px",
                                                            backgroundColor:
                                                              section.isDone ===
                                                              1
                                                                ? green[600]
                                                                : Moment(
                                                                    task.dueDate
                                                                  ).unix() <
                                                                  Moment().unix()
                                                                ? red[600]
                                                                : Moment(
                                                                    task.dueDate
                                                                  ).unix() -
                                                                    Moment().unix() <
                                                                  90000
                                                                ? orange[600]
                                                                : Moment(
                                                                    task.dueDate
                                                                  ).unix() -
                                                                    Moment().unix() <
                                                                  604800
                                                                ? yellow[900]
                                                                : "unset",
                                                            color:
                                                              section.isDone ===
                                                              1
                                                                ? green[100]
                                                                : Moment(
                                                                    task.dueDate
                                                                  ).unix() <
                                                                  Moment().unix()
                                                                ? red[100]
                                                                : Moment(
                                                                    task.dueDate
                                                                  ).unix() -
                                                                    Moment().unix() <
                                                                  90000
                                                                ? orange[100]
                                                                : Moment(
                                                                    task.dueDate
                                                                  ).unix() -
                                                                    Moment().unix() <
                                                                  604800
                                                                ? yellow[100]
                                                                : theme.palette
                                                                    .mode ===
                                                                  "dark"
                                                                ? grey[100]
                                                                : grey[800],
                                                            borderRadius: "3px",
                                                          }}
                                                        >
                                                          <AccessTimeIcon
                                                            sx={{
                                                              width: "16px",
                                                              height: "16px",
                                                              marginRight:
                                                                "2px",
                                                            }}
                                                          />
                                                          {Moment(task.dueDate)
                                                            .locale("id")
                                                            .format("DD MMM")}
                                                        </Box>
                                                      </Typography>
                                                      {task &&
                                                        task.users &&
                                                        task.users.length >
                                                          1 && (
                                                          <AvatarGroup
                                                            max={3}
                                                            sx={{
                                                              mr: 1,
                                                              "& .MuiAvatar-root":
                                                                {
                                                                  width: 20,
                                                                  height: 20,
                                                                  fontSize: 10,
                                                                },
                                                            }}
                                                          >
                                                            {task.users.map(
                                                              (user, index) => {
                                                                return (
                                                                  <Avatar
                                                                    key={`${section.id}-${item.id}-${task.id}-${index}-avatars`}
                                                                    alt={
                                                                      user.name
                                                                    }
                                                                    src={
                                                                      user.image
                                                                    }
                                                                    sx={{
                                                                      width: 20,
                                                                      height: 20,
                                                                    }}
                                                                  />
                                                                );
                                                              }
                                                            )}
                                                          </AvatarGroup>
                                                        )}
                                                      {task &&
                                                      task.taskChecklists &&
                                                      task.taskChecklists
                                                        .length !== 0 ? (
                                                        <Typography
                                                          sx={{
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          ({task.totalCheckDone}{" "}
                                                          / {task.totalCheck}){" "}
                                                          {(
                                                            (task.totalCheckDone /
                                                              task.totalCheck) *
                                                            100
                                                          ).toFixed(1)}
                                                          %
                                                        </Typography>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Box>

                                                    {task &&
                                                    task.taskChecklists &&
                                                    task.taskChecklists
                                                      .length !== 0 ? (
                                                      <BorderLinearProgress
                                                        variant="determinate"
                                                        value={
                                                          (task.totalCheckDone /
                                                            task.totalCheck) *
                                                          100
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}

                                                    {/* <CardActions
                                                      sx={{
                                                        margin: "0px",
                                                        padding: "0px",
                                                      }}
                                                    >
                                                      <Grid
                                                        container
                                                        justify="center"
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <IconButton
                                                          sx={{
                                                            margin: "0px",
                                                            padding: "0px",
                                                            borderRadius: "0px",
                                                            width: "100%",
                                                          }}
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCollapse(
                                                              task.id
                                                            );
                                                          }}
                                                        >
                                                          <ArrowDropDown
                                                            sx={{
                                                              width: "16px",
                                                              height: "16px",
                                                            }}
                                                          />
                                                        </IconButton>
                                                      </Grid>
                                                    </CardActions> */}
                                                    <Collapse
                                                      in={
                                                        isOpenCollapse ===
                                                        task.id
                                                      }
                                                    >
                                                      <Box
                                                        sx={{
                                                          margin: "3px 0px",
                                                          padding: "0px",
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              task.content,
                                                          }}
                                                          className="editor ck-content"
                                                        ></div>
                                                      </Box>
                                                      <Box
                                                        sx={{
                                                          margin:
                                                            "0 0.2rem 0 0",
                                                        }}
                                                      >
                                                        {task.tags.map(
                                                          (data, index) => {
                                                            return (
                                                              <Chip
                                                                sx={{
                                                                  "& .MuiChip-label":
                                                                    {
                                                                      padding:
                                                                        "3px 6px",
                                                                      margin:
                                                                        "0px",
                                                                    },
                                                                  margin:
                                                                    "0px 2px 2px 0px",
                                                                  fontSize:
                                                                    "10px",
                                                                  height:
                                                                    "unset",
                                                                }}
                                                                key={`${section.id}-${item.id}-${task.id}-${index}-tags`}
                                                                label={
                                                                  data.name
                                                                }
                                                              />
                                                            );
                                                          }
                                                        )}
                                                      </Box>
                                                      {task.icons.map(
                                                        (data, index) => {
                                                          return (
                                                            <Badge
                                                              key={`${section.id}-${item.id}-${data.name}-${data.icon}-${task.id}-${index}-icons`}
                                                              overlap="circular"
                                                              anchorOrigin={{
                                                                vertical:
                                                                  "bottom",
                                                                horizontal:
                                                                  "right",
                                                              }}
                                                              badgeContent={
                                                                <Avatar
                                                                  alt={
                                                                    data.user
                                                                  }
                                                                  src={
                                                                    data.userImage
                                                                  }
                                                                  sx={{
                                                                    width: 22,
                                                                    height: 22,
                                                                    border: `2px solid ${theme.palette.background.paper}`,
                                                                  }}
                                                                />
                                                              }
                                                              sx={{
                                                                WebkitUserSelect:
                                                                  "none",
                                                                MsUserSelect:
                                                                  "none",
                                                                userSelect:
                                                                  "none",
                                                              }}
                                                            >
                                                              <Avatar
                                                                alt={data.icon}
                                                                src={
                                                                  data.iconImage
                                                                }
                                                                sx={{
                                                                  borderRadius: 0,
                                                                  marginRight:
                                                                    "5px",
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                              />
                                                            </Badge>
                                                            // <img
                                                            //   key={`${section.id}-${item.id}-${task.id}-${index}-icons`}
                                                            //   src={data}
                                                            //   alt={index}
                                                            //   height="50px"
                                                            //   width="50px"
                                                            //   style={{
                                                            //     marginRight:
                                                            //       "2px",
                                                            //     marginTop:
                                                            //       "5px",
                                                            //   }}
                                                            // />
                                                          );
                                                        }
                                                      )}

                                                      <Typography
                                                        sx={{
                                                          fontSize: "12px",
                                                          padding: "3px 0",
                                                        }}
                                                      >
                                                        {task.startDate.split(
                                                          "-"
                                                        )[0] !==
                                                        task.dueDate.split(
                                                          "-"
                                                        )[0] ? (
                                                          <>{`Date : ${Moment(
                                                            task.startDate
                                                          )
                                                            .locale("id")
                                                            .format(
                                                              "DD MMM YYYY"
                                                            )} - ${Moment(
                                                            task.dueDate
                                                          ).format(
                                                            "DD MMM YYYY"
                                                          )}`}</>
                                                        ) : task.startDate.split(
                                                            "-"
                                                          )[1] !==
                                                          task.dueDate.split(
                                                            "-"
                                                          )[1] ? (
                                                          <>
                                                            {`Date : ${Moment(
                                                              task.startDate
                                                            )
                                                              .locale("id")
                                                              .format(
                                                                "DD MMM"
                                                              )} - ${Moment(
                                                              task.dueDate
                                                            ).format(
                                                              "DD MMM"
                                                            )}`}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {`Date : ${Moment(
                                                              task.startDate
                                                            )
                                                              .locale("id")
                                                              .format(
                                                                "DD"
                                                              )} - ${Moment(
                                                              task.dueDate
                                                            ).format(
                                                              "DD MMM"
                                                            )}`}
                                                          </>
                                                        )}
                                                      </Typography>
                                                    </Collapse>
                                                  </Box>
                                                )}
                                              </Droppable>
                                            </Card>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </div>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
          </DragDropContext>
        </>
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            {users &&
              users.length !== 0 &&
              users.map((item, index) => (
                <Box
                  key={item.id}
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.DarkBgOpacity8
                        : assets.colors.LightBgOpacity8,
                    marginBottom: "8px",
                    borderRadius: "10px",
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid " + assets.colors.borderDark
                        : "1px solid " + assets.colors.borderLight,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // borderBottom:
                      //   theme.palette.mode === "dark"
                      //     ? "1px solid " + assets.colors.borderDark
                      //     : "1px solid " + assets.colors.borderLight,
                      padding: "10px 10px",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        display: "flex",
                        width: "350px",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <Typography>
                            {item && item.mood && item.mood.icon}
                          </Typography>
                        }
                        sx={{
                          WebkitUserSelect: "none",
                          MsUserSelect: "none",
                          userSelect: "none",
                          "& .MuiAvatar-root": {
                            border:
                              item.stAcc === "login"
                                ? "3px solid green !important"
                                : "3px solid #121212 !important",
                          },
                        }}
                      >
                        <Avatar
                          alt={item.name}
                          src={item.image}
                          sx={{
                            "&:hover": {
                              opacity: 0.9,
                              backgroundColor: "#000",
                            },
                          }}
                        />
                      </Badge>
                      <Typography
                        sx={{
                          flex: "1 1 100%",
                          fontSize: "14px",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                        }}
                        color="inherit"
                        variant="h6"
                        component="div"
                      >
                        {item.name}
                        <br />
                        <span style={{ fontWeight: "400", fontSize: "12px" }}>
                          {item && item.position && item.position.name}
                        </span>
                      </Typography>
                    </Stack>
                    {/* {isPDFFile && isPDFFile[item.id] && <PDFFile />}
                    <Tooltip title="Report">
                      <Button
                        variant="contained"
                        sx={{ mr: 2, padding: "8px 10px", minWidth: "unset" }}
                        color="error"
                        disableElevation={true}
                        onClick={(e) => {
                          handlePDFFile(item.id);
                        }}
                      >
                        <DescriptionIcon />
                      </Button>
                    </Tooltip> */}
                    <Box
                      sx={{
                        width: "100%",
                        mr: 1,
                        ml: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                        }}
                      >
                        0%
                      </span>
                      <CheckMarkSlider
                        sx={{ margin: "0 15px" }}
                        value={item.progNum}
                        icon={item.progIcon}
                        disabled
                        components={{ Thumb: CheckMarkThumbComponent }}
                      />
                      <span
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                        }}
                      >
                        100%
                      </span>
                    </Box>
                    {item.reward !== undefined && item.reward !== null && (
                      <Box
                        sx={{
                          width: "150px",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.taskTextDark
                              : assets.colors.taskTextLight,
                          fontWeight: 700,
                        }}
                      >
                        <EmojiEventsIcon sx={{ marginRight: "3px" }} />
                        {item.reward !== undefined &&
                          item.reward !== null &&
                          item.reward.name}
                      </Box>
                    )}
                    <CardActions
                      sx={{
                        margin: "0px",
                        padding: "0px",
                        width: "130px",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          sx={{
                            margin: "0px",
                            padding: "0px",
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCollapseUser(item.id);
                          }}
                        >
                          {isOpenCollapseUser[item.id] === true ? (
                            <>
                              <span
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                Hide
                              </span>{" "}
                              <ArrowDropUp />
                            </>
                          ) : (
                            <>
                              <span
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                Show
                              </span>{" "}
                              <ArrowDropDown />
                            </>
                          )}
                        </IconButton>
                      </Grid>
                    </CardActions>
                  </Box>
                  {isOpenCollapseUser[item.id] && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 10px 10px 10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "calc(100vw - 10vh)",
                          overflowX: "auto",
                        }}
                      >
                        {data.map((section, idx) => (
                          <div
                            key={`${section.id}-${item.id}`}
                            style={{
                              margin: "0 3px 3px 3px",
                              width: "235px",
                              borderRadius: "5px",
                              backgroundColor:
                                section.color === "blue"
                                  ? blue[100]
                                  : section.color === "orange"
                                  ? orange[100]
                                  : section.color === "green"
                                  ? green[100]
                                  : section.color === "purple"
                                  ? purple[100]
                                  : section.color === "pink"
                                  ? pink[100]
                                  : section.color === "yellow"
                                  ? yellow[100]
                                  : red[100],
                            }}
                          >
                            {user.id === item.id ? (
                              <Droppable
                                key={`${section.id}`}
                                droppableId={`${section.id}`}
                              >
                                {(provided) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={{
                                      width: "235px",
                                      padding: "0 5px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        margin: "5px 0px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          flexGrow: 1,
                                          padding: 0,
                                          border: "unset ",
                                          fontSize: "1rem",
                                          fontWeight: "700",
                                          color:
                                            theme.palette.mode === "dark"
                                              ? "#FFF"
                                              : "#FFF",
                                          marginRight: "20px",
                                          borderRadius: "5px",
                                          padding: "0px 5px 0 5px !important",
                                          backgroundColor:
                                            section.color === "blue"
                                              ? blue[900]
                                              : section.color === "orange"
                                              ? orange[900]
                                              : section.color === "green"
                                              ? green[900]
                                              : section.color === "purple"
                                              ? purple[900]
                                              : section.color === "pink"
                                              ? pink[900]
                                              : section.color === "yellow"
                                              ? yellow[900]
                                              : red[900],
                                        }}
                                      >
                                        {section.title}
                                      </Typography>
                                      <Badge
                                        badgeContent={
                                          section &&
                                          section.tasks &&
                                          section.tasks.length
                                        }
                                        sx={{
                                          marginRight: "10px",
                                          color:
                                            section.color === "blue"
                                              ? blue[900]
                                              : section.color === "orange"
                                              ? orange[900]
                                              : section.color === "green"
                                              ? green[900]
                                              : section.color === "purple"
                                              ? purple[900]
                                              : section.color === "pink"
                                              ? pink[900]
                                              : section.color === "yellow"
                                              ? yellow[900]
                                              : red[900],
                                        }}
                                      ></Badge>
                                      {dayjs(boardDueDate).format(
                                        "YYYY-MM-DD"
                                      ) >=
                                        dayjs(Moment()).format(
                                          "YYYY-MM-DD"
                                        ) && (
                                        <>
                                          <IconButton
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                              padding: "0px",
                                              color: "#333",
                                              "&:hover": { color: "green" },
                                            }}
                                            onClick={() =>
                                              showDialogCreateTask(
                                                section.id,
                                                item.id
                                              )
                                            }
                                          >
                                            <AddOutlinedIcon
                                              sx={{
                                                fontSize: "20px",
                                              }}
                                            />
                                          </IconButton>
                                        </>
                                      )}
                                    </Box>
                                    {/* tasks */}
                                    {item &&
                                      item.sections &&
                                      item.sections[idx] &&
                                      item.sections[idx].tasks.map(
                                        (task, index) => (
                                          <Draggable
                                            key={task.id}
                                            draggableId={task.id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <Card
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                  padding: "7px",
                                                  marginBottom: "5px",
                                                  cursor: snapshot.isDragging
                                                    ? "grab"
                                                    : "pointer!important",
                                                  "&:hover": {
                                                    border:
                                                      theme.palette.mode ===
                                                      "dark"
                                                        ? "3px solid " +
                                                          assets.colors
                                                            .taskHoverBorderDark
                                                        : "3px solid " +
                                                          assets.colors
                                                            .taskHoverBorderLight,
                                                    padding: "4.6px",
                                                  },
                                                }}
                                                onClick={(e) => {
                                                  handleCollapse(task.id);
                                                }}
                                              >
                                                <Droppable
                                                  key={
                                                    task !== undefined
                                                      ? `${task.id}-${item.id}`
                                                      : ""
                                                  }
                                                  droppableId={
                                                    task !== undefined
                                                      ? `${task.id}-${item.id}-${section.id}|${task.icons}`
                                                      : ""
                                                  }
                                                  isDropDisabled={
                                                    isDropDisabledCard
                                                  }
                                                >
                                                  {(provided) => (
                                                    <Box
                                                      ref={provided.innerRef}
                                                      {...provided.droppableProps}
                                                    >
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          // alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            // alignItems: "center",
                                                            justifyContent:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              marginRight:
                                                                "5px",
                                                              padding: "3px",
                                                              borderRadius:
                                                                "3px",
                                                              marginTop: "3px",
                                                              fontSize: "9px",
                                                              fontWeight:
                                                                "bold",
                                                              width: "5px",
                                                              backgroundColor:
                                                                task.priority ===
                                                                "low"
                                                                  ? yellow[400]
                                                                  : task.priority ===
                                                                    "medium"
                                                                  ? orange[400]
                                                                  : orange[900],
                                                              height: "14px",
                                                            }}
                                                          ></span>
                                                          <Typography
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                              lineHeight:
                                                                "1.3rem",
                                                            }}
                                                          >
                                                            {task.title === ""
                                                              ? "Untitled"
                                                              : task.title}
                                                          </Typography>
                                                        </Box>
                                                        {dayjs(
                                                          boardDueDate
                                                        ).format(
                                                          "YYYY-MM-DD"
                                                        ) >=
                                                          dayjs(
                                                            Moment()
                                                          ).format(
                                                            "YYYY-MM-DD"
                                                          ) && (
                                                          <Tooltip title="Edit">
                                                            <IconButton
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedTask(
                                                                  task
                                                                );
                                                              }}
                                                              sx={{
                                                                marginTop:
                                                                  "3px",
                                                                padding: "2px",
                                                                height: "14px",
                                                              }}
                                                            >
                                                              <ModeEditOutlineOutlinedIcon
                                                                sx={{
                                                                  width: "16px",
                                                                  height:
                                                                    "16px",
                                                                }}
                                                              />
                                                            </IconButton>
                                                          </Tooltip>
                                                        )}
                                                      </Box>
                                                      <Divider
                                                        sx={{ margin: "5px 0" }}
                                                      />
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{
                                                            fontSize: "11px",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "space-between",
                                                          }}
                                                        >
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              padding:
                                                                "1px 3px 1px 3px",
                                                              marginRight:
                                                                "5px",
                                                              backgroundColor:
                                                                section.isDone ===
                                                                1
                                                                  ? green[600]
                                                                  : Moment(
                                                                      task.dueDate
                                                                    ).unix() <
                                                                    Moment().unix()
                                                                  ? red[600]
                                                                  : Moment(
                                                                      task.dueDate
                                                                    ).unix() -
                                                                      Moment().unix() <
                                                                    90000
                                                                  ? orange[600]
                                                                  : Moment(
                                                                      task.dueDate
                                                                    ).unix() -
                                                                      Moment().unix() <
                                                                    604800
                                                                  ? yellow[900]
                                                                  : "unset",
                                                              color:
                                                                section.isDone ===
                                                                1
                                                                  ? green[100]
                                                                  : Moment(
                                                                      task.dueDate
                                                                    ).unix() <
                                                                    Moment().unix()
                                                                  ? red[100]
                                                                  : Moment(
                                                                      task.dueDate
                                                                    ).unix() -
                                                                      Moment().unix() <
                                                                    90000
                                                                  ? orange[100]
                                                                  : Moment(
                                                                      task.dueDate
                                                                    ).unix() -
                                                                      Moment().unix() <
                                                                    604800
                                                                  ? yellow[100]
                                                                  : theme
                                                                      .palette
                                                                      .mode ===
                                                                    "dark"
                                                                  ? grey[100]
                                                                  : grey[800],
                                                              borderRadius:
                                                                "3px",
                                                            }}
                                                          >
                                                            <AccessTimeIcon
                                                              sx={{
                                                                width: "16px",
                                                                height: "16px",
                                                                marginRight:
                                                                  "2px",
                                                              }}
                                                            />
                                                            {Moment(
                                                              task.dueDate
                                                            )
                                                              .locale("id")
                                                              .format("DD MMM")}
                                                          </Box>
                                                        </Typography>
                                                        {task &&
                                                          task.users &&
                                                          task.users.length >
                                                            1 && (
                                                            <AvatarGroup
                                                              max={3}
                                                              sx={{
                                                                mr: 1,
                                                                "& .MuiAvatar-root":
                                                                  {
                                                                    width: 20,
                                                                    height: 20,
                                                                    fontSize: 10,
                                                                  },
                                                              }}
                                                            >
                                                              {task.users.map(
                                                                (
                                                                  user,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <Avatar
                                                                      key={`${section.id}-${item.id}-${task.id}-${index}-avatars`}
                                                                      alt={
                                                                        user.name
                                                                      }
                                                                      src={
                                                                        user.image
                                                                      }
                                                                      sx={{
                                                                        width: 20,
                                                                        height: 20,
                                                                      }}
                                                                    />
                                                                  );
                                                                }
                                                              )}
                                                            </AvatarGroup>
                                                          )}
                                                        {task &&
                                                        task.taskChecklists &&
                                                        task.taskChecklists
                                                          .length !== 0 ? (
                                                          <Typography
                                                            sx={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            (
                                                            {
                                                              task.totalCheckDone
                                                            }{" "}
                                                            / {task.totalCheck}){" "}
                                                            {(
                                                              (task.totalCheckDone /
                                                                task.totalCheck) *
                                                              100
                                                            ).toFixed(1)}
                                                            %
                                                          </Typography>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </Box>

                                                      {task &&
                                                      task.taskChecklists &&
                                                      task.taskChecklists
                                                        .length !== 0 ? (
                                                        <BorderLinearProgress
                                                          variant="determinate"
                                                          value={
                                                            (task.totalCheckDone /
                                                              task.totalCheck) *
                                                            100
                                                          }
                                                        />
                                                      ) : (
                                                        ""
                                                      )}

                                                      {/* <CardActions
                                                  sx={{
                                                    margin: "0px",
                                                    padding: "0px",
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    justify="center"
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "center",
                                                    }}
                                                  >
                                                    <IconButton
                                                      sx={{
                                                        margin: "0px",
                                                        padding: "0px",
                                                        borderRadius: "0px",
                                                        width: "100%",
                                                      }}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleCollapse(
                                                          task.id
                                                        );
                                                      }}
                                                    >
                                                      <ArrowDropDown
                                                        sx={{
                                                          width: "16px",
                                                          height: "16px",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Grid>
                                                </CardActions> */}
                                                      <Collapse
                                                        in={
                                                          isOpenCollapse ===
                                                          task.id
                                                        }
                                                      >
                                                        <Box
                                                          sx={{
                                                            margin: "3px 0px",
                                                            padding: "0px",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                task.content,
                                                            }}
                                                            className="editor ck-content"
                                                          ></div>
                                                        </Box>
                                                        <Box
                                                          sx={{
                                                            margin:
                                                              "0 0.2rem 0 0",
                                                          }}
                                                        >
                                                          {task.tags.map(
                                                            (data, index) => {
                                                              return (
                                                                <Chip
                                                                  sx={{
                                                                    "& .MuiChip-label":
                                                                      {
                                                                        padding:
                                                                          "3px 6px",
                                                                        margin:
                                                                          "0px",
                                                                      },
                                                                    margin:
                                                                      "0px 2px 2px 0px",
                                                                    fontSize:
                                                                      "10px",
                                                                    height:
                                                                      "unset",
                                                                  }}
                                                                  key={`${section.id}-${item.id}-${task.id}-${index}-tags`}
                                                                  label={
                                                                    data.name
                                                                  }
                                                                />
                                                              );
                                                            }
                                                          )}
                                                        </Box>
                                                        {task.icons.map(
                                                          (data, index) => {
                                                            return (
                                                              <Badge
                                                                key={`${section.id}-${item.id}-${data.name}-${data.icon}-${task.id}-${index}-icons`}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                  vertical:
                                                                    "bottom",
                                                                  horizontal:
                                                                    "right",
                                                                }}
                                                                badgeContent={
                                                                  <Avatar
                                                                    alt={
                                                                      data.user
                                                                    }
                                                                    src={
                                                                      data.userImage
                                                                    }
                                                                    sx={{
                                                                      width: 22,
                                                                      height: 22,
                                                                      border: `2px solid ${theme.palette.background.paper}`,
                                                                    }}
                                                                  />
                                                                }
                                                                sx={{
                                                                  WebkitUserSelect:
                                                                    "none",
                                                                  MsUserSelect:
                                                                    "none",
                                                                  userSelect:
                                                                    "none",
                                                                }}
                                                              >
                                                                <Avatar
                                                                  alt={
                                                                    data.icon
                                                                  }
                                                                  src={
                                                                    data.iconImage
                                                                  }
                                                                  sx={{
                                                                    borderRadius: 0,
                                                                    marginRight:
                                                                      "5px",
                                                                    marginBottom:
                                                                      "5px",
                                                                  }}
                                                                />
                                                              </Badge>
                                                              // <img
                                                              //   key={`${section.id}-${item.id}-${task.id}-${index}-icons`}
                                                              //   src={data}
                                                              //   alt={index}
                                                              //   height="50px"
                                                              //   width="50px"
                                                              //   style={{
                                                              //     marginRight:
                                                              //       "2px",
                                                              //     marginTop:
                                                              //       "5px",
                                                              //   }}
                                                              // />
                                                            );
                                                          }
                                                        )}

                                                        <Typography
                                                          sx={{
                                                            fontSize: "12px",
                                                            padding: "3px 0",
                                                          }}
                                                        >
                                                          {task.startDate.split(
                                                            "-"
                                                          )[0] !==
                                                          task.dueDate.split(
                                                            "-"
                                                          )[0] ? (
                                                            <>{`Date : ${Moment(
                                                              task.startDate
                                                            )
                                                              .locale("id")
                                                              .format(
                                                                "DD MMM YYYY"
                                                              )} - ${Moment(
                                                              task.dueDate
                                                            ).format(
                                                              "DD MMM YYYY"
                                                            )}`}</>
                                                          ) : task.startDate.split(
                                                              "-"
                                                            )[1] !==
                                                            task.dueDate.split(
                                                              "-"
                                                            )[1] ? (
                                                            <>
                                                              {`Date : ${Moment(
                                                                task.startDate
                                                              )
                                                                .locale("id")
                                                                .format(
                                                                  "DD MMM"
                                                                )} - ${Moment(
                                                                task.dueDate
                                                              ).format(
                                                                "DD MMM"
                                                              )}`}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {`Date : ${Moment(
                                                                task.startDate
                                                              )
                                                                .locale("id")
                                                                .format(
                                                                  "DD"
                                                                )} - ${Moment(
                                                                task.dueDate
                                                              ).format(
                                                                "DD MMM"
                                                              )}`}
                                                            </>
                                                          )}
                                                        </Typography>
                                                      </Collapse>
                                                    </Box>
                                                  )}
                                                </Droppable>
                                              </Card>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                    {provided.placeholder}
                                  </Box>
                                )}
                              </Droppable>
                            ) : (
                              <Box
                                key={`${section.id}-${item.id}`}
                                sx={{
                                  width: "235px",
                                  padding: "0 5px",
                                  marginRight: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      flexGrow: 1,
                                      padding: 0,
                                      border: "unset ",
                                      fontSize: "1rem",
                                      fontWeight: "700",
                                      color:
                                        theme.palette.mode === "dark"
                                          ? "#FFF"
                                          : "#FFF",
                                      marginRight: "20px",
                                      borderRadius: "5px",
                                      padding: "0px 5px 0 5px !important",
                                      backgroundColor:
                                        section.color === "blue"
                                          ? blue[900]
                                          : section.color === "orange"
                                          ? orange[900]
                                          : section.color === "green"
                                          ? green[900]
                                          : section.color === "purple"
                                          ? purple[900]
                                          : section.color === "pink"
                                          ? pink[900]
                                          : section.color === "yellow"
                                          ? yellow[900]
                                          : red[900],
                                    }}
                                  >
                                    {section.title}
                                  </Typography>
                                  <Badge
                                    badgeContent={
                                      section &&
                                      section.tasks &&
                                      section.tasks.length
                                    }
                                    sx={{
                                      marginRight: "10px",
                                      color:
                                        section.color === "blue"
                                          ? blue[900]
                                          : section.color === "orange"
                                          ? orange[900]
                                          : section.color === "green"
                                          ? green[900]
                                          : section.color === "purple"
                                          ? purple[900]
                                          : section.color === "pink"
                                          ? pink[900]
                                          : section.color === "yellow"
                                          ? yellow[900]
                                          : red[900],
                                    }}
                                  ></Badge>
                                </Box>
                                {/* tasks */}
                                {item &&
                                  item.sections &&
                                  item.sections[idx] &&
                                  item.sections[idx].tasks.map(
                                    (task, index) => (
                                      <Card
                                        key={task.id}
                                        sx={{
                                          padding: "7px",
                                          marginBottom: "5px",
                                          cursor: "pointer!important",
                                          "&:hover": {
                                            border:
                                              theme.palette.mode === "dark"
                                                ? "3px solid " +
                                                  assets.colors
                                                    .taskHoverBorderDark
                                                : "3px solid " +
                                                  assets.colors
                                                    .taskHoverBorderLight,
                                            padding: "4.6px",
                                          },
                                        }}
                                        onClick={(e) => {
                                          handleCollapse(task.id);
                                        }}
                                      >
                                        <Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              // alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                // alignItems: "center",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  marginRight: "5px",
                                                  padding: "3px",
                                                  borderRadius: "3px",
                                                  marginTop: "3px",
                                                  fontSize: "9px",
                                                  fontWeight: "bold",
                                                  width: "5px",
                                                  backgroundColor:
                                                    task.priority === "low"
                                                      ? yellow[400]
                                                      : task.priority ===
                                                        "medium"
                                                      ? orange[400]
                                                      : orange[900],
                                                  height: "14px",
                                                }}
                                              ></span>
                                              <Typography
                                                sx={{
                                                  fontWeight: "bold",
                                                  lineHeight: "1.3rem",
                                                }}
                                              >
                                                {task.title === ""
                                                  ? "Untitled"
                                                  : task.title}
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Divider sx={{ margin: "5px 0" }} />
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "11px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  // width: "15px",
                                                  // height: "15px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  padding: "1px 3px 1px 3px",
                                                  marginRight: "5px",
                                                  backgroundColor:
                                                    section.isDone === 1
                                                      ? green[600]
                                                      : Moment(
                                                          task.dueDate
                                                        ).unix() <
                                                        Moment().unix()
                                                      ? red[600]
                                                      : Moment(
                                                          task.dueDate
                                                        ).unix() -
                                                          Moment().unix() <
                                                        90000
                                                      ? orange[600]
                                                      : Moment(
                                                          task.dueDate
                                                        ).unix() -
                                                          Moment().unix() <
                                                        604800
                                                      ? yellow[900]
                                                      : "unset",
                                                  color:
                                                    section.isDone === 1
                                                      ? green[100]
                                                      : Moment(
                                                          task.dueDate
                                                        ).unix() <
                                                        Moment().unix()
                                                      ? red[100]
                                                      : Moment(
                                                          task.dueDate
                                                        ).unix() -
                                                          Moment().unix() <
                                                        90000
                                                      ? orange[100]
                                                      : Moment(
                                                          task.dueDate
                                                        ).unix() -
                                                          Moment().unix() <
                                                        604800
                                                      ? yellow[100]
                                                      : theme.palette.mode ===
                                                        "dark"
                                                      ? grey[100]
                                                      : grey[800],
                                                  borderRadius: "3px",
                                                }}
                                              >
                                                <AccessTimeIcon
                                                  sx={{
                                                    width: "16px",
                                                    height: "16px",
                                                    marginRight: "2px",
                                                  }}
                                                />
                                                {Moment(task.dueDate)
                                                  .locale("id")
                                                  .format("DD MMM")}
                                              </Box>
                                            </Typography>
                                            {task &&
                                              task.users &&
                                              task.users.length > 1 && (
                                                <AvatarGroup
                                                  max={3}
                                                  sx={{
                                                    mr: 1,
                                                    "& .MuiAvatar-root": {
                                                      width: 20,
                                                      height: 20,
                                                      fontSize: 10,
                                                    },
                                                  }}
                                                >
                                                  {task.users.map(
                                                    (user, index) => {
                                                      return (
                                                        <Avatar
                                                          key={`${section.id}-${item.id}-${task.id}-${index}-avatars`}
                                                          alt={user.name}
                                                          src={user.image}
                                                          sx={{
                                                            width: 20,
                                                            height: 20,
                                                          }}
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </AvatarGroup>
                                              )}
                                            {task &&
                                            task.taskChecklists &&
                                            task.taskChecklists.length !== 0 ? (
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                ({task.totalCheckDone} /{" "}
                                                {task.totalCheck}){" "}
                                                {(
                                                  (task.totalCheckDone /
                                                    task.totalCheck) *
                                                  100
                                                ).toFixed(1)}
                                                %
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                          </Box>

                                          {task &&
                                          task.taskChecklists &&
                                          task.taskChecklists.length !== 0 ? (
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={
                                                (task.totalCheckDone /
                                                  task.totalCheck) *
                                                100
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                          <Collapse
                                            in={isOpenCollapse === task.id}
                                          >
                                            <Box
                                              sx={{
                                                margin: "3px 0px",
                                                padding: "0px",
                                                display: "flex",
                                              }}
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: task.content,
                                                }}
                                                className="editor ck-content"
                                              ></div>
                                            </Box>
                                            <Box
                                              sx={{
                                                margin: "0 0.2rem 0 0",
                                              }}
                                            >
                                              {task.tags.map((data, index) => {
                                                return (
                                                  <Chip
                                                    sx={{
                                                      "& .MuiChip-label": {
                                                        padding: "3px 6px",
                                                        margin: "0px",
                                                      },
                                                      margin: "0px 2px 2px 0px",
                                                      fontSize: "10px",
                                                      height: "unset",
                                                    }}
                                                    key={`${section.id}-${item.id}-${task.id}-${index}-tags`}
                                                    label={data.name}
                                                  />
                                                );
                                              })}
                                            </Box>
                                            {task.icons.map((data, index) => {
                                              return (
                                                <Badge
                                                  key={`${section.id}-${item.id}-${data.name}-${data.icon}-${task.id}-${index}-icons`}
                                                  overlap="circular"
                                                  anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                  }}
                                                  badgeContent={
                                                    <Avatar
                                                      alt={data.user}
                                                      src={data.userImage}
                                                      sx={{
                                                        width: 22,
                                                        height: 22,
                                                        border: `2px solid ${theme.palette.background.paper}`,
                                                      }}
                                                    />
                                                  }
                                                  sx={{
                                                    WebkitUserSelect: "none",
                                                    MsUserSelect: "none",
                                                    userSelect: "none",
                                                  }}
                                                >
                                                  <Avatar
                                                    alt={data.icon}
                                                    src={data.iconImage}
                                                    sx={{
                                                      borderRadius: 0,
                                                      marginRight: "5px",
                                                      marginBottom: "5px",
                                                    }}
                                                  />
                                                </Badge>
                                                // <img
                                                //   key={`${section.id}-${item.id}-${task.id}-${index}-icons`}
                                                //   src={data}
                                                //   alt={index}
                                                //   height="50px"
                                                //   width="50px"
                                                //   style={{
                                                //     marginRight:
                                                //       "2px",
                                                //     marginTop:
                                                //       "5px",
                                                //   }}
                                                // />
                                              );
                                            })}

                                            <Typography
                                              sx={{
                                                fontSize: "12px",
                                                padding: "3px 0",
                                              }}
                                            >
                                              {task.startDate.split("-")[0] !==
                                              task.dueDate.split("-")[0] ? (
                                                <>{`Date : ${Moment(
                                                  task.startDate
                                                )
                                                  .locale("id")
                                                  .format(
                                                    "DD MMM YYYY"
                                                  )} - ${Moment(
                                                  task.dueDate
                                                ).format("DD MMM YYYY")}`}</>
                                              ) : task.startDate.split(
                                                  "-"
                                                )[1] !==
                                                task.dueDate.split("-")[1] ? (
                                                <>
                                                  {`Date : ${Moment(
                                                    task.startDate
                                                  )
                                                    .locale("id")
                                                    .format(
                                                      "DD MMM"
                                                    )} - ${Moment(
                                                    task.dueDate
                                                  ).format("DD MMM")}`}
                                                </>
                                              ) : (
                                                <>
                                                  {`Date : ${Moment(
                                                    task.startDate
                                                  )
                                                    .locale("id")
                                                    .format("DD")} - ${Moment(
                                                    task.dueDate
                                                  ).format("DD MMM")}`}
                                                </>
                                              )}
                                            </Typography>
                                          </Collapse>
                                        </Box>
                                      </Card>
                                    )
                                  )}
                              </Box>
                            )}
                          </div>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
          </DragDropContext>
        </>
      )}
      <TaskModal
        icons={icons}
        speedIcons={speedIcons}
        boardStartDateProgress={boardStartDateProgress}
        boardDueDateProgress={boardDueDateProgress}
        progTeam={progTeam}
        task={selectedTask}
        boardId={boardId}
        tagsAll={tags}
        boardType={boardType}
        users={users}
        onClose={() => setSelectedTask(undefined)}
        onUpdate={onUpdateTask}
        onUpdateKanban={onUpdateTaskKanban}
        onUpdateTags={onUpdateTags}
        onUpdateUsers={onUpdateUsers}
        onDelete={onDeleteTask}
      />
      <TaskModalView
        task={selectedTaskView}
        boardId={boardId}
        onClose={() => setSelectedTaskView(undefined)}
        onUpdate={onUpdateTask}
        onUpdateKanban={onUpdateTaskKanban}
        onDelete={onDeleteTask}
      />
      <TaskModalSection
        section={selectedSection}
        boardId={boardId}
        onClose={() => setSelectedSection(undefined)}
        onUpdate={onUpdateSection}
      />
    </>
  );
};

export default Kanban;
