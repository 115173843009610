import { Box, Dialog, DialogContent, DialogContentText, DialogActions,Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { setBoards } from "../redux/features/boardSlice";
import { useNavigate } from "react-router-dom";
import boardApi from "../api/boardApi";
import { useState } from "react";
import { setFavouriteList } from "../redux/features/favouriteSlice";

const Home = () => {
  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.favourites.value)
  const [loading, setLoading] = useState(false);
  const [openDialogCreateBoard, setOpenDialogCreateBoard] = useState(false);

  const createBoard = async () => {
    setLoading(true);
    try {
      const res = await boardApi.create();
      const newList = [res, ...list];
      dispatch(setFavouriteList(newList))
      navigate(`/boards/${res.id}`);
      // await boardApi.create();
      // let res = "";
      // if (user.role.isManage === 1) {
      //   res = await boardApi.getAll();
      // } else {
      //   res = await boardApi.getAllUserId(user.id);
      // }
      // dispatch(setBoards(res));
      // navigate(`/boards/${res[0].id}`);
      // dispatch(setBoards([res]));
      // navigate(`/boards/${res.id}`);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const showDialogCreateBoard = () => {
    setOpenDialogCreateBoard(true);
  };

  const hideDialog = () => {
    setOpenDialogCreateBoard(false);
  };

  return (
    <>
      {openDialogCreateBoard && (
        <Dialog
          open={openDialogCreateBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create board
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={createBoard} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={showDialogCreateBoard}
          loading={loading}
        >
          Click here to create your first board
        </LoadingButton>
      </Box>
    </>
  );
};

export default Home;
