import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import quoteApi from "../../api/quoteApi";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  height: "50%",
  color: "#999",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const QuoteModal = (props) => {
  const [quote, setQuote] = useState(props.quote);
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    setQuote(props.quote);
    setName(props.quote !== undefined ? props.quote.name : "");
    setAuthor(props.quote !== undefined ? props.quote.author : "");
    setType(props.quote !== undefined ? props.quote.type : "");
    if (props.quote !== undefined) {
      isModalClosed = false;
    }
  }, [props.quote]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(quote);
    props.onClose();
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await quoteApi.update(quote.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    quote.name = newName;
    setName(newName);
    props.onUpdate(quote);
  };

  const updateAuthor = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await quoteApi.update(quote.id, { author: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    quote.author = newName;
    setAuthor(newName);
    props.onUpdate(quote);
  };

  const updateType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await quoteApi.update(quote.id, { type: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    quote.type = newName;
    setType(newName);
    props.onUpdate(quote);
  };

  return (
    <>
      <Modal
        open={quote !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={quote !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(quote);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                padding: "0rem 2rem",
              }}
            >
              <Box>
                Name
                <TextField
                  value={name}
                  onChange={updateName}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                    },
                    marginBottom: "10px",
                  }}
                />
              </Box>
              <Box sx={{mb:2}}>
                Type
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Select
                      size="small"
                      labelId="label-position"
                      name="type"
                      required
                      fullWidth
                      id="type"
                      value={type}
                      onChange={updateType}
                    >
                      <MenuItem value="0">Indonesia</MenuItem>
                      <MenuItem value="1">Inggris</MenuItem>
                      <MenuItem value="2">Islami</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                Author
                <TextField
                  value={author}
                  onChange={updateAuthor}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                    },
                    marginBottom: "10px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default QuoteModal;
