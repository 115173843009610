import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import authApi from "../api/authApi";
import positionApi from "../api/positionApi";

const Signup = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [nameErrText, setNameErrText] = useState("");
  const [positionErrText, setPositionErrText] = useState("");
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");
  const [imageErrText, setImageErrText] = useState("");
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const getPositions = async () => {
      try {
        const res = await positionApi.getAll();
        setPositions(res);
      } catch (err) {
        console.log(err);
      }
    };
    getPositions();
  }, []);

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameErrText("");
    setPositionErrText("");
    setUsernameErrText("");
    setPasswordErrText("");
    setConfirmPasswordErrText("");
    setImageErrText("");

    const data = new FormData(e.target);
    const name = data.get("name").trim();
    const username = data.get("username").trim();
    const position = data.get("position").trim();
    const password = data.get("password").trim();
    const confirmPassword = data.get("confirmPassword").trim();
    const image = await getBase64(data.get("image"))
    .then(res => res)
    .catch(err => err);

    let err = false;

    if (name === "") {
      err = true;
      setNameErrText("Please fill this field");
    }
    if (position === "") {
      err = true;
      setPositionErrText("Please fill this field");
    }
    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }
    if (confirmPassword === "") {
      err = true;
      setConfirmPasswordErrText("Please fill this field");
    }
    if (password !== confirmPassword) {
      err = true;
      setConfirmPasswordErrText("Confirm password not match");
    }
    if (image === "") {
      err = true;
      setImageErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    const role = 'user';

    try {
      const res = await authApi.signup({
        name,
        position,
        username,
        password,
        confirmPassword,
        image,
        role,
      });
      setLoading(false);
      localStorage.setItem("token", res.token);
      navigate("/");
    } catch (err) {
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "name") {
          setNameErrText(e.msg);
        }
        if (e.param === "position") {
          setPositionErrText(e.msg);
        }
        if (e.param === "username") {
          setUsernameErrText(e.msg);
        }
        if (e.param === "password") {
          setPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          setConfirmPasswordErrText(e.msg);
        }
        if (e.param === "image") {
          setImageErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          disabled={loading}
          error={nameErrText !== ""}
          helperText={nameErrText}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          disabled={loading}
          error={usernameErrText !== ""}
          helperText={usernameErrText}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          disabled={loading}
          error={passwordErrText !== ""}
          helperText={passwordErrText}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          disabled={loading}
          error={confirmPasswordErrText !== ""}
          helperText={confirmPasswordErrText}
        />
        <FormControl
          fullWidth
          sx={{ marginTop: "16px", marginBottom: "8px" }}
          error={positionErrText !== ""}
          helperText={positionErrText}
        >
          <InputLabel id="label-position">Posisi / Jabatan *</InputLabel>
          <Select
            labelId="label-position"
            name="position"
            required
            fullWidth
            id="position"
            label="Posisi / Jabatan *"
            disabled={loading}
            error={positionErrText !== ""}
          >
            {positions.map((position, index) => (
              <MenuItem key={index} value={position.id}>
                {position.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        Avatar
        <br />
        <input
          type="file"
          name="image"
          disabled={loading}
          error={imageErrText !== ""}
          helperText={imageErrText}
          accept="image/*"
          required
        />
        <LoadingButton
          sx={{ mt: 3, mb: 2 }}
          variant="outlined"
          fullWidth
          color="success"
          type="submit"
          loading={loading}
        >
          Signup
        </LoadingButton>
      </Box>
      <Button component={Link} to="/login" sx={{ textTransform: "none" }}>
        Already have an account? Login
      </Button>
    </>
  );
};

export default Signup;
