import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import sectionApi from "../../api/sectionApi";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "50%",
  color: "#999",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  height: "50%",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const TaskModalSection = (props) => {
  const theme = useTheme();
  const boardId = props.boardId;
  const [section, setSection] = useState(props.section);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    setSection(props.section);
    setTitle(props.section !== undefined ? props.section.title : "");
    setColor(props.section !== undefined ? props.section.color : "");
    if (props.section !== undefined) {
      isModalClosed = false;
    }
  }, [props.section]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(section);
    props.onClose();
  };

  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    timer = setTimeout(async () => {
      try {
        await sectionApi.update(boardId, section.id, { title: newTitle });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    section.title = newTitle;
    setTitle(newTitle);
    props.onUpdate(section);
  };

  const updateColor = async (e) => {
    clearTimeout(timer);
    const newColor = e.target.value;
    timer = setTimeout(async () => {
      try {
        await sectionApi.update(boardId, section.id, { color: newColor });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    section.color = newColor;
    setColor(newColor);
    props.onUpdate(section);
  };

  return (
    <>
      <Modal
        open={section !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={section !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(section);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                padding: "2rem 5rem 5rem",
              }}
            >
              <Typography
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.detailTaskTextDark
                      : assets.colors.detailTaskTextLight,
                }}
              >
                Section Title
              </Typography>
              <TextField
                value={title}
                onChange={updateTitle}
                placeholder="Untitled"
                variant="outlined"
                fullWidth
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": { padding: 0 },
                  "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "2.5rem",
                    fontWeight: "700",
                  },
                  marginBottom: "10px",
                }}
              />
              <FormControl>
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.detailTaskTextDark
                        : assets.colors.detailTaskTextLight,
                  }}
                >
                  Section Color
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={color}
                >
                  {[
                    "blue",
                    "orange",
                    "green",
                    "yellow",
                    "red",
                    "purple",
                    "pink",
                  ].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      onChange={(e) => {
                        updateColor(e);
                      }}
                      control={
                        <Radio
                          sx={{
                            color:
                              value === "blue"
                                ? blue[800]
                                : value === "orange"
                                ? orange[800]
                                : value === "green"
                                ? green[800]
                                : value === "purple"
                                ? purple[800]
                                : value === "pink"
                                ? pink[800]
                                : value === "yellow"
                                ? yellow[800]
                                : red[800],
                            "&.Mui-checked": {
                              color:
                                value === "blue"
                                  ? blue[800]
                                  : value === "orange"
                                  ? orange[800]
                                  : value === "green"
                                  ? green[800]
                                  : value === "purple"
                                  ? purple[800]
                                  : value === "pink"
                                  ? pink[800]
                                  : value === "yellow"
                                  ? yellow[800]
                                  : red[800],
                            },
                          }}
                        />
                      }
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default TaskModalSection;
