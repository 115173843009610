import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Card,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useAutocomplete,
  Badge,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import MyCustomUploadAdapterPlugin from "./ckImageUploader";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Checkbox from "@mui/material/Checkbox";
import { Cancel } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import taskApi from "../../api/taskApi";
import taskChecklistApi from "../../api/taskChecklistApi";
import tagApi from "../../api/tagApi";
import activityApi from "../../api/activityApi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import UserTaskModal from "./UserTaskModal";
import "../../css/custom-editor.css";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
// import "../../css/custom-draggable.css";

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

// const styles= {
//   myComponent: { left: "auto !important", top: "auto !important", },
// };

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  color: "#999",
  height: "80%",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const TaskModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [totalTaskCheck, setTotalTaskCheck] = useState(0);
  const theme = useTheme();
  const user = useSelector((state) => state.user.value);
  const boardId = props.boardId;
  const [tagsAll, setTagsAll] = useState(props.tagsAll);
  const [boardType, setBoardType] = useState(props.boardType);
  const [task, setTask] = useState(props.task);
  const [users, setUsers] = useState(props.users);
  const [progTeam, setProgTeam] = useState(props.progTeam);
  const [usersTask, setUsersTask] = useState([]);
  const [title, setTitle] = useState("");
  const [subtaskType, setSubtaskType] = useState("");
  const [subtaskTypeErr, setSubtaskTypeErr] = useState("");
  const [maxGoals, setMaxGoals] = useState("");
  const [hideMaxGoals, setHideMaxGoals] = useState(null);
  const [maxGoalsErr, setMaxGoalsErr] = useState("");
  const [note, setNote] = useState("");
  const [startDate, setStartDate] = useState(Moment());
  const [dueDate, setDueDate] = useState(Moment());
  const [priority, setPriority] = useState("");
  const [tags, setTags] = useState([]);
  const [taskIcons, setTaskIcons] = useState([]);
  const [content, setContent] = useState("");
  const editorWrapperRef = useRef();
  const icons = props.icons;
  const speedIcons = props.speedIcons;
  const boardDueDateProgress = props.boardDueDateProgress;
  const boardStartDateProgress = props.boardStartDateProgress;
  const [activities, setActivities] = useState([]);
  const [taskChecklists, setTaskChecklists] = useState([]);
  const [isUpdateTitle, setIsUpdateTitle] = useState(0);
  const [selectedManageUser, setSelectedManageUser] = useState(undefined);

  const [openDialogCreateTaskChecklist, setDialogCreateTaskChecklist] =
    useState(false);
  const [openDialogDeleteTaskChecklist, SetDialogDeleteTaskChecklist] =
    useState(false);
  const [idTaskChecklist, setidTaskChecklist] = useState(false);

  const [openDialogDeleteTask, setOpenDialogDeleteTask] = useState(false);

  const showDialogCreateTaskChecklist = (taskChecklistId) => {
    setDialogCreateTaskChecklist(true);
    setSubtaskTypeErr("");
    setMaxGoalsErr("");
    setMaxGoals("");
    setSubtaskType("");
    setHideMaxGoals(null);
    setidTaskChecklist(taskChecklistId);
  };
  const showDialogDeleteTaskChecklist = (taskChecklistId) => {
    SetDialogDeleteTaskChecklist(true);
    setidTaskChecklist(taskChecklistId);
  };

  const showDialogDeleteTask = () => {
    setOpenDialogDeleteTask(true);
  };

  const hideDialog = () => {
    setDialogCreateTaskChecklist(false);
    SetDialogDeleteTaskChecklist(false);
    setOpenDialogDeleteTask(false);
  };

  const updateSubtaskType = async (e) => {
    clearTimeout(timer);
    setSubtaskType(e.target.value);
    setSubtaskTypeErr("");
    setMaxGoalsErr("");
    if (e.target.value === "1") {
      setHideMaxGoals(e.target.value);
    } else {
      setHideMaxGoals(null);
    }
  };

  const updateMaxGoals = async (e) => {
    clearTimeout(timer);
    setMaxGoals(e.target.value);
  };

  const onDragEnd = async ({ source, destination }) => {
    // console.log(source.index)
    // console.log(destination)
    // console.log(user.id)
    const dragTaskIcons = destination.droppableId.split("|||")[1];
    let jsonTaskIcons = [];
    let jsonTaskViewIcons = [];
    dragTaskIcons.split("||").forEach((taskIcon, index) => {
      if (taskIcon.split("|")[0] !== "") {
        jsonTaskIcons.push({
          icon: taskIcon.split("|")[0],
          user: taskIcon.split("|")[2],
        });
        jsonTaskViewIcons.push({
          icon: taskIcon.split("|")[0],
          iconImage: taskIcon.split("|")[1],
          user: taskIcon.split("|")[2],
          userImage: taskIcon.split("|")[3],
        });
      }
    });
    const colIndex = jsonTaskIcons.findIndex(
      (e) => e.icon === source.index && e.user === user.id
    );
    console.log(jsonTaskIcons[colIndex]);
    if (colIndex === -1) {
      const colIdx = icons.findIndex((e) => e.id === source.index);
      let jsonTaskIcon = {
        user: user.id,
        icon: icons[colIdx].id,
      };
      let jsonTaskViewIcon = {
        user: user.id,
        userImage: user.image,
        icon: icons[colIdx].id,
        iconImage: icons[colIdx].icon_url,
      };
      clearTimeout(timer);
      const newTaskIcons = [...jsonTaskIcons, jsonTaskIcon];
      const newTaskViewIcons = [...jsonTaskViewIcons, jsonTaskViewIcon];
      timer = setTimeout(async () => {
        try {
          await taskApi.update(boardId, task.id, { icons: newTaskIcons });
        } catch (err) {
          console.log(err);
        }
      }, timeout);

      task.icons = newTaskViewIcons;
      setTaskIcons(newTaskViewIcons);
      props.onUpdate(task);
    }
  };

  const getActivities = async (taskId) => {
    const res = await activityApi.getOne(boardId, taskId);
    setActivities(res);
  };

  useEffect(() => {
    setUsers(props.users);
    setProgTeam(props.progTeam);
    setBoardType(props.boardType);
    setTask(props.task);
    setTagsAll(props.tagsAll);
    setUsersTask(props.task !== undefined ? props.task.users : "");
    setTitle(props.task !== undefined ? props.task.title : "");
    setNote(props.task !== undefined ? props.task.note : "");
    setContent(props.task !== undefined ? props.task.content : "");
    setStartDate(
      props.task !== undefined ? dayjs(props.task.startDate) : Moment()
    );
    setDueDate(props.task !== undefined ? dayjs(props.task.dueDate) : Moment());
    setPriority(props.task !== undefined ? props.task.priority : "");
    if (props.task !== undefined) {
      console.log(props.task);
      const jsonData = [];
      props.task.tags.forEach((tag) => {
        jsonData.push(tag.name);
      });
      setTags(jsonData);
      let totalChecklist = 0;
      props.task.taskChecklists.forEach((checklist) => {
        if (checklist.type === 0) {
          if (checklist.isChecked === true) {
            totalChecklist = totalChecklist + 1;
          }
        }
      });
      setTotalTaskCheck(totalChecklist);
    }
    setTaskIcons(props.task !== undefined ? props.task.icons : []);
    setTaskChecklists(
      props.task !== undefined ? props.task.taskChecklists : []
    );
    if (props.task !== undefined) {
      isModalClosed = false;
      getActivities(props.task.id);
      updateEditorHeight();
    }
  }, [props.task]);

  const updateEditorHeight = () => {
    setTimeout(() => {
      if (editorWrapperRef.current) {
        const box = editorWrapperRef.current;
        // box.querySelector(".ck-editor__editable_inline").style.height =
        //   box.offsetHeight - 50 + "px";
      }
    }, timeout);
  };

  const onClose = () => {
    isModalClosed = true;
    const lastTaskUsers = task;
    task.users = usersTask;
    props.onUpdateKanban(task, lastTaskUsers);
    props.onUpdate(task);
    props.onClose();
  };

  const deleteTask = async () => {
    try {
      let totalChecklist = 0;
      let totalChecklistQuick = 0;
      let totalChecklistDone = 0;
      let totalChecklistUndone = 0;

      let Difference_In_Time =
        new Date(boardDueDateProgress).getTime() -
        new Date(boardStartDateProgress).getTime();
      const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      let Difference_In_Time_Elapsed =
        Date.now() - new Date(boardStartDateProgress).getTime();
      const dayElapsed = Math.round(
        Difference_In_Time_Elapsed / (1000 * 3600 * 24)
      );

      task.users.forEach((user) => {
        const colIndexUser = users.findIndex((e) => e.id === user.id);
        task.taskChecklists.forEach((taskchecklist) => {
          if (taskchecklist.type === 0) {
            if (taskchecklist.isChecked === true) {
              users[colIndexUser].totalChecklistDone =
                users[colIndexUser].totalChecklistDone - 1;
              totalChecklistDone = totalChecklistDone + 1;
            } else {
              users[colIndexUser].totalChecklistUndone =
                users[colIndexUser].totalChecklistUndone - 1;
              totalChecklistUndone = totalChecklistUndone + 1;
            }
            users[colIndexUser].totalChecklist =
              users[colIndexUser].totalChecklist - 1;
            totalChecklist = totalChecklist + 1;
          } else {
            users[colIndexUser].totalChecklistDone =
              parseInt(users[colIndexUser].totalChecklistDone) -
              parseInt(taskchecklist.current);
            totalChecklistDone =
              parseInt(totalChecklistDone) + parseInt(taskchecklist.current);

            users[colIndexUser].totalChecklistUndone =
              parseInt(users[colIndexUser].totalChecklistUndone) -
              (parseInt(taskchecklist.goals) - parseInt(taskchecklist.current));
            totalChecklistUndone =
              parseInt(totalChecklistUndone) +
              (parseInt(taskchecklist.goals) - parseInt(taskchecklist.current));

            users[colIndexUser].totalChecklist =
              parseInt(users[colIndexUser].totalChecklist) -
              parseInt(taskchecklist.goals);
            totalChecklist =
              parseInt(totalChecklist) + parseInt(taskchecklist.goals);
          }
        });
        if (task.taskChecklists.length === 0) {
          users[colIndexUser].totalChecklist =
            users[colIndexUser].totalChecklist - 1;
          totalChecklist = totalChecklist + 1;

          users[colIndexUser].totalChecklistUndone =
            users[colIndexUser].totalChecklistUndone - 1;
          totalChecklistUndone = totalChecklistUndone + 1;
        }
        users[colIndexUser].progNum =
          (users[colIndexUser].totalChecklistDone /
            users[colIndexUser].totalChecklist) *
          100;

        if (users[colIndexUser].totalChecklistDone === 0) {
          users[colIndexUser].progNum = 0;
        }

        const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
        const treshold =
          users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
        const speed =
          (treshold - users[colIndexUser].totalChecklistUndone) / avgSpeedMin;

        let progIcon = "";
        for (const speedIcon of speedIcons) {
          if (speedIcon.speed == Math.round(speed)) {
            progIcon = speedIcon.icon;
          }
        }
        if (progIcon == "") {
          if (speedIcons[0].speed >= Math.round(speed)) {
            progIcon = speedIcons[0].icon;
          } else {
            if (speedIcons[speedIcons.length - 1].speed <= Math.round(speed)) {
              progIcon = speedIcons[speedIcons.length - 1].icon;
            }
          }
        }

        users[colIndexUser].progIcon = progIcon;
      });

      progTeam.totalCheck = progTeam.totalCheck - totalChecklist;
      progTeam.totalCheckUndone =
        progTeam.totalCheckUndone - totalChecklistUndone;
      const totalChecklistsTeam = progTeam.totalCheck;
      progTeam.totalCheckDone = progTeam.totalCheckDone - totalChecklistDone;
      progTeam.totalCheckDoneQuick =
        progTeam.totalCheckDoneQuick - totalChecklistQuick;

      const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
      const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
      const speedTeam =
        (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

      let progIconTeam = "";
      for (const speedIcon of speedIcons) {
        if (speedIcon.speed == Math.round(speedTeam)) {
          progIconTeam = speedIcon.icon;
        }
      }
      if (progIconTeam == "") {
        if (speedIcons[0].speed >= Math.round(speedTeam)) {
          progIconTeam = speedIcons[0].icon;
        } else {
          if (
            speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
          ) {
            progIconTeam = speedIcons[speedIcons.length - 1].icon;
          }
        }
      }

      progTeam.icon = progIconTeam;
      progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
      props.onUpdateUsers([...users], progTeam);

      await taskApi.delete(boardId, task.id);
      props.onDelete(task);
      setTask(undefined);
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    timer = setTimeout(async () => {
      try {
        await taskApi.update(boardId, task.id, { title: newTitle });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    task.title = newTitle;
    setTitle(newTitle);
    props.onUpdate(task);

    if (isUpdateTitle === 0) {
      setIsUpdateTitle(1);
      const taskId = task.id;
      const currentUserId = user.id;
      const sectionId = 0;
      const message = "Update Task Title";
      const type = 1;
      const lastSectionId = 0;
      await activityApi.create(boardId, {
        taskId,
        currentUserId,
        sectionId,
        message,
        type,
        lastSectionId,
      });
      getActivities(task.id);
    }
  };

  const updateNameTaskChecklist = async (e, taskChecklistId) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await taskChecklistApi.update(boardId, taskChecklistId, {
          name: newName,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    const newData = [...taskChecklists];
    const index = newData.findIndex((e) => e.id === taskChecklistId);
    newData[index].name = newName;
    task.taskChecklists = newData;
    setTaskChecklists(newData);
    props.onUpdate(task);
  };

  const updateCurrentGoals = async (
    e,
    taskChecklistId,
    taskChecklistCreatedAt,
    taskChecklistGoals,
    taskChecklistCurrent
  ) => {
    let Difference_In_Time =
      new Date(boardDueDateProgress).getTime() -
      new Date(boardStartDateProgress).getTime();
    const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    let Difference_In_Time_Elapsed =
      Date.now() - new Date(boardStartDateProgress).getTime();
    const dayElapsed = Math.round(
      Difference_In_Time_Elapsed / (1000 * 3600 * 24)
    );

    if (e.target.value === "" || e.target.value < 0) {
      e.target.value = 0;
    } else if (e.target.value > taskChecklistGoals) {
      e.target.value = taskChecklistCurrent;
    } else if (e.target.value.length > 0) {
      e.target.value = parseInt(e.target.value);
    }
    setLoading(true);
    const newCurrent = e.target.value;
    if (newCurrent !== taskChecklistCurrent) {
      if (newCurrent <= taskChecklistGoals) {
        let totalChecklist = 0;
        let totalChecklistUndone = 0;
        let totalChecklistDone = 0;

        props.task.taskChecklists.forEach((checklist) => {
          if (checklist.id !== taskChecklistId) {
            if (checklist.type === 1) {
              totalChecklist =
                parseInt(totalChecklist) + parseInt(checklist.current);
            }
          }
        });

        let taskChecklistCurrentTeam = 0;
        let newCurrentTeam = 0;
        task.users.forEach((user) => {
          taskChecklistCurrentTeam =
            parseInt(taskChecklistCurrentTeam) + parseInt(taskChecklistCurrent);
          newCurrentTeam = parseInt(newCurrentTeam) + parseInt(newCurrent);
          const colIndexUser = users.findIndex((e) => e.id === user.id);
          if (taskChecklistCurrent > newCurrent) {
            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone -
              (taskChecklistCurrent - newCurrent);
            totalChecklistDone =
              totalChecklistDone - (taskChecklistCurrent - newCurrent);

            users[colIndexUser].totalChecklistUndone =
              parseInt(users[colIndexUser].totalChecklistUndone) +
              (taskChecklistCurrent - newCurrent);
            totalChecklistUndone =
              totalChecklistUndone - (taskChecklistCurrent - newCurrent);
          } else {
            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone +
              (newCurrent - taskChecklistCurrent);
            totalChecklistDone =
              totalChecklistDone + (newCurrent - taskChecklistCurrent);

            users[colIndexUser].totalChecklistUndone =
              parseInt(users[colIndexUser].totalChecklistUndone) -
              (newCurrent - taskChecklistCurrent);
            totalChecklistUndone =
              totalChecklistUndone + (newCurrent - taskChecklistCurrent);
          }

          users[colIndexUser].progNum =
            (users[colIndexUser].totalChecklistDone /
              users[colIndexUser].totalChecklist) *
            100;

          const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
          const treshold =
            users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
          const speed =
            (treshold - users[colIndexUser].totalChecklistUndone) / avgSpeedMin;

          let progIcon = "";
          for (const speedIcon of speedIcons) {
            if (speedIcon.speed == Math.round(speed)) {
              progIcon = speedIcon.icon;
            }
          }
          if (progIcon == "") {
            if (speedIcons[0].speed >= Math.round(speed)) {
              progIcon = speedIcons[0].icon;
            } else {
              if (
                speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
              ) {
                progIcon = speedIcons[speedIcons.length - 1].icon;
              }
            }
          }

          users[colIndexUser].progIcon = progIcon;
          console.log(users[colIndexUser]);
        });
        if (taskChecklistCurrent > newCurrent) {
          progTeam.totalCheckDone =
            progTeam.totalCheckDone -
            (taskChecklistCurrentTeam - newCurrentTeam);

          progTeam.totalCheckUndone =
            parseInt(progTeam.totalCheckUndone) +
            (parseInt(taskChecklistCurrentTeam) - parseInt(newCurrentTeam));
        } else {
          console.log(newCurrentTeam);
          console.log(taskChecklistCurrentTeam);
          console.log(newCurrent);
          console.log(taskChecklistCurrent);
          progTeam.totalCheckDone =
            parseInt(progTeam.totalCheckDone) +
            (parseInt(newCurrentTeam) - parseInt(taskChecklistCurrentTeam));

          progTeam.totalCheckUndone =
            parseInt(progTeam.totalCheckUndone) -
            (parseInt(newCurrentTeam) - parseInt(taskChecklistCurrentTeam));
        }
        const totalChecklistsTeam = progTeam.totalCheck;

        const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
        const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
        const speedTeam =
          (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

        let progIconTeam = "";
        for (const speedIcon of speedIcons) {
          if (speedIcon.speed == Math.round(speedTeam)) {
            progIconTeam = speedIcon.icon;
          }
        }
        if (progIconTeam == "") {
          if (speedIcons[0].speed >= Math.round(speedTeam)) {
            progIconTeam = speedIcons[0].icon;
          } else {
            if (
              speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
            ) {
              progIconTeam = speedIcons[speedIcons.length - 1].icon;
            }
          }
        }

        progTeam.icon = progIconTeam;
        progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
        props.onUpdateUsers([...users], progTeam);

        const newData = [...taskChecklists];
        const index = newData.findIndex((e) => e.id === taskChecklistId);
        newData[index].current = e.target.value;
        task.taskChecklists = newData;
        task.totalCheckDone =
          parseInt(totalTaskCheck) +
          parseInt(totalChecklist) +
          parseInt(newCurrent);
        setTaskChecklists(newData);
        props.onUpdate(task);

        timer = setTimeout(async () => {
          try {
            await taskChecklistApi.update(boardId, taskChecklistId, {
              current: newCurrent,
              updatedAt: Moment(),
            });
          } catch (err) {
            console.log(err);
          }
        }, timeout);

        let taskChecklistStatus = false;
        if (newCurrent === taskChecklistGoals) {
          taskChecklistStatus = true;
        }
        const taskId = task.id;
        const currentUserId = user.id;
        const sectionId = 0;
        const message = "Update Subtask";
        const type = 4;
        const lastSectionId = 0;
        await activityApi.create(boardId, {
          taskId,
          currentUserId,
          sectionId,
          message,
          type,
          lastSectionId,
          taskChecklistId,
          taskChecklistStatus,
          taskChecklistCurrent: newCurrent,
        });
        getActivities(task.id);
      }
    }

    setLoading(false);
  };

  const updateCheckTaskChecklist = async (
    e,
    taskChecklistId,
    taskChecklistCreatedAt
  ) => {
    clearTimeout(timer);
    const newIsChecked = e.target.checked;

    let Difference_In_Time =
      new Date(boardDueDateProgress).getTime() -
      new Date(boardStartDateProgress).getTime();
    const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    let Difference_In_Time_Elapsed =
      Date.now() - new Date(boardStartDateProgress).getTime();
    const dayElapsed = Math.round(
      Difference_In_Time_Elapsed / (1000 * 3600 * 24)
    );
    setLoading(true);
    timer = setTimeout(async () => {
      try {
        await taskChecklistApi.update(boardId, taskChecklistId, {
          isChecked: newIsChecked,
          updatedAt: Moment(),
        });
        if (newIsChecked === true) {
          setTotalTaskCheck((total) => parseInt(total) + 1);
          let totalChecklistQuick = 0;
          let totalChecklistDone = 0;
          let totalChecklistUndone = 0;
          task.users.forEach((user) => {
            const colIndexUser = users.findIndex((e) => e.id === user.id);

            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone + 1;
            totalChecklistDone = totalChecklistDone + 1;

            users[colIndexUser].totalChecklistUndone =
              users[colIndexUser].totalChecklistUndone - 1;
            totalChecklistUndone = totalChecklistUndone + 1;

            users[colIndexUser].progNum =
              (users[colIndexUser].totalChecklistDone /
                users[colIndexUser].totalChecklist) *
              100;

            const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
            const treshold =
              users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
            const speed =
              (treshold - users[colIndexUser].totalChecklistUndone) /
              avgSpeedMin;

            let progIcon = "";
            for (const speedIcon of speedIcons) {
              if (speedIcon.speed == Math.round(speed)) {
                progIcon = speedIcon.icon;
              }
            }
            if (progIcon == "") {
              if (speedIcons[0].speed >= Math.round(speed)) {
                progIcon = speedIcons[0].icon;
              } else {
                if (
                  speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
                ) {
                  progIcon = speedIcons[speedIcons.length - 1].icon;
                }
              }
            }

            users[colIndexUser].progIcon = progIcon;
          });
          const totalChecklistsTeam = progTeam.totalCheck;
          progTeam.totalCheckDone =
            progTeam.totalCheckDone + totalChecklistDone;
          progTeam.totalCheckUndone =
            progTeam.totalCheckUndone - totalChecklistUndone;
          progTeam.totalCheckDoneQuick =
            progTeam.totalCheckDoneQuick + totalChecklistQuick;

          const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
          const tresholdTeam =
            progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
          const speedTeam =
            (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

          let progIconTeam = "";
          for (const speedIcon of speedIcons) {
            if (speedIcon.speed == Math.round(speedTeam)) {
              progIconTeam = speedIcon.icon;
            }
          }
          if (progIconTeam == "") {
            if (speedIcons[0].speed >= Math.round(speedTeam)) {
              progIconTeam = speedIcons[0].icon;
            } else {
              if (
                speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
              ) {
                progIconTeam = speedIcons[speedIcons.length - 1].icon;
              }
            }
          }

          progTeam.icon = progIconTeam;
          progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
          props.onUpdateUsers([...users], progTeam);

          const newData = [...taskChecklists];
          const index = newData.findIndex((e) => e.id === taskChecklistId);
          newData[index].isChecked = newIsChecked;
          task.taskChecklists = newData;
          task.totalCheckDone = task.totalCheckDone + 1;
          setTaskChecklists(newData);
          props.onUpdate(task);
        } else {
          setTotalTaskCheck((total) => parseInt(total) - 1);
          let totalChecklistQuick = 0;
          let totalChecklistDone = 0;
          let totalChecklistUndone = 0;
          task.users.forEach((user) => {
            const colIndexUser = users.findIndex((e) => e.id === user.id);

            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone - 1;
            totalChecklistDone = totalChecklistDone + 1;

            users[colIndexUser].totalChecklistUndone =
              users[colIndexUser].totalChecklistUndone + 1;
            totalChecklistUndone = totalChecklistUndone + 1;

            users[colIndexUser].progNum =
              (users[colIndexUser].totalChecklistDone /
                users[colIndexUser].totalChecklist) *
              100;

            const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
            const treshold =
              users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
            const speed =
              (treshold - users[colIndexUser].totalChecklistUndone) /
              avgSpeedMin;

            let progIcon = "";
            for (const speedIcon of speedIcons) {
              if (speedIcon.speed == Math.round(speed)) {
                progIcon = speedIcon.icon;
              }
            }
            if (progIcon == "") {
              if (speedIcons[0].speed >= Math.round(speed)) {
                progIcon = speedIcons[0].icon;
              } else {
                if (
                  speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
                ) {
                  progIcon = speedIcons[speedIcons.length - 1].icon;
                }
              }
            }

            users[colIndexUser].progIcon = progIcon;
          });
          const totalChecklistsTeam = progTeam.totalCheck;
          progTeam.totalCheckDone =
            progTeam.totalCheckDone - totalChecklistDone;
          progTeam.totalCheckUndone =
            progTeam.totalCheckUndone + totalChecklistUndone;
          progTeam.totalCheckDoneQuick =
            progTeam.totalCheckDoneQuick - totalChecklistQuick;

          const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
          const tresholdTeam =
            progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
          const speedTeam =
            (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

          let progIconTeam = "";
          for (const speedIcon of speedIcons) {
            if (speedIcon.speed == Math.round(speedTeam)) {
              progIconTeam = speedIcon.icon;
            }
          }
          if (progIconTeam == "") {
            if (speedIcons[0].speed >= Math.round(speedTeam)) {
              progIconTeam = speedIcons[0].icon;
            } else {
              if (
                speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
              ) {
                progIconTeam = speedIcons[speedIcons.length - 1].icon;
              }
            }
          }

          progTeam.icon = progIconTeam;
          progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
          props.onUpdateUsers([...users], progTeam);

          const newData = [...taskChecklists];
          const index = newData.findIndex((e) => e.id === taskChecklistId);
          newData[index].isChecked = newIsChecked;
          task.taskChecklists = newData;
          task.totalCheckDone = task.totalCheckDone - 1;
          setTaskChecklists(newData);
          props.onUpdate(task);
        }

        let taskChecklistStatus = false;
        if (newIsChecked === true) {
          taskChecklistStatus = true;
        }
        const taskId = task.id;
        const currentUserId = user.id;
        const sectionId = 0;
        const message = "Update Subtask";
        const type = 3;
        const lastSectionId = 0;
        await activityApi.create(boardId, {
          taskId,
          currentUserId,
          sectionId,
          message,
          type,
          lastSectionId,
          taskChecklistId,
          taskChecklistStatus,
        });
        getActivities(task.id);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setLoading(false);
  };

  const updateNote = async (e) => {
    clearTimeout(timer);
    const newNote = e.target.value;
    setLoading(true);
    timer = setTimeout(async () => {
      try {
        await taskApi.update(boardId, task.id, { note: newNote });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    task.note = newNote;
    setNote(newNote);
    props.onUpdate(task);
    setLoading(false);
  };

  const updatePriority = async (e) => {
    clearTimeout(timer);
    const newPriority = e.target.value;
    timer = setTimeout(async () => {
      try {
        await taskApi.update(boardId, task.id, { priority: newPriority });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    task.priority = newPriority;
    setPriority(newPriority);
    props.onUpdate(task);

    const taskId = task.id;
    const currentUserId = user.id;
    const sectionId = 0;
    const message = "Update Task Priority";
    const type = 1;
    const lastSectionId = 0;
    await activityApi.create(boardId, {
      taskId,
      currentUserId,
      sectionId,
      message,
      type,
      lastSectionId,
    });
    getActivities(task.id);
  };

  const updateStartDate = async (e) => {
    clearTimeout(timer);
    const newStartDate = new Date(e);
    timer = setTimeout(async () => {
      try {
        await taskApi.update(boardId, task.id, { startDate: newStartDate });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    task.startDate = newStartDate.toISOString();
    props.onUpdate(task);
    setStartDate(dayjs(newStartDate));

    const taskId = task.id;
    const currentUserId = user.id;
    const sectionId = 0;
    const message = "Update Task Start Date";
    const type = 1;
    const lastSectionId = 0;
    await activityApi.create(boardId, {
      taskId,
      currentUserId,
      sectionId,
      message,
      type,
      lastSectionId,
    });
    getActivities(task.id);
  };

  const updateDueDate = async (e) => {
    clearTimeout(timer);
    const newDueDate = new Date(e);
    timer = setTimeout(async () => {
      try {
        await taskApi.update(boardId, task.id, {
          dueDate: newDueDate,
        });
        task.dueDate = newDueDate.toISOString();
        props.onUpdate(task);
        setDueDate(dayjs(newDueDate));
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    const taskId = task.id;
    const currentUserId = user.id;
    const sectionId = 0;
    const message = "Update Task Due Date";
    const type = 1;
    const lastSectionId = 0;
    await activityApi.create(boardId, {
      taskId,
      currentUserId,
      sectionId,
      message,
      type,
      lastSectionId,
    });
    getActivities(task.id);
  };

  const updateContent = async (event, editor) => {
    clearTimeout(timer);
    const data = editor.getData();

    console.log({ isModalClosed });

    if (!isModalClosed) {
      timer = setTimeout(async () => {
        try {
          await taskApi.update(boardId, task.id, { content: data });
        } catch (err) {
          console.log(err);
        }
      }, timeout);

      task.content = data;
      setContent(data);
      props.onUpdate(task);
    }
  };

  const deleteIcons = (value) => {
    clearTimeout(timer);
    const newTaskIcons = taskIcons.filter((val) => val !== value);
    timer = setTimeout(async () => {
      try {
        await taskApi.update(boardId, task.id, { icons: newTaskIcons });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    task.icons = newTaskIcons;
    setTaskIcons(newTaskIcons);
    props.onUpdate(task);
  };

  // const {
  //   getRootProps,
  //   getInputLabelProps,
  //   getInputProps,
  //   getTagProps,
  //   getListboxProps,
  //   getOptionProps,
  //   groupedOptions,
  //   value,
  //   focused,
  //   setAnchorEl,
  // } = useAutocomplete({
  //   id: "customized-hook-demo",
  //   value: tags,
  //   multiple: true,
  //   options: tagsAll,
  //   getOptionLabel: (option) => option.name,
  //   onChange: (e, value) => updateTags(e, value),
  // });

  const updateTags = async (e, value) => {
    clearTimeout(timer);
    let jsonData = [];
    timer = setTimeout(async () => {
      value.forEach((tag) => {
        timer = setTimeout(async () => {
          const index = tagsAll.findIndex((e) => e.name === tag);
          if (index === -1) {
            const name = tag;
            const newTag = await tagApi.create({
              name,
              boardType,
            });
            jsonData.push(newTag);
            setTagsAll([...tagsAll, newTag]);
            props.onUpdateTags([...tagsAll, newTag]);
          } else {
            jsonData.push(tagsAll[index]);
          }
        }, timeout);
      });
      console.log(jsonData);

      timer = setTimeout(async () => {
        try {
          await taskApi.update(boardId, task.id, { tags: jsonData });
        } catch (err) {
          console.log(err);
        }
      }, 1000);

      task.tags = jsonData;
      setTags(jsonData);
      props.onUpdate(task);

      const taskId = task.id;
      const currentUserId = user.id;
      const sectionId = 0;
      const message = "Update Task Tags";
      const type = 1;
      const lastSectionId = 0;
      await activityApi.create(boardId, {
        taskId,
        currentUserId,
        sectionId,
        message,
        type,
        lastSectionId,
      });
      getActivities(task.id);
    }, timeout);
  };

  const createTaskChecklist = async (checklist) => {
    setLoading(true);
    console.log(checklist);
    console.log(task);
    const taskId = checklist.split("-")[0];
    const lengthTaskChecklists = checklist.split("-")[1];

    let Difference_In_Time =
      new Date(boardDueDateProgress).getTime() -
      new Date(boardStartDateProgress).getTime();
    const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    let Difference_In_Time_Elapsed =
      Date.now() - new Date(boardStartDateProgress).getTime();
    const dayElapsed = Math.round(
      Difference_In_Time_Elapsed / (1000 * 3600 * 24)
    );

    try {
      if (subtaskType === "") {
        setSubtaskTypeErr("Please select subtask type");
      } else if (subtaskType === "1" && maxGoals === "") {
        setMaxGoalsErr("Please fill max quantity");
      } else {
        let totalChecklist = 0;
        let totalChecklistUndone = 0;
        task.users.forEach((user) => {
          if (maxGoals === "") {
            if (parseInt(lengthTaskChecklists) !== 0) {
              const colIndexUser = users.findIndex((e) => e.id === user.id);
              users[colIndexUser].totalChecklist =
                users[colIndexUser].totalChecklist + 1;
              totalChecklist = totalChecklist + 1;

              users[colIndexUser].totalChecklistUndone =
                users[colIndexUser].totalChecklistUndone + 1;
              totalChecklistUndone = totalChecklistUndone + 1;

              users[colIndexUser].progNum =
                (users[colIndexUser].totalChecklistDone /
                  users[colIndexUser].totalChecklist) *
                100;

              const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
              const treshold =
                users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
              const speed =
                (treshold - users[colIndexUser].totalChecklistUndone) /
                avgSpeedMin;

              let progIcon = "";
              for (const speedIcon of speedIcons) {
                if (speedIcon.speed == Math.round(speed)) {
                  progIcon = speedIcon.icon;
                }
              }
              if (progIcon == "") {
                if (speedIcons[0].speed >= Math.round(speed)) {
                  progIcon = speedIcons[0].icon;
                } else {
                  if (
                    speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
                  ) {
                    progIcon = speedIcons[speedIcons.length - 1].icon;
                  }
                }
              }

              users[colIndexUser].progIcon = progIcon;
            }
          } else {
            const colIndexUser = users.findIndex((e) => e.id === user.id);
            users[colIndexUser].totalChecklist =
              parseInt(users[colIndexUser].totalChecklist) + parseInt(maxGoals);
            totalChecklist = parseInt(totalChecklist) + parseInt(maxGoals);

            users[colIndexUser].totalChecklistUndone =
              parseInt(users[colIndexUser].totalChecklistUndone) +
              parseInt(maxGoals);
            totalChecklistUndone =
              parseInt(totalChecklistUndone) + parseInt(maxGoals);

            users[colIndexUser].progNum =
              (users[colIndexUser].totalChecklistDone /
                users[colIndexUser].totalChecklist) *
              100;

            const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
            const treshold =
              users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
            const speed =
              (treshold - users[colIndexUser].totalChecklistUndone) /
              avgSpeedMin;

            let progIcon = "";
            for (const speedIcon of speedIcons) {
              if (speedIcon.speed == Math.round(speed)) {
                progIcon = speedIcon.icon;
              }
            }
            if (progIcon == "") {
              if (speedIcons[0].speed >= Math.round(speed)) {
                progIcon = speedIcons[0].icon;
              } else {
                if (
                  speedIcons[speedIcons.length - 1].speed <= Math.round(speed)
                ) {
                  progIcon = speedIcons[speedIcons.length - 1].icon;
                }
              }
            }

            users[colIndexUser].progIcon = progIcon;
          }
        });
        progTeam.totalCheck = progTeam.totalCheck + totalChecklist;
        progTeam.totalCheckUndone =
          progTeam.totalCheckUndone + totalChecklistUndone;
        const totalChecklistsTeam = progTeam.totalCheck;

        const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
        const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
        const speedTeam =
          (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

        let progIconTeam = "";
        for (const speedIcon of speedIcons) {
          if (speedIcon.speed == Math.round(speedTeam)) {
            progIconTeam = speedIcon.icon;
          }
        }
        if (progIconTeam == "") {
          if (speedIcons[0].speed >= Math.round(speedTeam)) {
            progIconTeam = speedIcons[0].icon;
          } else {
            if (
              speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
            ) {
              progIconTeam = speedIcons[speedIcons.length - 1].icon;
            }
          }
        }

        progTeam.icon = progIconTeam;
        progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
        props.onUpdateUsers([...users], progTeam);

        if (maxGoals === "") {
          if (lengthTaskChecklists === "0") {
            task.totalCheck = 1;
          } else {
            task.totalCheck = task.totalCheck + 1;
          }
          props.onUpdate(task);
        } else {
          if (lengthTaskChecklists === "0") {
            task.totalCheck = parseInt(maxGoals);
          } else {
            task.totalCheck = parseInt(task.totalCheck) + parseInt(maxGoals);
          }
          props.onUpdate(task);
        }

        const taskChecklist = await taskChecklistApi.create(boardId, {
          taskId,
          subtaskType,
          maxGoals,
        });
        const newTaskChecklists = [...taskChecklists, taskChecklist];
        task.taskChecklists = newTaskChecklists;
        setTaskChecklists(newTaskChecklists);
        props.onUpdate(task);

        const currentUserId = user.id;
        const sectionId = 0;
        const message = "Create Subtask";
        const type = 1;
        const lastSectionId = 0;
        await activityApi.create(boardId, {
          taskId,
          currentUserId,
          sectionId,
          message,
          type,
          lastSectionId,
        });
        getActivities(task.id);

        hideDialog();
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const deleteTaskChecklist = async (taskChecklistId) => {
    try {
      let Difference_In_Time =
        new Date(boardDueDateProgress).getTime() -
        new Date(boardStartDateProgress).getTime();
      const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      let Difference_In_Time_Elapsed =
        Date.now() - new Date(boardStartDateProgress).getTime();
      const dayElapsed = Math.round(
        Difference_In_Time_Elapsed / (1000 * 3600 * 24)
      );

      const colIndex = taskChecklists.findIndex(
        (e) => e.id === taskChecklistId
      );
      const deletedTask = taskChecklists[colIndex];
      if (deletedTask.type === 0) {
        task.totalCheck = task.totalCheck - 1;
        props.onUpdate(task);
      } else {
        task.totalCheck =
          parseInt(task.totalCheck) - parseInt(deletedTask.goals);
        props.onUpdate(task);
      }

      if (deletedTask.type === 0) {
        if (deletedTask.isChecked === true) {
          task.totalCheckDone = task.totalCheckDone - 1;
        }
      } else {
        task.totalCheckDone =
          parseInt(task.totalCheckDone) - parseInt(deletedTask.current);
      }
      let totalChecklist = 0;
      let totalChecklistQuick = 0;
      let totalChecklistDone = 0;
      let totalChecklistUndone = 0;
      task.users.forEach((user) => {
        const colIndexUser = users.findIndex((e) => e.id === user.id);

        if (deletedTask.type === 0) {
          if (deletedTask.isChecked === true) {
            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone - 1;
            totalChecklistDone = totalChecklistDone + 1;
          } else {
            users[colIndexUser].totalChecklistUndone =
              users[colIndexUser].totalChecklistUndone - 1;
            totalChecklistUndone = totalChecklistUndone + 1;
          }

          users[colIndexUser].totalChecklist =
            users[colIndexUser].totalChecklist - 1;
          totalChecklist = totalChecklist + 1;
        } else {
          users[colIndexUser].totalChecklistDone =
            users[colIndexUser].totalChecklistDone - deletedTask.current;
          totalChecklistDone = totalChecklistDone + deletedTask.current;

          users[colIndexUser].totalChecklistUndone =
            users[colIndexUser].totalChecklistUndone -
            (deletedTask.goals - deletedTask.current);
          totalChecklistUndone =
            totalChecklistUndone + (deletedTask.goals - deletedTask.current);

          users[colIndexUser].totalChecklist =
            users[colIndexUser].totalChecklist - deletedTask.goals;
          totalChecklist = totalChecklist + deletedTask.goals;
        }

        users[colIndexUser].progNum =
          (users[colIndexUser].totalChecklistDone /
            users[colIndexUser].totalChecklist) *
          100;

        const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
        const treshold =
          users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
        const speed =
          (treshold - users[colIndexUser].totalChecklistUndone) / avgSpeedMin;

        let progIcon = "";
        for (const speedIcon of speedIcons) {
          if (speedIcon.speed == Math.round(speed)) {
            progIcon = speedIcon.icon;
          }
        }
        if (progIcon == "") {
          if (speedIcons[0].speed >= Math.round(speed)) {
            progIcon = speedIcons[0].icon;
          } else {
            if (speedIcons[speedIcons.length - 1].speed <= Math.round(speed)) {
              progIcon = speedIcons[speedIcons.length - 1].icon;
            }
          }
        }

        users[colIndexUser].progIcon = progIcon;
      });

      console.log(progTeam);

      progTeam.totalCheck = progTeam.totalCheck - totalChecklist;
      const totalChecklistsTeam = progTeam.totalCheck;
      progTeam.totalCheckDone = progTeam.totalCheckDone - totalChecklistDone;
      progTeam.totalCheckUndone =
        progTeam.totalCheckUndone - totalChecklistUndone;
      progTeam.totalCheckDoneQuick =
        progTeam.totalCheckDoneQuick - totalChecklistQuick;

      const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
      const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
      const speedTeam =
        (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

      let progIconTeam = "";
      for (const speedIcon of speedIcons) {
        if (speedIcon.speed == Math.round(speedTeam)) {
          progIconTeam = speedIcon.icon;
        }
      }
      if (progIconTeam == "") {
        if (speedIcons[0].speed >= Math.round(speedTeam)) {
          progIconTeam = speedIcons[0].icon;
        } else {
          if (
            speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
          ) {
            progIconTeam = speedIcons[speedIcons.length - 1].icon;
          }
        }
      }

      progTeam.icon = progIconTeam;
      progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
      props.onUpdateUsers([...users], progTeam);
      await taskChecklistApi.delete(boardId, taskChecklistId);
      const newTaskChecklists = [...taskChecklists].filter(
        (e) => e.id !== taskChecklistId
      );
      task.taskChecklists = newTaskChecklists;
      setTaskChecklists(newTaskChecklists);
      props.onUpdate(task);

      console.log(progTeam);

      const taskId = task.id;
      const currentUserId = user.id;
      const sectionId = 0;
      const message = "Delete Subtask";
      const type = 1;
      const lastSectionId = 0;
      await activityApi.create(boardId, {
        taskId,
        currentUserId,
        sectionId,
        message,
        type,
        lastSectionId,
      });
      getActivities(task.id);

      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateUsers = (users) => {
    setUsersTask(users);
  };

  const onUpdateTask = (task, type, userId) => {
    setTask(task);

    let Difference_In_Time =
      new Date(boardDueDateProgress).getTime() -
      new Date(boardStartDateProgress).getTime();
    const totalDay = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    let Difference_In_Time_Elapsed =
      Date.now() - new Date(boardStartDateProgress).getTime();
    const dayElapsed = Math.round(
      Difference_In_Time_Elapsed / (1000 * 3600 * 24)
    );

    if (type === "delete") {
      let totalChecklist = 0;
      let totalChecklistQuick = 0;
      let totalChecklistDone = 0;
      let totalChecklistUndone = 0;

      const colIndexUser = users.findIndex((e) => e.id === userId);
      task.taskChecklists.forEach((taskchecklist) => {
        if (taskchecklist.type === 0) {
          if (taskchecklist.isChecked === true) {
            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone - 1;
            totalChecklistDone = totalChecklistDone + 1;
          } else {
            users[colIndexUser].totalChecklistUndone =
              users[colIndexUser].totalChecklistUndone - 1;
            totalChecklistUndone = totalChecklistUndone + 1;
          }

          users[colIndexUser].totalChecklist =
            users[colIndexUser].totalChecklist - 1;
          totalChecklist = totalChecklist + 1;
        } else {
          users[colIndexUser].totalChecklistDone =
            parseInt(users[colIndexUser].totalChecklistDone) -
            parseInt(taskchecklist.current);
          totalChecklistDone =
            parseInt(totalChecklistDone) + parseInt(taskchecklist.current);

          users[colIndexUser].totalChecklistUndone =
            parseInt(users[colIndexUser].totalChecklistUndone) -
            (parseInt(taskchecklist.goals) - parseInt(taskchecklist.current));
          totalChecklistUndone =
            parseInt(totalChecklistUndone) +
            (parseInt(taskchecklist.goals) - parseInt(taskchecklist.current));

          users[colIndexUser].totalChecklist =
            parseInt(users[colIndexUser].totalChecklist) -
            parseInt(taskchecklist.goals);
          totalChecklist =
            parseInt(totalChecklist) + parseInt(taskchecklist.goals);
        }
      });
      if (task.taskChecklists.length === 0) {
        totalChecklist = totalChecklist + 1;

        users[colIndexUser].totalChecklistUndone =
          users[colIndexUser].totalChecklistUndone - 1;
        totalChecklistUndone = totalChecklistUndone + 1;
      }
      users[colIndexUser].progNum =
        (users[colIndexUser].totalChecklistDone /
          users[colIndexUser].totalChecklist) *
        100;
      const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
      const treshold =
        users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
      const speed =
        (treshold - users[colIndexUser].totalChecklistUndone) / avgSpeedMin;

      let progIcon = "";
      for (const speedIcon of speedIcons) {
        if (speedIcon.speed == Math.round(speed)) {
          progIcon = speedIcon.icon;
        }
      }
      if (progIcon == "") {
        if (speedIcons[0].speed >= Math.round(speed)) {
          progIcon = speedIcons[0].icon;
        } else {
          if (speedIcons[speedIcons.length - 1].speed <= Math.round(speed)) {
            progIcon = speedIcons[speedIcons.length - 1].icon;
          }
        }
      }
      users[colIndexUser].progIcon = progIcon;

      progTeam.totalCheck = progTeam.totalCheck - totalChecklist;
      progTeam.totalCheckUndone =
        progTeam.totalCheckUndone - totalChecklistUndone;

      const totalChecklistsTeam = progTeam.totalCheck;
      progTeam.totalCheckDone = progTeam.totalCheckDone - totalChecklistDone;
      progTeam.totalCheckDoneQuick =
        progTeam.totalCheckDoneQuick - totalChecklistQuick;

      const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
      const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
      const speedTeam =
        (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

      let progIconTeam = "";
      for (const speedIcon of speedIcons) {
        if (speedIcon.speed == Math.round(speedTeam)) {
          progIconTeam = speedIcon.icon;
        }
      }
      if (progIconTeam == "") {
        if (speedIcons[0].speed >= Math.round(speedTeam)) {
          progIconTeam = speedIcons[0].icon;
        } else {
          if (
            speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
          ) {
            progIconTeam = speedIcons[speedIcons.length - 1].icon;
          }
        }
      }

      progTeam.icon = progIconTeam;
      progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
      props.onUpdateUsers([...users], progTeam);
    } else {
      let totalChecklist = 0;
      let totalChecklistQuick = 0;
      let totalChecklistDone = 0;
      let totalChecklistUndone = 0;

      const colIndexUser = users.findIndex((e) => e.id === userId);
      task.taskChecklists.forEach((taskchecklist) => {
        if (taskchecklist.type === 0) {
          if (taskchecklist.isChecked === true) {
            users[colIndexUser].totalChecklistDone =
              users[colIndexUser].totalChecklistDone + 1;
            totalChecklistDone = totalChecklistDone + 1;
          } else {
            users[colIndexUser].totalChecklistUndone =
              users[colIndexUser].totalChecklistUndone + 1;
            totalChecklistUndone = totalChecklistUndone + 1;
          }

          users[colIndexUser].totalChecklist =
            users[colIndexUser].totalChecklist + 1;
          totalChecklist = totalChecklist + 1;
        } else {
          users[colIndexUser].totalChecklistDone =
            parseInt(users[colIndexUser].totalChecklistDone) +
            parseInt(taskchecklist.current);
          totalChecklistDone =
            parseInt(totalChecklistDone) + parseInt(taskchecklist.current);

          users[colIndexUser].totalChecklistUndone =
            parseInt(users[colIndexUser].totalChecklistUndone) +
            (parseInt(taskchecklist.goals) - parseInt(taskchecklist.current));
          totalChecklistUndone =
            parseInt(totalChecklistUndone) +
            (parseInt(taskchecklist.goals) - parseInt(taskchecklist.current));

          users[colIndexUser].totalChecklist =
            parseInt(users[colIndexUser].totalChecklist) +
            parseInt(taskchecklist.goals);
          totalChecklist =
            parseInt(totalChecklist) + parseInt(taskchecklist.goals);
        }
      });
      if (task.taskChecklists.length === 0) {
        totalChecklist = totalChecklist + 1;

        users[colIndexUser].totalChecklistUndone =
          users[colIndexUser].totalChecklistUndone + 1;
        totalChecklistUndone = totalChecklistUndone + 1;
      }
      users[colIndexUser].progNum =
        (users[colIndexUser].totalChecklistDone /
          users[colIndexUser].totalChecklist) *
        100;
      const avgSpeedMin = users[colIndexUser].totalChecklist / totalDay;
      const treshold =
        users[colIndexUser].totalChecklist - avgSpeedMin * dayElapsed;
      const speed =
        (treshold - users[colIndexUser].totalChecklistUndone) / avgSpeedMin;

      let progIcon = "";
      for (const speedIcon of speedIcons) {
        if (speedIcon.speed == Math.round(speed)) {
          progIcon = speedIcon.icon;
        }
      }
      if (progIcon == "") {
        if (speedIcons[0].speed >= Math.round(speed)) {
          progIcon = speedIcons[0].icon;
        } else {
          if (speedIcons[speedIcons.length - 1].speed <= Math.round(speed)) {
            progIcon = speedIcons[speedIcons.length - 1].icon;
          }
        }
      }
      users[colIndexUser].progIcon = progIcon;
      console.log(users[colIndexUser]);
      progTeam.totalCheck = progTeam.totalCheck + totalChecklist;
      progTeam.totalCheckUndone =
        progTeam.totalCheckUndone - totalChecklistUndone;

      const totalChecklistsTeam = progTeam.totalCheck;
      progTeam.totalCheckDone = progTeam.totalCheckDone + totalChecklistDone;
      progTeam.totalCheckDoneQuick =
        progTeam.totalCheckDoneQuick + totalChecklistQuick;

      const avgSpeedMinTeam = progTeam.totalCheck / totalDay;
      const tresholdTeam = progTeam.totalCheck - avgSpeedMinTeam * dayElapsed;
      const speedTeam =
        (tresholdTeam - progTeam.totalCheckUndone) / avgSpeedMinTeam;

      let progIconTeam = "";
      for (const speedIcon of speedIcons) {
        if (speedIcon.speed == Math.round(speedTeam)) {
          progIconTeam = speedIcon.icon;
        }
      }
      if (progIconTeam == "") {
        if (speedIcons[0].speed >= Math.round(speedTeam)) {
          progIconTeam = speedIcons[0].icon;
        } else {
          if (
            speedIcons[speedIcons.length - 1].speed <= Math.round(speedTeam)
          ) {
            progIconTeam = speedIcons[speedIcons.length - 1].icon;
          }
        }
      }

      progTeam.icon = progIconTeam;
      progTeam.num = (progTeam.totalCheckDone / totalChecklistsTeam) * 100;
      props.onUpdateUsers([...users], progTeam);
    }
  };

  return (
    <>
      {openDialogCreateTaskChecklist && (
        <Dialog
          open={openDialogCreateTaskChecklist}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* Are you sure create subtask */}
              <Typography
                fontWeight="700"
                sx={{ fontSize: "18px", textAlign: "center", mt: 1 }}
              >
                Select Subtask Type
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  defaultValue={subtaskType}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Checklist"
                    onChange={(e) => {
                      updateSubtaskType(e);
                    }}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Quantity"
                    onChange={(e) => {
                      updateSubtaskType(e);
                    }}
                  />
                </RadioGroup>
                {hideMaxGoals && (
                  <TextField
                    value={maxGoals}
                    size="small"
                    type="number"
                    onChange={updateMaxGoals}
                    placeholder="Set Max Quantity"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Typography>
              {maxGoalsErr !== "" && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    mt: 0,
                    color: "red",
                  }}
                >
                  {maxGoalsErr}
                </Typography>
              )}
              {subtaskTypeErr !== "" && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    mt: 0,
                    color: "red",
                  }}
                >
                  {subtaskTypeErr}
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              onClick={() => createTaskChecklist(idTaskChecklist)}
              color="primary"
              loading={loading}
            >
              Yes
            </LoadingButton>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDialogDeleteTaskChecklist && (
        <Dialog
          open={openDialogDeleteTaskChecklist}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete subtask
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                deleteTaskChecklist(idTaskChecklist);
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDialogDeleteTask && (
        <Dialog
          open={openDialogDeleteTask}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete task
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteTask} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Modal
        open={task !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={task !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size="1.1rem" sx={{ marginRight: "7px" }} />{" "}
                  Loading
                </Box>
              )}
              {/* {task && user.id === task.userAssigneeId.id && ( */}
              <IconButton
                variant="outlined"
                color="error"
                onClick={showDialogDeleteTask}
              >
                <DeleteOutlinedIcon />
              </IconButton>
              {/* )} */}
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  const lastTaskUsers = task;
                  task.users = usersTask;
                  console.log(lastTaskUsers);
                  console.log(task.users);
                  props.onUpdateKanban(task, lastTaskUsers);
                  props.onUpdate(task);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                padding: "0rem 2rem 2rem 2rem",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.detailTaskTextDark
                    : assets.colors.detailTaskTextLight,
              }}
            >
              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Task Owner &nbsp;
                <AvatarGroup max={20} sx={{ mr: 1, ml: 1 }}>
                  {task &&
                    usersTask &&
                    usersTask.map((user, index) => (
                      <Tooltip key={`avatarBoard-${index}`} title={user.name}>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <Typography>{user.mood.icon}</Typography>
                          }
                          sx={{
                            WebkitUserSelect: "none",
                            MsUserSelect: "none",
                            userSelect: "none",
                          }}
                        >
                          <Avatar
                            alt={user.name}
                            src={user.image}
                            sx={{
                              "&:hover": {
                                opacity: 0.85,
                                backgroundColor: "#000",
                              },
                            }}
                          />
                        </Badge>
                      </Tooltip>
                    ))}
                </AvatarGroup>
                {task && user.id === task.userAssigneeId.id && (
                  <Button
                    onClick={() => {
                      setSelectedManageUser(task.id);
                    }}
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                  >
                    Add / Delete Users
                  </Button>
                )}
                {/* <b>{task && task.user && task.user.name}</b> */}
              </Box>
              Task Title
              {/* {user.role.isManage === 1 ? ( */}
              <TextField
                value={title}
                onChange={updateTitle}
                placeholder="Untitled"
                variant="outlined"
                fullWidth
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": { padding: 0 },
                  "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "2.5rem",
                    fontWeight: "700",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "rgba(0, 0, 0, 1)",
                  },
                  marginBottom: "10px",
                }}
              />
              {/* ) : (
                <Typography
                  sx={{
                    width: "100%",
                    padding: 0,
                    fontSize: "2.5rem",
                    fontWeight: "700",
                    WebkitTextFillColor: "rgba(0, 0, 0, 1)",
                    marginBottom: "10px",
                  }}
                >
                  {title}
                </Typography>
              )} */}
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4}>
                      Start Date
                      {/* {user.role.isManage === 1 ? ( */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={startDate}
                          onChange={updateStartDate}
                          dateFormat="dd/MM/yyyy"
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {/* ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "18px",
                            color:
                              theme.palette.mode === "dark"
                                ? assets.colors.boardTextDark
                                : assets.colors.boardTextLight,
                          }}
                        >
                          {dayjs(startDate).format("DD MMM YYYY")}
                        </Typography>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      Due Date
                      {/* {user.role.isManage === 1 ? ( */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dueDate}
                          onChange={updateDueDate}
                          dateFormat="dd/MM/yyyy"
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {/* ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "18px",
                            color:
                              theme.palette.mode === "dark"
                                ? assets.colors.boardTextDark
                                : assets.colors.boardTextLight,
                          }}
                        >
                          {dayjs(dueDate).format("DD MMM YYYY")}
                        </Typography>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      Priority
                      {/* {user.role.isManage === 1 ? ( */}
                      <FormControl fullWidth>
                        <Select value={priority} onChange={updatePriority}>
                          <MenuItem value="low">low</MenuItem>
                          <MenuItem value="medium">medium</MenuItem>
                          <MenuItem value="high">high</MenuItem>
                        </Select>
                      </FormControl>
                      {/* ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "18px",
                            textTransform: "capitalize",
                            color:
                              theme.palette.mode === "dark"
                                ? assets.colors.boardTextDark
                                : assets.colors.boardTextLight,
                          }}
                        >
                          {priority}
                        </Typography>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      Tags
                      {/* {user.role.isManage === 1 ? ( */}
                      <Autocomplete
                        multiple
                        id="tags-filled"
                        onChange={(e, value) => updateTags(e, value)}
                        options={tagsAll.map((option) => option.name)}
                        defaultValue={tags}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({
                              index,
                            });
                            return (
                              <Chip
                                variant="outlined"
                                label={option}
                                key={key}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                      {/* ) : (
                        <>
                          {tags.length === 0 && (
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "18px",
                                textTransform: "capitalize",
                                color:
                                  theme.palette.mode === "dark"
                                    ? assets.colors.boardTextDark
                                    : assets.colors.boardTextLight,
                              }}
                            >
                              Tags Not Found
                            </Typography>
                          )}
                          <Box>
                            {tags.map((data, index) => {
                              return (
                                <Chip
                                  sx={{
                                    "& .MuiChip-label": {
                                      padding: "5px 10px",
                                      margin: "0px",
                                    },
                                    margin: "5px 5px 5px 0px",
                                    fontSize: "12px",
                                    height: "unset",
                                  }}
                                  key={`${index}-tags`}
                                  label={data}
                                />
                              );
                            })}
                          </Box>
                        </>
                      )} */}
                      {/* <Box>
                        <div {...getRootProps()}>
                          <Label {...getInputLabelProps()}>Tags</Label>
                          <InputWrapper
                            ref={setAnchorEl}
                            sx={{ width: "100%" }}
                            className={focused ? "focused" : ""}
                          >
                            {value.map((option, index) => (
                              <StyledTag
                                label={option.name}
                                {...getTagProps({ index })}
                              />
                            ))}
                            <input {...getInputProps()} />
                          </InputWrapper>
                        </div>
                        {groupedOptions.length > 0 ? (
                          <Listbox {...getListboxProps()}>
                            {groupedOptions.map((option, index) => (
                              <li {...getOptionProps({ option, index })}>
                                <span>{option.name}</span>
                                <CheckIcon fontSize="small" />
                              </li>
                            ))}
                          </Listbox>
                        ) : null}
                      </Box> */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Subtask</p>
                        {/* {user.role.isManage === 1 && ( */}
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            color: "gray",
                            "&:hover": { color: "green" },
                          }}
                          onClick={() =>
                            showDialogCreateTaskChecklist(
                              task.id + "-" + task.taskChecklists.length
                            )
                          }
                        >
                          <AddOutlinedIcon />
                        </Button>
                        {/* )} */}
                      </Box>
                      <Box>
                        {taskChecklists &&
                          taskChecklists.length !== 0 &&
                          taskChecklists.map((taskChecklist, index) => (
                            <Box
                              key={index}
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ width: "50%" }}
                                fontWeight="300"
                              >
                                {/* {user.role.isManage === 1 ? ( */}
                                <TextField
                                  defaultValue={taskChecklist.name}
                                  onChange={(e) => {
                                    updateNameTaskChecklist(
                                      e,
                                      taskChecklist.id
                                    );
                                  }}
                                  placeholder="Untitled"
                                  variant="outlined"
                                  fullWidth
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": {
                                      padding: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "unset ",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      fontSize: "1rem",
                                      fontWeight: "300",
                                    },
                                    marginBottom: "10px",
                                  }}
                                />
                                {/* ) : (
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      padding: 0,
                                      border: "unset ",
                                      fontSize: "1rem",
                                      fontWeight: "300",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {taskChecklist.name === ""
                                      ? "Untitled"
                                      : taskChecklist.name}
                                  </Typography>
                                )} */}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  width: "50%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                fontWeight="100"
                              >
                                {taskChecklist.type === 0 ? (
                                  <>
                                    <Checkbox
                                      checked={taskChecklist.isChecked}
                                      onChange={(e) => {
                                        updateCheckTaskChecklist(
                                          e,
                                          taskChecklist.id,
                                          taskChecklist.createdAt
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                    {/* {user.role.isManage === 1 && ( */}
                                    <IconButton
                                      variant="outlined"
                                      color="error"
                                      onClick={() => {
                                        showDialogDeleteTaskChecklist(
                                          taskChecklist.id
                                        );
                                      }}
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                    {/* )} */}
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    >
                                      Progress :
                                    </Typography>
                                    <TextField
                                      type="number"
                                      defaultValue={taskChecklist.current}
                                      onChange={(e) => {
                                        updateCurrentGoals(
                                          e,
                                          taskChecklist.id,
                                          taskChecklist.createdAt,
                                          taskChecklist.goals,
                                          taskChecklist.current
                                        );
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      sx={{
                                        width: "50px",
                                        mr: 1,
                                        ml: 1,
                                        "& .MuiOutlinedInput-input": {
                                          padding: "1px 5px",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          // border: "unset ",
                                        },
                                        // "& .MuiOutlinedInput-root": {
                                        //   fontSize: "20px",
                                        //   fontWeight: "700",
                                        // },
                                      }}
                                    />
                                    <Typography
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    >
                                      / <b>{taskChecklist.goals}</b>
                                    </Typography>
                                    {/* {user.role.isManage === 1 && ( */}
                                    <IconButton
                                      sx={{ ml: 1 }}
                                      variant="outlined"
                                      color="error"
                                      onClick={() => {
                                        showDialogDeleteTaskChecklist(
                                          taskChecklist.id
                                        );
                                      }}
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                    {/* )} */}
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                    </Grid>
                    {user.role.isManage === 1 && (
                      <Grid item xs={12} sm={12}>
                        Assessment
                        <DragDropContext
                          onDragEnd={onDragEnd}
                          key={task !== undefined ? task.id : ""}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              overflowX: "auto",
                              paddingTop: "10px",
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{ marginBottom: "20px" }}
                            >
                              <Grid item xs={12} sm={7}>
                                <div
                                  key={task !== undefined ? task.id : ""}
                                  style={{
                                    minHeight: "300px",
                                    padding: "10px 10px 10px 10px",
                                    border: "5px dashed #666",
                                  }}
                                >
                                  <Droppable
                                    key={task !== undefined ? task.id : ""}
                                    droppableId={
                                      task !== undefined
                                        ? `${task.id}|||${taskIcons.reduce(
                                            (acc, curr) =>
                                              `${acc}${curr.icon}|${curr.iconImage}|${curr.user}|${curr.userImage}||`,
                                            ""
                                          )}`
                                        : ""
                                    }
                                  >
                                    {(provided) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                          width: "300px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{ marginBottom: "20px" }}
                                        >
                                          {taskIcons.length === 0 ? (
                                            <div
                                              style={{
                                                height: "300px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                fontWeight: "bold",
                                                color: "#666",
                                              }}
                                            >
                                              <ArrowDropDownCircleIcon />
                                              &nbsp; Drop Icon Here
                                            </div>
                                          ) : (
                                            <>
                                              {taskIcons.map((icon, index) => (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={4}
                                                  key={index}
                                                >
                                                  <Draggable
                                                    key={index}
                                                    draggableId={index}
                                                    index={index}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <Card
                                                        className="detail"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                          boxShadow: "unset",
                                                          height: "unset",
                                                          display: "flex",
                                                          borderRadius: "unset",
                                                          backgroundColor:
                                                            "unset",
                                                          backgroundImage:
                                                            "unset",
                                                          padding:
                                                            "0px 0px 0px 0px",
                                                          cursor:
                                                            snapshot.isDragging
                                                              ? "grab"
                                                              : "pointer!important",
                                                        }}
                                                      >
                                                        <Badge
                                                          key={`${index}-icons`}
                                                          overlap="circular"
                                                          anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "right",
                                                          }}
                                                          badgeContent={
                                                            <Avatar
                                                              alt={icon.user}
                                                              src={
                                                                icon.userImage
                                                              }
                                                              sx={{
                                                                width: 22,
                                                                height: 22,
                                                                border: `2px solid ${theme.palette.background.paper}`,
                                                              }}
                                                            />
                                                          }
                                                          sx={{
                                                            WebkitUserSelect:
                                                              "none",
                                                            MsUserSelect:
                                                              "none",
                                                            userSelect: "none",
                                                          }}
                                                        >
                                                          <Avatar
                                                            alt={icon.icon}
                                                            src={icon.iconImage}
                                                            sx={{
                                                              borderRadius: 0,
                                                              marginRight:
                                                                "5px",
                                                              marginBottom:
                                                                "5px",
                                                            }}
                                                          />
                                                        </Badge>
                                                        {/* <img
                                                          src={icon}
                                                          alt={index}
                                                          height="50px"
                                                          width="50px"
                                                        /> */}
                                                        {icon.user ===
                                                          user.id && (
                                                          <Cancel
                                                            sx={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              deleteIcons(icon);
                                                            }}
                                                          />
                                                        )}
                                                      </Card>
                                                    )}
                                                  </Draggable>
                                                </Grid>
                                              ))}
                                            </>
                                          )}
                                          {provided.placeholder}
                                        </Grid>
                                      </Box>
                                    )}
                                  </Droppable>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <div key="cards">
                                  <Droppable key="cards" droppableId="cards">
                                    {(provided) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        Icon Assessment
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{
                                            marginBottom: "20px",
                                            marginTop: "0px",
                                          }}
                                        >
                                          {icons.map((icon, index) => (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={4}
                                              key={icon.id}
                                            >
                                              <Draggable
                                                key={icon.id}
                                                draggableId={icon.id}
                                                index={icon.id}
                                                sx={{
                                                  left: "auto !important",
                                                  top: "auto !important",
                                                }}
                                              >
                                                {(provided, snapshot) => (
                                                  <Card
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                      backgroundColor: "unset",
                                                      backgroundImage: "unset",
                                                      boxShadow: "unset",
                                                      padding: "0px",
                                                      left: "auto !important",
                                                      top: "auto !important",
                                                    }}
                                                  >
                                                    <img
                                                      src={icon.icon_url}
                                                      alt={icon.id}
                                                      height="50px"
                                                      width="50px"
                                                    />
                                                  </Card>
                                                )}
                                              </Draggable>
                                            </Grid>
                                          ))}
                                        </Grid>
                                        {provided.placeholder}
                                      </Box>
                                    )}
                                  </Droppable>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </DragDropContext>
                        Notes
                        <Card
                          sx={{ padding: "5px 15px", marginBottom: "15px" }}
                        >
                          <TextField
                            value={note}
                            multiline
                            rows={2}
                            maxRows={4}
                            onChange={updateNote}
                            placeholder="Untitled"
                            variant="outlined"
                            fullWidth
                            sx={{
                              width: "100%",
                              "& .MuiOutlinedInput-input": { padding: 0 },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "unset ",
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "20px",
                                fontWeight: "700",
                              },
                            }}
                          />
                        </Card>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        Assignee By :{" "}
                        <b>
                          {task &&
                            task.userAssigneeId &&
                            task.userAssigneeId.name}
                        </b>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  Content
                  <Box
                    ref={editorWrapperRef}
                    sx={{
                      position: "relative",
                      // height: "100%",
                      overflowX: "hidden",
                      overflowY: "auto",
                      mb: 3,
                    }}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      data={content}
                      onChange={updateContent}
                      onFocus={updateEditorHeight}
                      onBlur={updateEditorHeight}
                      // config={{
                      //   ckfinder: {
                      //     // Upload the images to the server using the CKFinder QuickUpload command.
                      //     uploadUrl:
                      //       "http://127.0.0.1:5000/api/v1/boards/" +
                      //       boardId +
                      //       "/tasks/image-content-upload",
                      //   },
                      // }}
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "insertTable",
                            "|",
                            "undo",
                            "redo",
                          ],
                        },
                        extraPlugins: [MyCustomUploadAdapterPlugin],
                      }}
                    />
                  </Box>
                  Activity
                  <Box
                    sx={{
                      marginBottom: "10px",
                      maxHeight: "100px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    {activities.map((activity, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" fontWeight="300">
                          {activity.type === 2 ? (
                            <>
                              {activity.user} {activity.message} From{" "}
                              {activity.lastSection} To {activity.section}
                            </>
                          ) : (
                            <>
                              {activity.user} {activity.message}{" "}
                              {activity.section}
                            </>
                          )}
                        </Typography>
                        <Typography variant="body2" fontWeight="100">
                          {/* {Moment(activity.createdAt)
                            .locale("id")
                            .format("DD MMM YYYY")} */}
                          {Moment(activity.createdAt)
                            .locale("id")
                            .startOf("minute")
                            .fromNow()}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
              {/* <Typography variant="body2" fontWeight="700">
              {task !== undefined
                ? Moment(task.createdAt).format("YYYY-MM-DD")
                : ""}
            </Typography> */}
              {/* <Divider sx={{ margin: "1.5rem 0" }} /> */}
            </Box>
          </Box>
        </Fade>
      </Modal>

      {task && usersTask && (
        <UserTaskModal
          boardId={boardId}
          task={task}
          taskId={selectedManageUser}
          users={usersTask}
          allUsers={users}
          onUpdate={onUpdateUsers}
          onUpdateTask={onUpdateTask}
          onClose={() => setSelectedManageUser(undefined)}
        />
      )}
    </>
  );
};

export default TaskModal;
