import axiosClient from "./axiosClient";

const iconApi = {
  create: (params) => axiosClient.post("icons", params),
  getAll: () => axiosClient.get("icons"),
  delete: (id) => axiosClient.delete(`icons/${id}`),
  update: (id, params) => axiosClient.put(`icons/${id}`, params),
};

export default iconApi;
