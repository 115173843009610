import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import quoteApi from "../../api/quoteApi";
import setupLoginApi from "../../api/setupLoginApi";
import QuoteModal from "../../components/common/QuoteModal";
import assets from "../../assets/index";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { MuiColorInput } from "mui-color-input";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

let timer;
const timeout = 500;

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "author",
    numeric: false,
    disablePadding: true,
    label: "Author",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showDialogDeleteQuote, selected, requestSearch, searchFilter } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormatListBulletedIcon sx={{ mr: 1 }} /> List Quote
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => showDialogDeleteQuote(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? assets.colors.inputTypeDark
                  : assets.colors.inputTypeLight,
              mt: 2,
              mr: 2,
              width: "300px",
            }}
            value={searchFilter}
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Search name..."
            type="search"
            onInput={(e) => requestSearch(e.target.value)}
          />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const BackgroundLogin = () => {
  const theme = useTheme();
  const [selectedQuote, setSelectedQuote] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [setupLoginImage, setSetupLoginImage] = useState("");
  const [setupLoginColor, setSetupLoginColor] = useState("");
  const [setupLoginQuotes, setSetupLoginQuotes] = useState("");
  const [setupLoginActive, setSetupLoginActive] = useState("");

  const [loading, setLoading] = useState(false);
  const [nameErrText, setNameErrText] = useState("");
  const [typeErrText, setIsManageErrText] = useState("");
  const [authorErrText, setTypeManageErrText] = useState("");
  const [fileURL, setFileURL] = useState(null);

  const [openDialogDeleteQuote, setOpenDialogDeleteQuote] = useState(false);
  const [idDeleteQuote, setIdDeleteQuote] = useState(false);

  const [copyList, setCopyList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const requestSearch = (searched) => {
    setCopyList(rows.filter((item) => item.name.toString().toLowerCase().includes(searched)));
    setSearchFilter(searched);
  };

  useEffect(() => {
    const getQuotes = async () => {
      try {
        const jsonData = [];
        const res = await quoteApi.getAll();
        res.forEach((value, index) => {
          jsonData.push({
            _id: value.id,
            id: value.id,
            name: value.name,
            author: value.author,
            type: value.type,
            action: (
              <Tooltip title="Edit">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedQuote(value);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ),
          });
        });
        setRows(jsonData);
        setCopyList(jsonData);
      } catch (err) {
        console.log(err);
      }
    };
    getQuotes();
  }, []);

  const showDialogDeleteQuote = (sectionId) => {
    setOpenDialogDeleteQuote(true);
    setIdDeleteQuote(sectionId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteQuote(false);
  };

  const onUpdateQuote = (quote) => {
    const newData = [...rows];
    const quoteIndex = newData.findIndex((e) => e._id === quote.id);
    newData[quoteIndex].name = quote.name;
    newData[quoteIndex].author = quote.author;
    newData[quoteIndex].type = quote.type;
    setRows(newData);
    setCopyList(newData);
  };

  const deleteQuotes = (quotes) => {
    quotes.forEach(async (value) => {
      try {
        const deleteJson = await quoteApi.delete(value);
        if (deleteJson === "deleted") {
          const newData = [...rows].filter((e) => !quotes.includes(e.id));
          setRows(newData);
          setCopyList(newData);
          setSelected([]);
          hideDialog();
        } else {
          // setDeleteFailCount(deleteJson);
          // setOpenDialogDeleteFail(true);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const visibleRowsCopy = useMemo(
    () =>
      stableSort(copyList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, copyList]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameErrText("");
    setIsManageErrText("");
    setTypeManageErrText("");

    const data = new FormData(e.target);
    const name = data.get("name").trim();
    const type = data.get("type").trim();
    const author = data.get("author").trim();

    let err = false;

    if (name === "") {
      err = true;
      setNameErrText("Please fill this field");
    }
    if (type === "") {
      err = true;
      setIsManageErrText("Please fill this field");
    }
    if (author === "") {
      err = true;
      setTypeManageErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await quoteApi.create({
        name,
        type,
        author,
      });
      setLoading(false);
      const newJsonData = {
        _id: res.id,
        id: res.id,
        name: res.name,
        type: res.type,
        author: res.author,
        action: (
          <Tooltip title="Edit">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedQuote(res);
              }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
      };
      const newData = [...rows];
      newData.unshift(newJsonData);
      console.log(newData);
      setRows(newData);
      setCopyList(newData);
      e.target.reset();
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "name") {
          setNameErrText(e.msg);
        }
        if (e.param === "type") {
          setIsManageErrText(e.msg);
        }
        if (e.param === "author") {
          setTypeManageErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const getSetupLogin = async () => {
      try {
        const res = await setupLoginApi.getAll();
        setSetupLoginImage(res.image);
        setSetupLoginColor(res.color);
        setSetupLoginQuotes(res.quotes);
        setSetupLoginActive(res.isActiveBg);
      } catch (err) {
        console.log(err);
      }
    };
    getSetupLogin();
  }, []);

  const updateQuotes = async (e) => {
    clearTimeout(timer);
    const quotes = e.target.value;
    timer = setTimeout(async () => {
      try {
        await setupLoginApi.create({
          quotes,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setSetupLoginQuotes(quotes);
  };

  const updateColor = async (e) => {
    clearTimeout(timer);
    const color = e;
    timer = setTimeout(async () => {
      try {
        await setupLoginApi.create({
          color,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setSetupLoginColor(color);
  };

  const updateActiveQuotes = async (e) => {
    clearTimeout(timer);
    const quotes = e.target.value;
    timer = setTimeout(async () => {
      try {
        await setupLoginApi.create({
          quotes,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setSetupLoginQuotes(quotes);
  };

  const updateActiveBg = async (e) => {
    clearTimeout(timer);
    const isActiveBg = e.target.value;
    timer = setTimeout(async () => {
      try {
        await setupLoginApi.create({
          isActiveBg,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setSetupLoginActive(isActiveBg);
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName);
    } else {
      compressNonHeic(newName);
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const updateImage = async (e) => {
    clearTimeout(timer);
    const image = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);
    timer = setTimeout(async () => {
      try {
        await setupLoginApi.create({
          image,
        });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setSetupLoginImage(image);
    setFileURL(null);
  };

  const convertAndCompressHeic = async (file) => {
    const blob = file;
    try {
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.8,
      });

      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });

      setFileURL(convertedFile);
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file) => {
    try {
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      });

      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });

      setFileURL(convertedFile);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {openDialogDeleteQuote && (
        <Dialog
          open={openDialogDeleteQuote}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete quote
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteQuotes(idDeleteQuote)} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          // width: "100%",
          margin: "5px 25px 0px 25px",
        }}
      >
        <Typography
          sx={{
            padding: 0,
            fontSize: "1.5rem",
            fontWeight: "500",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          🖼️ Manage Login Page
        </Typography>
        <Typography
          sx={{
            padding: 0,
            marginTop: "2px",
            fontSize: "14px",
            fontWeight: "400",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          <i>Manage Login Page in the zabran scrum board application</i>
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            m: 2,
            p: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Typography sx={{ color: "rgb(108, 108, 108)" }}>
            Quotes <span style={{ color: "red" }}>*</span>
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={setupLoginQuotes}
          >
            {[
              { id: 3, name: "None" },
              { id: 0, name: "Indonesia" },
              { id: 1, name: "Inggris" },
              { id: 2, name: "Islami" },
            ].map((value, index) => (
              <FormControlLabel
                key={index}
                value={value.id}
                onChange={(e) => {
                  updateActiveQuotes(e);
                }}
                control={<Radio />}
                label={value.name}
              />
            ))}
          </RadioGroup>
          {/* <Box
            sx={{
              width: "100%",
              gap: "1px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              value={setupLoginQuotes}
              onChange={updateQuotes}
              sx={{
                background:
                  theme.palette.mode === "dark"
                    ? assets.colors.inputTypeDark
                    : assets.colors.inputTypeLight,
                mt: 0,
              }}
            />
          </Box> */}
          <Typography sx={{ color: "rgb(108, 108, 108)" }}>
            Background <span style={{ color: "red" }}>*</span>
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={setupLoginActive}
          >
            {["image", "color"].map((value, index) => (
              <FormControlLabel
                key={index}
                value={value}
                onChange={(e) => {
                  updateActiveBg(e);
                }}
                control={<Radio />}
                label={value}
              />
            ))}
          </RadioGroup>
          {setupLoginActive === "image" ? (
            <>
              <Button
                disableElevation={true}
                variant="contained"
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  border:
                    theme.palette.mode === "dark"
                      ? "1px solid " + assets.colors.borderDark
                      : "1px solid " + assets.colors.borderLight,
                  color: "#999",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                  },
                }}
                component="label"
              >
                <CloudUploadIcon sx={{ ml: 1, mr: 1, fontSize: "22px" }} />{" "}
                Upload
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={uploadImage}
                  required
                  hidden
                />
              </Button>
              {fileURL && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ paddingTop: "5px", ml: 1 }}
                  onClick={updateImage}
                >
                  Update Image
                </Button>
              )}
              {setupLoginImage !== undefined && setupLoginImage !== "" && (
                <img
                  src={setupLoginImage}
                  alt="imageBg"
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
            </>
          ) : setupLoginActive === "color" ? (
            <MuiColorInput
              format="hex"
              value={setupLoginColor}
              onChange={updateColor}
            />
          ) : (
            ""
          )}
        </Paper>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            width: "50%",
            m: 2,
            p: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              display: "flex",
              alignItems: "center",
              color:
                theme.palette.mode === "dark"
                  ? assets.colors.rolesTextDark
                  : assets.colors.rolesTextLight,
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <FormatQuoteIcon sx={{ mr: 1 }} /> Add Quote
          </Typography>
          <Box
            component="form"
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
            noValidate
          >
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                // width: "100%",
                gap: "1px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                disabled={loading}
                error={nameErrText !== ""}
                helperText={nameErrText}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
              />
            </Box>
            <Typography sx={{ color: "rgb(108, 108, 108)" }}>
              Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                // width: "100%",
                gap: "1px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControl
                fullWidth
                size="small"
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
                error={typeErrText !== ""}
                helperText={typeErrText}
              >
                <Select
                  size="small"
                  labelId="label-position"
                  name="type"
                  required
                  fullWidth
                  id="type"
                  disabled={loading}
                  error={typeErrText !== ""}
                >
                  <MenuItem value="0">Indonesia</MenuItem>
                  <MenuItem value="1">Inggris</MenuItem>
                  <MenuItem value="2">Islami</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography sx={{ color: "rgb(108, 108, 108)", mt: 1 }}>
              Author <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                // width: "100%",
                gap: "1px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="author"
                name="author"
                disabled={loading}
                error={authorErrText !== ""}
                helperText={authorErrText}
                sx={{
                  background:
                    theme.palette.mode === "dark"
                      ? assets.colors.inputTypeDark
                      : assets.colors.inputTypeLight,
                  mt: 0,
                }}
              />
            </Box>
            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              <FormatQuoteIcon sx={{ mr: 1 }} /> Create Quote
            </LoadingButton>
          </Box>
        </Paper>
        <Paper
          sx={{
            width: "100%",
            m: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            showDialogDeleteQuote={showDialogDeleteQuote}
            requestSearch={requestSearch}
            searchFilter={searchFilter}
            selected={selected}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
              {(visibleRowsCopy.length > 0 ? visibleRowsCopy : visibleRows).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.author}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.type === 0
                          ? "Indonesia"
                          : row.type === 1
                          ? "Inggris"
                          : "Islami"}
                      </TableCell>
                      <TableCell align="right">{row.action}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <QuoteModal
        quote={selectedQuote}
        onClose={() => setSelectedQuote(undefined)}
        onUpdate={onUpdateQuote}
      />
    </>
  );
};

export default BackgroundLogin;
