import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import boardTypeApi from "../api/boardTypeApi";
import boardApi from "../api/boardApi";
import userApi from "../api/userApi";
import EmojiPicker from "../components/common/EmojiPicker";
import Kanban from "../components/common/Kanban";
import { setBoards } from "../redux/features/boardSlice";
import { setFavouriteList } from "../redux/features/favouriteSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import BarChartIcon from "@mui/icons-material/BarChart";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

let timer;
const timeout = 500;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  marginBottom: "10px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Tags = ({ data }) => {
  return (
    <Box
      sx={{
        background: "#3799e8",
        borderRadius: "10px",
        display: "flex",
        padding: "3px 6px",
        margin: "0 0.3rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography sx={{ fontSize: "14px" }}>{data}</Typography>
      </Stack>
    </Box>
  );
};

const DashboardWorkload = () => {
  const searchQuery = useOutletContext();
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [usersPosition, setUsersPosition] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [boardType, setBoardType] = useState("");
  const [boardTypeName, setBoardTypeName] = useState("");
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStartDate, setBoardStartDate] = useState(Moment());
  const [boardDueDate, setBoardDueDate] = useState(Moment());
  const [sections, setSections] = useState([]);
  const [pieSections, setPieSections] = useState("");
  const [icon, setIcon] = useState("");
  const [lineA, setLineA] = useState([]);
  const [lineB, setLineB] = useState([]);
  const [tableSprint, setTableSprint] = useState([]);
  const [tableTimeline, setTableTimeline] = useState([]);

  const getAllDaysInMonth = (month, year) =>
    Array.from(
      { length: new Date(year, month, 0).getDate() },
      (_, i) => new Date(year, month - 1, i + 1)
    );

  const boards = useSelector((state) => state.board.value);
  const favouriteList = useSelector((state) => state.favourites.value);

  useEffect(() => {
    const getBoardTypes = async () => {
      try {
        const res = await boardTypeApi.getAll();
        setBoardTypes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getBoardTypes();
  }, []);

  useEffect(() => {
    if (user.role.isManage === 1) {
      const getUsers = async () => {
        try {
          const res = await userApi.getAll(boardId);
          setUsers(res.users);
          setUsersCount(res.users.length);
          let jsonPositions = [];
          let jsonPieSections = [];
          let jsonTableSprint = [];
          let jsonTableTimeline = [];
          //   Prediction
          let jsonLineA = [];
          //   Actuallyy
          let jsonLineB = [];
          let jsonTasks = [];
          res.users.forEach((value) => {
            const userColIndex = jsonPositions.findIndex(
              (e) => e === value.position.name
            );
            if (userColIndex === -1) {
              jsonPositions.push(value.position.name);
            }
            value.sections.forEach((val, idx) => {
              if (val.title !== "GOALS") {
                if (val.title === "") {
                  val.title = "UNDEFINED";
                }
                let taskCount = 0
                val.tasks.forEach((task, idx) => {
                  const jsonTasksCheckerIndex = jsonTasks.findIndex(
                    (e) => e.id === task.id
                  );
                  if (jsonTasksCheckerIndex === -1) {
                    if (
                      dayjs(new Date(Moment())).format("YYYY-MM-DD") <=
                      dayjs(new Date(task.dueDate)).format("YYYY-MM-DD")
                    ) {
                      if (task.section.isDone != 1) {
                        const jsonTableSprintIndex = jsonTableSprint.findIndex(
                          (e) => e.id === task.id
                        );
                        if (jsonTableSprintIndex === -1) {
                          jsonTableSprint.push(task);
                        }
                      }
                    }
                    taskCount = taskCount + 1
                    jsonTasks.push(task);
                  }
                });

                const userColIndex = jsonPieSections.findIndex(
                  (e) => e.label === val.title
                );
                if (userColIndex === -1) {
                  jsonPieSections.push({
                    id: idx,
                    value: taskCount,
                    label: val.title,
                  });
                } else {
                  jsonPieSections[userColIndex].value =
                    jsonPieSections[userColIndex].value + taskCount;
                }
              }
            });
            jsonTableTimeline.push({
              name: value.name,
              timelines: [],
            });
          });

          let sortedJsonTableSprint = jsonTableSprint.sort(
            (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
          );

          setTableSprint(sortedJsonTableSprint.slice(0, 20));

          getAllDaysInMonth(Moment().format("M"), Moment().format("Y")).map(
            (x) => {
              //   const colIndex = jsonTasks.findIndex((e) => new Date(x) >= new Date(e.startDate) && new Date(x) <= new Date(e.dueDate))
              jsonLineA.push(
                jsonTasks
                  .map((e, idx) =>
                    dayjs(new Date(x)).format("YYYY-MM-DD") <=
                    dayjs(new Date(e.dueDate)).format("YYYY-MM-DD")
                      ? idx
                      : ""
                  )
                  .filter(String).length
              );
              if (new Date(Moment()) >= new Date(x)) {
                let jsonCountB = 0;
                jsonTasks.forEach((value, idx) => {
                  if (
                    dayjs(new Date(value.updatedPositionDate)).format(
                      "YYYY-MM-DD"
                    ) <= dayjs(new Date(x)).format("YYYY-MM-DD")
                  ) {
                    if (value.section.isDone != 1) {
                      jsonCountB = jsonCountB + 1;
                    }
                  } else {
                    jsonCountB = jsonCountB + 1;
                  }
                });
                jsonLineB.push(jsonCountB);
              }
              let jsonTimeline = [];
              let jsonTasksChecker = [];
              jsonTasks.forEach((value, idx) => {
                if (
                  dayjs(new Date(value.startDate)).format("YYYY-MM-DD") <=
                    dayjs(new Date(x)).format("YYYY-MM-DD") &&
                  dayjs(new Date(value.dueDate)).format("YYYY-MM-DD") >=
                    dayjs(new Date(x)).format("YYYY-MM-DD")
                ) {
                  const jsonTasksCheckerIndex = jsonTasksChecker.findIndex(
                    (e) => e.id === value.id
                  );
                  if (jsonTasksCheckerIndex === -1) {
                    jsonTasksChecker.push(value);
                    value.users.forEach((user) => {
                      jsonTimeline.push(user.name);
                    });
                  }
                }
              });

              // console.log(jsonTimeline);

              jsonTableTimeline.forEach((value, idx) => {
                let checker = 0;
                let jsonName = [];
                jsonTimeline.forEach((val, i) => {
                  if (val === value.name) {
                    checker = 1;
                    jsonName.push(val);
                  }
                });
                if (checker == 0) {
                  jsonTableTimeline[idx].timelines.push([]);
                } else {
                  jsonTableTimeline[idx].timelines.push(jsonName);
                }
              });
            }
          );
          setTableTimeline(jsonTableTimeline);
          setUsersPosition(jsonPositions);
          setPieSections(jsonPieSections);
          setLineA(jsonLineA);
          setLineB(jsonLineB);
        } catch (err) {
          console.log(err);
        }
      };
      getUsers();
    }
    const getBoard = async () => {
      try {
        const res = await boardApi.getOne(boardId);
        setTitle(res.title);
        setDescription(res.description);
        setSections(res.sections);
        if (res.boardType !== undefined) {
          setBoardType(res.boardType.id);
          setBoardTypeName(res.boardType.name);
        } else {
          setBoardType("");
          setBoardTypeName("");
        }
        setBoardStartDate(dayjs(res.startDate));
        setBoardDueDate(dayjs(res.dueDate));
        setIcon(res.icon);
      } catch (err) {
        console.log(err);
      }
    };
    getBoard();
  }, [boardId]);

  return (
    <>
      <Box sx={{ padding: "5px 5px" }}>
        <Paper sx={{ m: 2, p: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <BarChartIcon sx={{ width: "60px", height: "60px" }} />
                <Box>
                  <Box>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Sprint {title}
                    </Typography>
                  </Box>
                  <Box>
                    <span style={{ fontSize: "14px" }}>{boardTypeName}</span>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <EventAvailableIcon sx={{ width: "60px", height: "60px" }} />
                <Box>
                  <Box sx={{ padding: "2px 5px" }}>
                    Start Date :{" "}
                    <b>
                      {dayjs(new Date(boardStartDate)).format("YYYY-MM-DD")}
                    </b>
                  </Box>
                  <Box sx={{ padding: "2px 5px" }}>
                    Due Date :{" "}
                    <b>{dayjs(new Date(boardDueDate)).format("YYYY-MM-DD")}</b>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              sx={{ textAlign: "center", paddingLeft: "0px !important" }}
            >
              <Typography variant="p" id="tableTitle" component="div">
                Team Number
              </Typography>
              <Typography variant="h4" id="tableTitle" component="div">
                {usersCount}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="p"
                id="tableTitle"
                component="div"
              >
                Team
              </Typography>
              <Box
                sx={{
                  margin: "0 0.2rem 0 0",
                  display: "flex",
                  padding: "5px 0",
                }}
              >
                {usersPosition.map((data, index) => {
                  return <Tags data={data} key={index} />;
                })}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ ml: 2, p: 2 }}>
              {pieSections !== "" ? (
                <>
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Sprint Goal
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: pieSections,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    height={300}
                  />
                </>
              ) : (
                ""
              )}
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ mr: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Remaining Work
              </Typography>
              <LineChart
                width={700}
                height={300}
                series={[
                  { data: lineB, label: "Actually" },
                  { data: lineA, label: "Prediction" },
                ]}
                xAxis={[
                  {
                    scaleType: "point",
                    data: getAllDaysInMonth(
                      Moment().format("M"),
                      Moment().format("Y")
                    ).map((x) =>
                      x.toLocaleDateString([], {
                        month: "short",
                        day: "numeric",
                      })
                    ),
                  },
                ]}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ ml: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%", mb: 1 }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Sprint Goal Timeline{" "}
                {dayjs(new Date(Moment())).format("MMM YYYY")}
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {getAllDaysInMonth(
                        Moment().format("M"),
                        Moment().format("Y")
                      ).map((x, index) => (
                        <TableCell key={index} sx={{ textAlign: "center" }}>
                          {x.toLocaleDateString([], {
                            day: "numeric",
                          })}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableTimeline &&
                      tableTimeline.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            verticalAlign: "top",
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ whiteSpace: "nowrap", padding: "0px 5px" }}
                          >
                            {row.name}
                          </TableCell>
                          {row.timelines.map((x, index) => (
                            <TableCell
                              key={index}
                              sx={{
                                textAlign: "center",
                                padding: "0px",
                              }}
                            >
                              {x.map((y, index) => (
                                <Box
                                  sx={{
                                    backgroundColor: "#000",
                                    border: "1px solid #FFF",
                                  }}
                                >
                                  &nbsp;
                                </Box>
                              ))}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ mr: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%", mb: 1 }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Sprint Management
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Task Title</TableCell>
                      <TableCell>Progress Bar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableSprint &&
                      tableSprint.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {dayjs(new Date(row.dueDate)).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell>
                            {row.users.map((user) => (
                              <p>{user.name}</p>
                            ))}
                          </TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>
                            {row.taskChecklists.length !== 0 ? (
                              <BorderLinearProgress
                                variant="determinate"
                                value={
                                  (row.taskChecklists.filter((x, i) => {
                                    return x.isChecked;
                                  }).length /
                                    row.taskChecklists.length) *
                                  100
                                }
                              />
                            ) : (
                              ""
                            )}
                            {row.taskChecklists.length !== 0
                              ? `(${
                                  row.taskChecklists.filter((x, i) => {
                                    return x.isChecked;
                                  }).length
                                } / ${row.taskChecklists.length}) `
                              : ""}
                            {row.taskChecklists.length !== 0
                              ? `${(
                                  (row.taskChecklists.filter((x, i) => {
                                    return x.isChecked;
                                  }).length /
                                    row.taskChecklists.length) *
                                  100
                                ).toFixed(2)} %`
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardWorkload;
