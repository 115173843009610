import axiosClient from "./axiosClient";

const companyKpiApi = {
  create: (params) => axiosClient.post("companyKpis", params),
  getAll: (year) => axiosClient.get(`companyKpis/${year}`),
  delete: (id) => axiosClient.delete(`companyKpis/${id}`),
  update: (id, params) => axiosClient.put(`companyKpis/${id}`, params),
  getOneTask: (taskId) => axiosClient.get(`companyKpis/task/${taskId}`),
};

export default companyKpiApi;
