import axiosClient from './axiosClient'

const boardApi = {
  create: () => axiosClient.post('boards'),
  getKpiBoards: (boardTypeId, boardId, userId) => axiosClient.get(`boards/kpi/${boardTypeId}/${boardId}/${userId}`),
  createUser: (boardId, userId) => axiosClient.get(`boards/createUser/${boardId}/${userId}`),
  deleteUser: (boardId, userId) => axiosClient.get(`boards/deleteUser/${boardId}/${userId}`),
  getAll: () => axiosClient.get('boards'),
  getAllUserId: (userId) => axiosClient.get(`boards/user/${userId}`),
  updatePositoin: (params) => axiosClient.put('boards', params),
  getOne: (id) => axiosClient.get(`boards/${id}`),
  getCloneBoard: (id) => axiosClient.get(`boards/clone/${id}`),
  delete: (id) => axiosClient.delete(`boards/${id}`),
  update: (id, params) => axiosClient.put(`boards/${id}`, params),
  getFavourites: () => axiosClient.get('boards/favourites'),
  updateFavouritePosition: (params) => axiosClient.put('boards/favourites', params)
}

export default boardApi