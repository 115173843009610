import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import divisionApi from "../../api/divisionApi";
import boardTypeApi from "../../api/boardTypeApi";
import userApi from "../../api/userApi";
import DivisionModal from "../../components/common/DivisionModal";
import assets from "../../assets/index";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  RadioGroup,
  TextField,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EmojiPicker from "../../components/common/EmojiPicker";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

let timer;
const timeout = 500;

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    label: "User",
  },
  {
    id: "boardType",
    numeric: false,
    disablePadding: true,
    label: "Division",
  },
  // {
  //   id: "type",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Role",
  // },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showDialogDeleteDivision, selected } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormatListBulletedIcon sx={{ mr: 1 }} /> List User Division
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => showDialogDeleteDivision(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        // <Tooltip title="Delete">
        //   <IconButton onClick={() => showDialogDeleteDivision(selected)}>
        //     <DeleteIcon />
        //   </IconButton>
        // </Tooltip>
        <></>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Divisions = () => {
  const user = useSelector((state) => state.user.value);
  const theme = useTheme();
  const [selectedDivision, setSelectedDivision] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [boardTypes, setBoardTypes] = useState([]);
  const [icon, setIcon] = useState("📃");

  const [fileURL, setFileURL] = useState(null);

  const [loading, setLoading] = useState(false);
  const [deleteFailCount, setDeleteFailCount] = useState(0);
  const [userErrText, setUserErrText] = useState("");
  const [typeErrText, setTypeErrText] = useState("");
  const [boardTypeErrText, setBoardTypeErrText] = useState("");

  const onUpdateDivision = (division) => {
    console.log(division);
    const newData = [...rows];
    const divisionIndex = newData.findIndex((e) => e._id === division.id);
    const userIndex = users.findIndex((e) => e.id === division.user.id);
    const boardTypeIndex = boardTypes.findIndex(
      (e) => e.id === division.boardType.id
    );
    newData[divisionIndex].user = users[userIndex];
    newData[divisionIndex].boardType = boardTypes[boardTypeIndex];
    newData[divisionIndex].type = division.type === 0 ? "user" : "manager";
    setRows(newData);
  };

  const onIconChange = async (newIcon) => {
    setIcon(newIcon);
  };

  const deleteDivisions = (divisions) => {
    divisions.forEach(async (value) => {
      try {
        const deleteJson = await divisionApi.delete(value);
        if (deleteJson === "deleted") {
          const newData = [...rows].filter((e) => !divisions.includes(e.id));
          setRows(newData);
          setSelected([]);
          hideDialog();
        } else {
          setDeleteFailCount(deleteJson);
          setOpenDialogDeleteFail(true);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const getBoardTypes = async () => {
      try {
        const res = await boardTypeApi.getAll();
        setBoardTypes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getBoardTypes();

    const getUsers = async () => {
      try {
        let jsonData = [];
        const res = await userApi.getUsers();
        res.forEach((value, index) => {
          if (user.id !== value.id) {
            if (user.role.isManage === 1 && user.role.typeManage === 1) {
              if (value.role.isManage === 0) {
                jsonData.push(value);
              }
            } else {
              if (value.role.isManage === 1 && value.role.typeManage === 1) {
                jsonData.push(value);
              }
              if (value.role.isManage === 0) {
                jsonData.push(value);
              }
            }
          }
        });
        setUsers(jsonData);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    const getDivisions = async () => {
      try {
        const jsonData = [];
        const res = await divisionApi.getAll();
        res.forEach((value, index) => {
          if (user.id !== value.user.id) {
            if (user.role.isManage === 1 && user.role.typeManage === 1) {
              if (value.boardType.id === user.userDivision.boardType) {
                jsonData.push({
                  _id: value.id,
                  id: value.id,
                  user: value.user,
                  type: value.type === 0 ? "user" : "manager",
                  boardType: value.boardType,
                });
              }
            } else {
              jsonData.push({
                _id: value.id,
                id: value.id,
                user: value.user,
                type: value.type === 0 ? "user" : "manager",
                boardType: value.boardType,
                action: (
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDivision(value);
                      }}
                    >
                      <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ),
              });
            }
          }
        });
        setRows(jsonData);
      } catch (err) {
        console.log(err);
      }
    };
    getDivisions();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserErrText("");
    setBoardTypeErrText("");
    setTypeErrText("");

    const data = new FormData(e.target);
    let boardType = 0;
    if (user.role.isManage === 1 && user.role.typeManage === 1) {
      boardType = user.userDivision.boardType;
    } else {
      boardType = data.get("boardType").trim();
    }
    const userId = data.get("user").trim();

    // const type = data.get("type").trim();
    const type = 0;

    let err = false;

    if (userId === "") {
      err = true;
      setUserErrText("Please fill this field");
    }

    if (boardType === "") {
      err = true;
      setBoardTypeErrText("Please fill this field");
    }

    if (type === "") {
      err = true;
      setTypeErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await divisionApi.create({
        user: userId,
        boardType,
        type: type === "user" ? 0 : 1,
      });
      setLoading(false);
      const userIndex = users.findIndex((e) => e.id === res.user);
      const boardTypeIndex = boardTypes.findIndex(
        (e) => e.id === res.boardType
      );
      res.user = users[userIndex];
      res.boardType = boardTypes[boardTypeIndex];
      let newJsonData = [];
      if (user.role.isManage === 1 && user.role.typeManage === 1) {
        newJsonData = {
          _id: res.id,
          id: res.id,
          user: users[userIndex],
          boardType: boardTypes[boardTypeIndex],
          type: res.type === 0 ? "user" : "manager",
        };
      } else {
        newJsonData = {
          _id: res.id,
          id: res.id,
          user: users[userIndex],
          boardType: boardTypes[boardTypeIndex],
          type: res.type === 0 ? "user" : "manager",
          action: (
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDivision(res);
                }}
              >
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          ),
        };
      }

      const newData = [...rows];
      newData.unshift(newJsonData);
      console.log(newData);
      setRows(newData);
      e.target.reset();
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "user") {
          setUserErrText(e.msg);
        }
        if (e.param === "type") {
          setTypeErrText(e.msg);
        }
        if (e.param === "boardType") {
          setBoardTypeErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  const [openDialogDeleteDivision, setOpenDialogDeleteDivision] =
    useState(false);
  const [openDialogDeleteFail, setOpenDialogDeleteFail] = useState(false);
  const [idDeleteDivision, setIdDeleteDivision] = useState(false);

  const showDialogDeleteDivision = (sectionId) => {
    setOpenDialogDeleteDivision(true);
    setIdDeleteDivision(sectionId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteDivision(false);
    setOpenDialogDeleteFail(false);
  };

  return (
    <>
      {(user && user.userDivision && user.userDivision.length !== 0 ) || (user.id === "66548449189917c408a51746") ? (
        <>
          {openDialogDeleteFail && (
            <Dialog
              open={openDialogDeleteFail}
              onClose={hideDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  There are still {deleteFailCount} users using this division,
                  please check board management
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={hideDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {openDialogDeleteDivision && (
            <Dialog
              open={openDialogDeleteDivision}
              onClose={hideDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure delete division
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => deleteDivisions(idDeleteDivision)}
                  color="primary"
                >
                  Yes
                </Button>
                <Button onClick={hideDialog} color="primary">
                  No
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Box
            sx={{
              width: "100%",
              margin: "5px 25px 0px 25px",
            }}
          >
            <Typography
              sx={{
                padding: 0,
                fontSize: "1.5rem",
                fontWeight: "500",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
            >
              👥 Manage User Division
            </Typography>
            <Typography
              sx={{
                padding: 0,
                marginTop: "2px",
                fontSize: "14px",
                fontWeight: "400",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
            >
              <i>Manage all user division in the zabran scrum board application</i>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              margin: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Paper
              sx={{
                width: "50%",
                m: 2,
                p: 2,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.rolesTextDark
                      : assets.colors.rolesTextLight,
                }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <PersonAddIcon sx={{ mr: 1 }} /> Add User Division
              </Typography>
              <Box
                component="form"
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
                noValidate
              >
                {/* <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
              Role <span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="type"
            >
              {["user", "manager"].map((value, index) => (
                <FormControlLabel
                  key={index}
                  value={value}
                  control={<Radio />}
                  label={value}
                />
              ))}
            </RadioGroup> */}

                <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
                  Select User <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiFormControl-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  >
                    <Select
                      labelId="label-position"
                      name="user"
                      fullWidth
                      id="user"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": {
                          padding: "8.5px 0px 8.5px 8.5px",
                        },
                        "& .MuiFormControl-root": {
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                        },
                        "& .MuiOutlinedInput-root": {
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                        },
                        "& .MuiInputBase-root": {
                          background:
                            theme.palette.mode === "dark"
                              ? assets.colors.inputTypeDark
                              : assets.colors.inputTypeLight,
                        },
                      }}
                    >
                      {users.map((user, index) => (
                        <MenuItem key={index} value={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {user.role.isManage === 1 && user.role.typeManage !== 1 && (
                  <>
                    <Typography sx={{ mt: 1, color: "rgb(108, 108, 108)" }}>
                      Select Division <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        gap: "1px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl
                        size="small"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "8.5px 0px 8.5px 8.5px",
                          },
                          "& .MuiFormControl-root": {
                            background:
                              theme.palette.mode === "dark"
                                ? assets.colors.inputTypeDark
                                : assets.colors.inputTypeLight,
                          },
                          "& .MuiOutlinedInput-root": {
                            background:
                              theme.palette.mode === "dark"
                                ? assets.colors.inputTypeDark
                                : assets.colors.inputTypeLight,
                          },
                        }}
                      >
                        <Select
                          labelId="label-position"
                          name="boardType"
                          fullWidth
                          id="boardType"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-input": {
                              padding: "8.5px 0px 8.5px 8.5px",
                            },
                            "& .MuiFormControl-root": {
                              background:
                                theme.palette.mode === "dark"
                                  ? assets.colors.inputTypeDark
                                  : assets.colors.inputTypeLight,
                            },
                            "& .MuiOutlinedInput-root": {
                              background:
                                theme.palette.mode === "dark"
                                  ? assets.colors.inputTypeDark
                                  : assets.colors.inputTypeLight,
                            },
                            "& .MuiInputBase-root": {
                              background:
                                theme.palette.mode === "dark"
                                  ? assets.colors.inputTypeDark
                                  : assets.colors.inputTypeLight,
                            },
                          }}
                        >
                          {boardTypes.map((boardType, index) => (
                            <MenuItem key={index} value={boardType.id}>
                              {boardType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
                <LoadingButton
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                >
                  <PersonAddIcon sx={{ mr: 1 }} /> Create Division
                </LoadingButton>
              </Box>
            </Paper>
            <Paper
              sx={{
                width: "100%",
                m: 2,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
              }}
            >
              <EnhancedTableToolbar
                numSelected={selected.length}
                showDialogDeleteDivision={showDialogDeleteDivision}
                selected={selected}
              />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.user.name}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.boardType.name}
                          </TableCell>
                          {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.type}
                      </TableCell> */}
                          <TableCell align="right">{row.action}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </>
      ) : (
        <Alert
              sx={{ mb: 3 }}
              severity="info"
            >
              You have not been assigned a division, please contact admin.
            </Alert>
      )}
      <DivisionModal
        division={selectedDivision}
        users={users}
        boardTypes={boardTypes}
        onClose={() => setSelectedDivision(undefined)}
        onUpdate={onUpdateDivision}
      />
    </>
  );
};

export default Divisions;
