import axiosClient from "./axiosClient"

const authApi = {
  signup: params => axiosClient.post('auth/signup', params),
  updatePassword: params => axiosClient.post('auth/updatePassword', params),
  login: params => axiosClient.post('auth/login', params),
  logout: params => axiosClient.post('auth/logout', params),
  verifyToken: () => axiosClient.post('auth/verify-token')
}

export default authApi
