import axiosClient from "./axiosClient";

const speedIconApi = {
  create: (params) => axiosClient.post("speedIcons", params),
  getAll: () => axiosClient.get("speedIcons"),
  delete: (id) => axiosClient.delete(`speedIcons/${id}`),
  update: (id, params) => axiosClient.put(`speedIcons/${id}`, params),
};

export default speedIconApi;
