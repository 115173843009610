import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Card,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useAutocomplete,
} from "@mui/material";
import MyCustomUploadAdapterPlugin from "./ckImageUploader";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Checkbox from "@mui/material/Checkbox";
import { Cancel } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import taskApi from "../../api/taskApi";
import taskChecklistApi from "../../api/taskChecklistApi";
import iconApi from "../../api/iconApi";
import activityApi from "../../api/activityApi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import UserTaskModal from "./UserTaskModal";
import "../../css/custom-editor.css";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";

const Tags = ({ data }) => {
  return (
    <Box
      sx={{
        background: "#3799e8",
        height: "100%",
        borderRadius: "10px",
        display: "flex",
        padding: "3px 6px",
        margin: "0 0.3rem 0.5rem 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography sx={{ fontSize: "12px" }}>{data}</Typography>
      </Stack>
    </Box>
  );
};

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
    width: 300px;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
  
    &:hover {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    }
  
    &.focused {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  
    & input {
      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
      color: ${
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,0.65)"
          : "rgba(0,0,0,.85)"
      };
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
    }
  `
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: ${
      theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
    };
    border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;
  
    &:focus {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      background-color: ${
        theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
    }
  
    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
    & svg {
      font-size: 12px;
      cursor: pointer;
      padding: 4px;
    }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
    width: 300px;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  
    & li {
      padding: 5px 12px;
      display: flex;
  
      & span {
        flex-grow: 1;
      }
  
      & svg {
        color: transparent;
      }
    }
  
    & li[aria-selected='true'] {
      background-color: ${
        theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"
      };
      font-weight: 600;
  
      & svg {
        color: #1890ff;
      }
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: ${
        theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
      cursor: pointer;
  
      & svg {
        color: currentColor;
      }
    }
  `
);

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  color: "#999",
  p: 1,
  height: "100%",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const TaskModalView = (props) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user.value);
  const boardId = props.boardId;
  const [task, setTask] = useState(props.task);
  const [usersTask, setUsersTask] = useState([]);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [startDate, setStartDate] = useState(Moment());
  const [dueDate, setDueDate] = useState(Moment());
  const [priority, setPriority] = useState("");
  const [tags, setTags] = useState([]);
  const [taskIcons, setTaskIcons] = useState([]);
  const [content, setContent] = useState("");
  const [activities, setActivities] = useState([]);
  const [taskChecklists, setTaskChecklists] = useState([]);

  const getActivities = async (taskId) => {
    const res = await activityApi.getOne(boardId, taskId);
    setActivities(res);
  };

  useEffect(() => {
    setTask(props.task);
    setUsersTask(props.task !== undefined ? props.task.users : "");
    setTitle(props.task !== undefined ? props.task.title : "");
    setNote(props.task !== undefined ? props.task.note : "");
    setContent(props.task !== undefined ? props.task.content : "");
    setStartDate(
      props.task !== undefined ? dayjs(props.task.startDate) : Moment()
    );
    setDueDate(props.task !== undefined ? dayjs(props.task.dueDate) : Moment());
    setPriority(props.task !== undefined ? props.task.priority : "");
    setTags(props.task !== undefined ? props.task.tags : []);
    setTaskIcons(props.task !== undefined ? props.task.icons : []);
    setTaskChecklists(
      props.task !== undefined ? props.task.taskChecklists : []
    );
    if (props.task !== undefined) {
      isModalClosed = false;
      getActivities(props.task.id);
    }
  }, [props.task]);

  const onClose = () => {
    isModalClosed = true;
    const lastTaskUsers = task;
    task.users = usersTask;
    props.onUpdateKanban(task, lastTaskUsers);
    props.onUpdate(task);
    props.onClose();
  };

  return (
    <>
      <Modal
        open={task !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={task !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  const lastTaskUsers = task;
                  task.users = usersTask;
                  console.log(lastTaskUsers);
                  console.log(task.users);
                  props.onUpdateKanban(task, lastTaskUsers);
                  props.onUpdate(task);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                padding: "2rem 5rem 5rem",
              }}
            >
              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Task Owner &nbsp;
                <AvatarGroup max={20} sx={{ mr: 1, ml: 1 }}>
                  {task &&
                    usersTask &&
                    usersTask.map((user, index) => (
                      <Avatar
                        alt={user.name}
                        key={`avatarTask-${index}`}
                        src={user.image}
                      />
                    ))}
                </AvatarGroup>
              </Box>
              Task Title
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "2.5rem",
                  fontWeight: "700",

                  marginBottom: "10px",
                }}
              >
                {title}
              </Typography>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4}>
                      Start Date : {dayjs(startDate).format("DD MMM YYYY")}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      Due Date : {dayjs(dueDate).format("DD MMM YYYY")}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      Priority :{" "}
                      <span
                        style={{
                          padding: "3px",
                          borderRadius: "5px",
                          fontSize: "9px",
                          fontWeight: "bold",
                          width: "unset",
                          color: "#FFF",
                          backgroundColor:
                            priority === "low"
                              ? "green"
                              : priority === "medium"
                              ? "yellow"
                              : "orange",
                        }}
                      >
                        {priority.toUpperCase()}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          margin: "0 0.2rem 0 0",
                          display: "flex",
                          padding: "5px 0",
                        }}
                      >
                        Tags&nbsp;:&nbsp;{" "}
                        {tags.map((data, index) => {
                          return (
                            <Tags
                              data={data.name}
                              key={`${task.id}-${index}-tags`}
                            />
                          );
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Subtask</p>
                      </Box>
                      <Box>
                        {taskChecklists &&
                          taskChecklists.length !== 0 &&
                          taskChecklists.map((taskChecklist, index) => (
                            <Box
                              key={index}
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="body2" fontWeight="300">
                                {taskChecklist.name}
                              </Typography>
                              <Typography variant="body2" fontWeight="100">
                                {taskChecklist.type === 0 ? (
                                  <>
                                    {taskChecklist.isChecked ? (
                                      <CheckIcon />
                                    ) : (
                                      <CloseIcon />
                                    )}
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                      Progress &nbsp;
                                      <b>{taskChecklist.current}</b> /{" "}
                                      <b>{taskChecklist.goals}</b>
                                      {taskChecklist.current.toString() ===
                                      taskChecklist.goals.toString() ? (
                                        <CheckIcon sx={{ml:1}}/>
                                      ) : (
                                        <CloseIcon sx={{ml:1}} />
                                      )}
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                    </Grid>
                    {user.role.isManage === 1 && (
                      <Grid item xs={12} sm={12}>
                        Assessment
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            overflowX: "auto",
                            paddingTop: "10px",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ marginBottom: "20px" }}
                          >
                            <Grid item xs={12} sm={12}>
                              <div
                                key={task !== undefined ? task.id : ""}
                                style={{
                                  minHeight: "300px",
                                  padding: "0 10px 0 10px",
                                  border: "5px dashed #666",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "100%",
                                    marginRight: "10px",
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginBottom: "20px" }}
                                  >
                                    {taskIcons.length === 0 ? (
                                      <div
                                        style={{
                                          height: "300px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "100%",
                                          fontWeight: "bold",
                                          color: "#666",
                                        }}
                                      >
                                        <ArrowDropDownCircleIcon />
                                        &nbsp; No assessment yet
                                      </div>
                                    ) : (
                                      <>
                                        {taskIcons.map((icon, index) => (
                                          <Grid item xs={12} sm={2} key={index}>
                                            <Card
                                              sx={{
                                                boxShadow: "unset",
                                                display: "flex",
                                                backgroundImage: "unset",
                                                padding: "10px 0px 0px 0px",
                                              }}
                                            >
                                              <img
                                                src={icon}
                                                alt={index}
                                                height="50px"
                                                width="50px"
                                              />
                                            </Card>
                                          </Grid>
                                        ))}
                                      </>
                                    )}
                                  </Grid>
                                </Box>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                        {note !== "" ? (
                          <>
                            Notes&nbsp;:&nbsp;
                            <Typography
                              sx={{
                                width: "100%",
                                fontSize: "20px",
                                fontWeight: "700",
                                marginBottom: "5px",
                              }}
                            >
                              {note}
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        Assignee By :{" "}
                        <b>
                          {task &&
                            task.userAssigneeId &&
                            task.userAssigneeId.name}
                        </b>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  Content
                  <Box
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      display: "flex",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                      className="editor ck-content"
                    ></div>
                  </Box>
                  Activity
                  <Box
                    sx={{
                      marginBottom: "10px",
                      maxHeight: "100px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    {activities.map((activity, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" fontWeight="300">
                          {activity.type === 2 ? (
                            <>
                              {activity.user} {activity.message} From{" "}
                              {activity.lastSection} To {activity.section}
                            </>
                          ) : (
                            <>
                              {activity.user} {activity.message}{" "}
                              {activity.section}
                            </>
                          )}
                        </Typography>
                        <Typography variant="body2" fontWeight="100">
                          {/* {Moment(activity.createdAt)
                              .locale("id")
                              .format("DD MMM YYYY")} */}
                          {Moment(activity.createdAt)
                            .locale("id")
                            .startOf("minute")
                            .fromNow()}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default TaskModalView;
