import { Container, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
import assets from "../../assets";
import setupLoginApi from "../../api/setupLoginApi";
import quoteApi from "../../api/quoteApi";
import "../../css/font.css";

const AuthLayout = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [setupPage, setSetupPage] = useState(true);

  useEffect(() => {
    document.title = "Login - Zabran Scrum Board";
    const checkAuth = async () => {
      const isAuth = await authUtils.isAuthenticated();
      if (!isAuth) {
        setLoading(false);
        const res = await setupLoginApi.getAll();
        const resQuotes = await quoteApi.getSetupLogin();
        res.quotes = resQuotes.name;
        res.quotesAuthor = resQuotes.author;
        // if (res.quotes === undefined || res.quotes === "" || res.quotes === null) {
        //   const quote = await setupLoginApi.getQuotes();
        //   res.quotes = quote.content;
        //   res.quotesAuthor = quote.author;
        // }
        setSetupPage(res);
      } else {
        navigate("/");
      }
    };
    checkAuth();
  }, [navigate]);

  return loading ? (
    <Loading fullHeight />
  ) : (
    <Box
      sx={{
        background:
          setupPage.isActiveBg === undefined
            ? "#FFF"
            : setupPage.isActiveBg === "image"
            ? "url(" + setupPage.image + ") fixed"
            : setupPage.color,
        height: "100vh",
        backgroundRepeat: "noRepeat",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            paddingTop: 8,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              background: "rgba(0, 0, 0,0.65)",
              p: 3,
              borderRadius: "10px",
              color: "#FFF !important",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={assets.images.logoDark}
              style={{ width: "200px", marginBottom: "5px" }}
              alt="app logo"
            />
            <h1 style={{ margin: 0, padding: 0, fontWeight: 500 }}>
              Scrum Board
            </h1>
            <Outlet />
            {setupPage.quotes !== undefined && (
              <h3
                style={{
                  margin: "7px 0 7px 0",
                  padding: 0,
                  textAlign: "center",
                }}
              >
                <i style={{ fontWeight: 700, fontSize: "16px" }}>
                  " {setupPage.quotes} "
                </i>
                {setupPage.quotesAuthor !== undefined && (
                  <>
                    <br />
                    <i style={{ fontWeight: 300, fontSize: "14px" }}>
                      ~ {setupPage.quotesAuthor}
                    </i>
                  </>
                )}
              </h3>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthLayout;
