import axiosClient from "./axiosClient";

const kpiApi = {
  create: (params) => axiosClient.post("kpis", params),
  getAll: (boardTypeId, year) => axiosClient.get(`kpis/${boardTypeId}/${year}`),
  delete: (id) => axiosClient.delete(`kpis/${id}`),
  update: (id, params) => axiosClient.put(`kpis/${id}`, params),
  getOneTask: (taskId) => axiosClient.get(`kpis/task/${taskId}`),
};

export default kpiApi;
