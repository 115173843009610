import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import boardTypeApi from "../api/boardTypeApi";
import boardApi from "../api/boardApi";
import userApi from "../api/userApi";
import EmojiPicker from "../components/common/EmojiPicker";
import Kanban from "../components/common/Kanban";
import { setBoards } from "../redux/features/boardSlice";
import { setFavouriteList } from "../redux/features/favouriteSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import BarChartIcon from "@mui/icons-material/BarChart";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import assets from "../assets/index";
import { useTheme } from "@mui/material/styles";

let timer;
const timeout = 500;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  marginBottom: "10px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Tags = ({ data }) => {
  return (
    <Box
      sx={{
        background: "#333",
        borderRadius: "10px",
        display: "flex",
        padding: "3px 6px",
        margin: "0 0.3rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography sx={{ fontSize: "14px" }}>{data}</Typography>
      </Stack>
    </Box>
  );
};

const DashboardOverview = () => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const searchQuery = useOutletContext();
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [usersPosition, setUsersPosition] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [boardType, setBoardType] = useState("");
  const [boardTypeName, setBoardTypeName] = useState("");
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStartDate, setBoardStartDate] = useState(Moment());
  const [boardDueDate, setBoardDueDate] = useState(Moment());
  const [sections, setSections] = useState([]);
  const [pieSections, setPieSections] = useState("");
  const [icon, setIcon] = useState("");
  const [lineA, setLineA] = useState([]);
  const [lineB, setLineB] = useState([]);
  const [tableClose100, setTableClose100] = useState([]);
  const [tableHighSpeed, setTableHighSpeed] = useState([]);
  const [tableBestMood, setTableBestMood] = useState([]);
  const [tableAttendance, setTableAttendance] = useState([]);

  var enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [];

    var currDate = Moment(startDate).startOf("day");
    var lastDate = Moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(dayjs(currDate.clone().toDate()).format("YYYY-MM-DD"));
    }

    return dates;
  };

  const boards = useSelector((state) => state.board.value);
  const favouriteList = useSelector((state) => state.favourites.value);

  useEffect(() => {
    const getBoardTypes = async () => {
      try {
        const res = await boardTypeApi.getAll();
        setBoardTypes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getBoardTypes();
  }, []);

  useEffect(() => {
    if (user.role.isManage === 1) {
      const getUsers = async () => {
        try {
          const res = await userApi.getAll(boardId);
          setLoading(false);
          setUsers(res.users);
          setUsersCount(res.users.length);
          let jsonPositions = [];
          let jsonTableUsers = [];
          let jsonTableHighSpeed = [];
          let jsonTableLog = [];
          let jsonTableAttendance = [];
          res.users.forEach((value) => {
            const userId = value.id;
            if (value.id !== "66548449189917c408a51746") {
              const getUserMoods = async () => {
                const userMoods = await userApi.getUserMoods(userId);
                let totalScale = 0;
                let totalScaleLogout = 0;
                let totalScaleLogin = 0;
                let jsonAttendance = [];
                userMoods.forEach((mood) => {
                  if (mood.type === "logout") {
                    totalScaleLogout = totalScaleLogout + mood.mood.scale;
                  } else if (mood.type === "login") {
                    totalScaleLogin = totalScaleLogin + mood.mood.scale;
                  }
                  totalScale = totalScale + mood.mood.scale;
                  const colIndex = jsonAttendance.findIndex(
                    (e) =>
                      e === dayjs(new Date(mood.createdAt)).format("YYYY-MM-DD")
                  );
                  if (colIndex === -1) {
                    jsonAttendance.push(
                      dayjs(new Date(mood.createdAt)).format("YYYY-MM-DD")
                    );
                  }
                  console.log(
                    dayjs(new Date(mood.createdAt)).format("YYYY-MM-DD")
                  );
                });
                let attendance = 0;
                enumerateDaysBetweenDates(
                  Moment().subtract(31, "days"),
                  Moment()
                ).forEach((date) => {
                  const colIndex = jsonAttendance.findIndex((e) => e === date);
                  if (colIndex !== -1) {
                    attendance = attendance + 1;
                  }
                });
                console.log(attendance);
                console.log(
                  enumerateDaysBetweenDates(
                    Moment().subtract(31, "days"),
                    Moment()
                  ).length
                );
                let percentageScale = 0;
                let percentageScaleLogin = 0;
                let percentageScaleLogout = 0;
                if (userMoods.length !== 0) {
                  percentageScale = totalScale / userMoods.length;
                  percentageScaleLogin = totalScaleLogin / userMoods.length;
                  percentageScaleLogout = totalScaleLogout / userMoods.length;
                }

                let moodIcon = "";
                if (1 <= percentageScale && percentageScale < 2) {
                  moodIcon = "😠";
                } else if (2 <= percentageScale && percentageScale < 3) {
                  moodIcon = "☹️";
                } else if (3 <= percentageScale && percentageScale < 3.5) {
                  moodIcon = "🙂";
                } else if (3.5 <= percentageScale && percentageScale < 4.5) {
                  moodIcon = "😃";
                } else if (4.5 <= percentageScale && percentageScale <= 5) {
                  moodIcon = "🤩";
                }

                let moodIconLogin = "";
                if (1 <= percentageScale && percentageScale < 2) {
                  moodIconLogin = "😠";
                } else if (2 <= percentageScale && percentageScale < 3) {
                  moodIconLogin = "☹️";
                } else if (3 <= percentageScale && percentageScale < 3.5) {
                  moodIconLogin = "🙂";
                } else if (3.5 <= percentageScale && percentageScale < 4.5) {
                  moodIconLogin = "😃";
                } else if (4.5 <= percentageScale && percentageScale <= 5) {
                  moodIconLogin = "🤩";
                }

                let moodIconLogout = "";
                if (1 <= percentageScale && percentageScale < 2) {
                  moodIconLogout = "😠";
                } else if (2 <= percentageScale && percentageScale < 3) {
                  moodIconLogout = "☹️";
                } else if (3 <= percentageScale && percentageScale < 3.5) {
                  moodIconLogout = "🙂";
                } else if (3.5 <= percentageScale && percentageScale < 4.5) {
                  moodIconLogout = "😃";
                } else if (4.5 <= percentageScale && percentageScale <= 5) {
                  moodIconLogout = "🤩";
                }

                jsonTableLog.push({
                  name: value.name,
                  image: value.image,
                  scale: percentageScale,
                  icon: moodIcon,
                  iconLogin: moodIconLogin,
                  iconLogout: moodIconLogout,
                });

                let percentageAttendance =
                  (attendance /
                    enumerateDaysBetweenDates(
                      Moment().subtract(31, "days"),
                      Moment()
                    ).length) *
                  100;

                jsonTableAttendance.push({
                  name: value.name,
                  image: value.image,
                  present: attendance,
                  presentDate: enumerateDaysBetweenDates(
                    Moment().subtract(31, "days"),
                    Moment()
                  ).length,
                  percentage: percentageAttendance,
                });

                setTableBestMood(
                  jsonTableLog.sort((a, b) => b.scale - a.scale).slice(0, 5)
                );
                setTableAttendance(
                  jsonTableAttendance
                    .sort((a, b) => b.percentage - a.percentage)
                    .slice(0, 5)
                );
              };
              getUserMoods();

              const userColIndex = jsonPositions.findIndex(
                (e) => e === value.position.name
              );
              if (userColIndex === -1) {
                jsonPositions.push(value.position.name);
              }
              // let progDoneSpeed =
              //   ((value.totalChecklistQuick - value.totalChecklistLate) / (value.totalChecklistDone - value.totalChecklistUndone)) * 100;
              let progDoneSpeed =
                (value.totalChecklistQuick / value.totalChecklistDone) * 100;
              let progLateSpeed =
                (value.totalChecklistLate / value.totalChecklistUndone) * 100;
              jsonTableUsers.push({
                progNum: value.progNum,
                progIcon: value.progIcon,
                totalChecklist: value.totalChecklist,
                totalChecklistDone: value.totalChecklistDone,
                totalChecklistLate: value.totalChecklistLate,
                totalChecklistUndone: value.totalChecklistUndone,
                totalChecklistQuick: value.totalChecklistQuick,
                name: value.name,
                image: value.image,
                speed: value.speed,
                progDoneSpeed: progDoneSpeed,
                progLateSpeed: progLateSpeed,
              });

              if (value.speed !== "") {
                if (value.totalChecklist !== 0) {
                  jsonTableHighSpeed.push({
                    progNum: value.progNum,
                    progIcon: value.progIcon,
                    totalChecklist: value.totalChecklist,
                    totalChecklistDone: value.totalChecklistDone,
                    totalChecklistLate: value.totalChecklistLate,
                    totalChecklistUndone: value.totalChecklistUndone,
                    totalChecklistQuick: value.totalChecklistQuick,
                    name: value.name,
                    image: value.image,
                    speed: value.speed,
                    progDoneSpeed: progDoneSpeed,
                    progLateSpeed: progLateSpeed,
                  });
                }
              }
            }
          });
          setTableClose100(
            jsonTableUsers.sort((a, b) => b.progNum - a.progNum).slice(0, 5)
          );
          setTableHighSpeed(
            jsonTableHighSpeed.sort((a, b) => b.speed - a.speed).slice(0, 5)
          );
          setUsersPosition(jsonPositions);
        } catch (err) {
          console.log(err);
        }
      };
      getUsers();
    }
    if (boardId !== "all") {
      const getBoard = async () => {
        try {
          const res = await boardApi.getOne(boardId);
          setTitle(res.title);
          setDescription(res.description);
          setSections(res.sections);
          if (res.boardType !== undefined) {
            setBoardType(res.boardType.id);
            setBoardTypeName(res.boardType.name);
          } else {
            setBoardType("");
            setBoardTypeName("");
          }
          setBoardStartDate(dayjs(res.startDate));
          setBoardDueDate(dayjs(res.dueDate));
          setIcon(res.icon);
        } catch (err) {
          console.log(err);
        }
      };
      getBoard();
    }
  }, [boardId]);

  return (
    <>
      {loading === true && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
            width: "100%",
            height: "100%",
            zIndex: "9",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.5",
            margin: "-10px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ padding: "5px 5px" }}>
        {boardId !== "all" && (
          <Button
            sx={{
              ml: 2,
              mt: 1,
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              padding: "5px 10px",
            }}
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon sx={{ marginRight: "3px", fontSize: "16px" }} /> Back
          </Button>
        )}

        {boardId !== "all" && (
          <Paper sx={{ m: 2, p: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <BarChartIcon sx={{ width: "60px", height: "60px" }} />
                  <Box>
                    <Box>
                      <Typography
                        sx={{ flex: "1 1 100%" }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Team Overview {title}
                      </Typography>
                    </Box>
                    <Box>
                      <span style={{ fontSize: "14px" }}>{boardTypeName}</span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <EventAvailableIcon sx={{ width: "60px", height: "60px" }} />
                  <Box>
                    <Box sx={{ padding: "2px 5px" }}>
                      Start Date :{" "}
                      <b>
                        {dayjs(new Date(boardStartDate)).format("YYYY-MM-DD")}
                      </b>
                    </Box>
                    <Box sx={{ padding: "2px 5px" }}>
                      Due Date :{" "}
                      <b>
                        {dayjs(new Date(boardDueDate)).format("YYYY-MM-DD")}
                      </b>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                sx={{ textAlign: "center", paddingLeft: "0px !important" }}
              >
                <Typography variant="p" id="tableTitle" component="div">
                  Team Number
                </Typography>
                <Typography variant="h4" id="tableTitle" component="div">
                  {usersCount}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="p"
                  id="tableTitle"
                  component="div"
                >
                  Team
                </Typography>
                <Box
                  sx={{
                    margin: "0 0.2rem 0 0",
                    display: "flex",
                    padding: "5px 0",
                  }}
                >
                  {usersPosition.map((data, index) => {
                    return <Tags data={data} key={index} />;
                  })}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ ml: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Most Diligent Attendance
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Present</TableCell>
                      <TableCell align="center">Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableAttendance &&
                      tableAttendance.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              alt={row.name}
                              src={row.image}
                              sx={{
                                "&:hover": {
                                  opacity: 0.9,
                                  backgroundColor: "#000",
                                },
                              }}
                            />
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            {row.present} / {row.presentDate}
                          </TableCell>
                          <TableCell align="center">
                            {row.percentage.toFixed(2)}%
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ mr: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Best Mood
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Outside Work</TableCell>
                      <TableCell align="center">Inside Work</TableCell>
                      {/* <TableCell align="center">Mood</TableCell> */}
                      <TableCell align="center">Scale</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableBestMood &&
                      tableBestMood.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              alt={row.name}
                              src={row.image}
                              sx={{
                                "&:hover": {
                                  opacity: 0.9,
                                  backgroundColor: "#000",
                                },
                              }}
                            />
                            {row.name}
                          </TableCell>
                          <TableCell align="center">{row.iconLogin}</TableCell>
                          <TableCell align="center">{row.iconLogout}</TableCell>
                          <TableCell align="center">
                            {row.scale.toFixed(2)} / 5
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ ml: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%", mb: 1 }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Highest Working Speed
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {/* <TableCell align="center">Task Undone Late</TableCell>
                      <TableCell align="center">Task Undone</TableCell> */}
                      {/* <TableCell align="center">Task Done Quick</TableCell>
                      <TableCell align="center">Task Done</TableCell> */}
                      <TableCell align="center">Task</TableCell>
                      <TableCell align="left">Icon</TableCell>
                      <TableCell align="center">Speed Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableHighSpeed &&
                      tableHighSpeed.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              alt={row.name}
                              src={row.image}
                              sx={{
                                "&:hover": {
                                  opacity: 0.9,
                                  backgroundColor: "#000",
                                },
                              }}
                            />
                            {row.name}
                          </TableCell>
                          {/* <TableCell align="center">
                            {row.totalChecklistLate}
                          </TableCell>
                          <TableCell align="center">
                            {row.totalChecklistUndone}
                          </TableCell> */}
                          {/* <TableCell align="center">
                            {row.totalChecklistQuick}
                          </TableCell>
                          <TableCell align="center">
                            {row.totalChecklistDone}
                          </TableCell> */}
                          <TableCell align="center">
                            {row.totalChecklist}
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{ transform: "scale(-1, 1)", fontSize: "20px" }}
                          >
                            {row.progIcon}
                          </TableCell>
                          <TableCell align="center">
                            {row.speed &&
                              row.speed !== "" &&
                              row.speed.toFixed(0)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "5px 5px" }}>
            <Paper sx={{ mr: 2, p: 2 }}>
              <Typography
                sx={{ flex: "1 1 100%", mb: 1 }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Closest to 100% Progress
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Task Done</TableCell>
                      <TableCell align="center">Task</TableCell>
                      <TableCell>Progress</TableCell>
                      <TableCell align="center">Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableClose100 &&
                      tableClose100.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              alt={row.name}
                              src={row.image}
                              sx={{
                                "&:hover": {
                                  opacity: 0.9,
                                  backgroundColor: "#000",
                                },
                              }}
                            />
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            {row.totalChecklistDone}
                          </TableCell>
                          <TableCell align="center">
                            {row.totalChecklist}
                          </TableCell>
                          <TableCell>
                            <BorderLinearProgress
                              variant="determinate"
                              value={row.progNum}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.progNum !== undefined &&
                              row.progNum !== null &&
                              row.progNum.toFixed(1)}
                            %
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardOverview;
