import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Badge,
  Avatar,
  Stack,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import boardApi from "../../api/boardApi";
import userApi from "../../api/userApi";
import rewardApi from "../../api/rewardApi";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  height: "80%",
  color: "#999",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const BoardModal = (props) => {
  const [board, setBoard] = useState(props.board);
  const [rewards, setRewards] = useState(props.rewards);
  const [name, setName] = useState("");
  const [boardId, setBoardId] = useState("");
  const [users, setUsers] = useState("");
  const [team, setTeam] = useState("");

  const [openDialogDeleteUserBoard, setOpenDialogDeleteUserBoard] =
    useState(false);
  const [userIdDelete, setUserIdDelete] = useState("");
  const showDialogDeleteUserBoard = (userId) => {
    setOpenDialogDeleteUserBoard(true);
    setUserIdDelete(userId);
  };
  const hideDialog = () => {
    setOpenDialogDeleteUserBoard(false);
  };

  useEffect(() => {
    setBoard(props.board);
    setRewards(props.rewards);
    setName(props.board !== undefined ? props.board.name : "");
    setBoardId(props.board !== undefined ? props.board.id : "");
    if (props.board !== undefined) {
      isModalClosed = false;
      const getUsers = async () => {
        try {
          const res = await userApi.getAll(props.board.id);
          setUsers(res.users.sort((a, b) => b.progNum - a.progNum));
          setTeam(res.progTeam);
        } catch (err) {
          console.log(err);
        }
      };
      getUsers();
    }
  }, [props.board]);

  const onClose = () => {
    isModalClosed = true;
    setUsers("");
    props.onUpdate(board);
    props.onClose();
  };

  const deleteUserReward = async (userId) => {
    const boardId = board.id;
    try {
      if (userId !== "") {
        await rewardApi.deleteUserReward(boardId, userId);
        const colIndexUser = users.findIndex((e) => e.id === userId);
        users[colIndexUser].reward = null;
        setUsers([...users]);
      }
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const createReward = async (e, board, user) => {
    clearTimeout(timer);
    const reward = e.target.value;
    timer = setTimeout(async () => {
      try {
        await rewardApi.createUserReward({ reward, board, user });
        const colIndexReward = rewards.findIndex((e) => e.id === reward);
        const rewardData = rewards[colIndexReward];
        const colIndexUser = users.findIndex((e) => e.id === user);
        users[colIndexUser].reward = rewardData;
        setUsers([...users]);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateBoardReward = async (e, board) => {
    clearTimeout(timer);
    const boardId = board.id;
    let rewardId = e.target.value;
    timer = setTimeout(async () => {
      try {
        const board = await rewardApi.updateBoardReward(boardId, rewardId);
        setBoard(board);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  return (
    <>
      {openDialogDeleteUserBoard && (
        <Dialog
          open={openDialogDeleteUserBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete reward
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteUserReward(userIdDelete)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Modal
        open={board !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={board !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
                p: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                Setting Reward
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  setUsers("");
                  props.onUpdate(board);
                  props.onClose();
                }}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                mb: 1,
                pl: 2,
                pr: 2,
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: 0 }}>
                Team
              </Typography>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 1,
                      marginTop: "10px",
                    }}
                  >
                    <AssignmentTurnedInIcon />
                    {team.totalCheckDone}/{team.totalCheck}
                    <b style={{ ml: 1 }}>
                      {team.num !== undefined ? team.num !== null ? team.num.toFixed(1) : 0 : 0}%
                    </b>
                    <Typography
                      sx={{
                        transform: "scale(-1, 1)",
                        marginTop: "-5px",
                      }}
                      color="inherit"
                      variant="h5"
                      component="div"
                    >
                      {team.icon}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  {board !== undefined && board.reward !== undefined && board.reward === null ? (
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Select Reward
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Reward"
                        onChange={(e) => updateBoardReward(e, board)}
                      >
                        {rewards.map((reward, index) => (
                          <MenuItem key={index} value={reward.id}>
                            {reward.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%",
                        gap: 1,
                        marginTop: "5px",
                      }}
                    >
                      {board !== undefined && (
                        <>
                          <b>
                            {board.reward.name}
                          </b>
                          <Tooltip title="Delete Reward">
                            <Button
                              variant="contained"
                              sx={{
                                padding: "8px 10px",
                                minWidth: "unset",
                              }}
                              color="error"
                              disableElevation={true}
                              onClick={(e) => updateBoardReward(e, board)}
                            >
                              <DeleteOutlinedIcon />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                width: "100%",
                mb: 1,
                pl: 2,
                pr: 2,
                pt: 1,
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: 2 }}>
                Individual
              </Typography>
              {users &&
                users.length !== 0 &&
                users.map((user, index) => (
                  <Box
                    key={`${index}-users`}
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px 0px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: 1,
                          }}
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <Typography>{user.mood.icon}</Typography>
                            }
                          >
                            <Avatar
                              alt={user.name}
                              src={user.image}
                              sx={{
                                "&:hover": {
                                  opacity: 0.9,
                                  backgroundColor: "#000",
                                },
                              }}
                            />
                          </Badge>
                          <Typography
                            sx={{
                              flex: "1 1 100%",
                              fontSize: "16px",
                              fontWeight: 700,
                            }}
                            color="inherit"
                            // variant="h6"
                            component="div"
                          >
                            {user.name}{" "}
                            <Typography
                              sx={{ flex: "1 1 100%", fontSize: "12px" }}
                              color="inherit"
                              component="div"
                            >
                              {user.position.name}
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            gap: 1,
                            marginTop: "10px",
                          }}
                        >
                          <AssignmentTurnedInIcon />
                          {user.totalChecklistDone}/{user.totalChecklist}
                          <b style={{ ml: 1 }}>
                            {user.progNum !== null
                              ? user.progNum.toFixed(1)
                              : 0}
                            %
                          </b>
                          <Typography
                            sx={{
                              transform: "scale(-1, 1)",
                              marginTop: "-5px",
                            }}
                            color="inherit"
                            variant="h5"
                            component="div"
                          >
                            {user.progIcon}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {user.reward === null ? (
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Select Reward
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Select Reward"
                              onChange={(e) =>
                                createReward(e, board.id, user.id)
                              }
                            >
                              {rewards.map((reward, index) => (
                                <MenuItem key={index} value={reward.id}>
                                  {reward.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "100%",
                              gap: 1,
                              marginTop: "5px",
                            }}
                          >
                            <b>{user.reward.name}</b>
                            <Tooltip title="Delete Reward">
                              <Button
                                variant="contained"
                                sx={{
                                  padding: "8px 10px",
                                  minWidth: "unset",
                                }}
                                color="error"
                                disableElevation={true}
                                onClick={(e) =>
                                  showDialogDeleteUserBoard(user.id)
                                }
                              >
                                <DeleteOutlinedIcon />
                              </Button>
                            </Tooltip>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default BoardModal;
