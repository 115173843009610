import axiosClient from '../../api/axiosClient'

class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {

                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });

                return toBase64(file).then(cFile=>{
                    const article = {
                        imageBinary: cFile
                    };
    
                    return  axiosClient.post('https://zboard-dev.zabran.id/api/v1/boards/1/tasks/image-content-upload', article)
                    .then((d) => {
                        console.log(d);
                        if (d.uploaded) {
                            this.loader.uploaded = true;
                            resolve( {
                                default: d.url
                            } );
                        } else {
                            reject(`Couldn't upload file: ${ file.name }.`)
                        }
                    });
                })
                
                // return toBase64(file).then(cFile=>{
                //     const article = {
                //         imageBinary: cFile
                //     };

                //     return  axiosClient.post('http://127.0.0.1:5000/api/v1/boards/1/tasks/image-content-upload', article)
                //     .then((d) => {
                //         console.log(d);
                //         if (d.status) {
                //             this.loader.uploaded = true;
                //             resolve( {
                //                 default: d.response.url
                //             } );
                //         } else {
                //             reject(`Couldn't upload file: ${ file.name }.`)
                //         }
                //     });
                // })
                
            } ) );
    }

   
}

// ...

export default function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter( loader );
    };
}