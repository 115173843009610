import axiosClient from "./axiosClient";

const userApi = {
  getAll: (id) => axiosClient.get(`users/${id}`),
  getUsers: () => axiosClient.get(`users`),
  getOne: (userId, startDate, dueDate) => axiosClient.get(`users/user/${userId}/${startDate}/${dueDate}`),
  delete: (taskId) => axiosClient.delete(`users/${taskId}`),
  update: (taskId, params) => axiosClient.put(`users/${taskId}`, params),
  getUserMoods: (userId) => axiosClient.get(`users/userMoods/${userId}`),
};

export default userApi;
