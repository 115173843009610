import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Drawer,
  IconButton,
  List,
  ListItem,
  Divider,
  ListItemButton,
  Typography,
  Avatar,
  Stack,
  Badge,
} from "@mui/material";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import assets from "../../assets/index";
import { useEffect, useState } from "react";
import boardApi from "../../api/boardApi";
import menuApi from "../../api/menuApi";
import { setBoards } from "../../redux/features/boardSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTheme } from "@mui/material/styles";
import FavouriteList from "./FavouriteList";

const Sidebar = (props) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user.value);
  const boards = useSelector((state) => state.board.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const [menus, setMenus] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [openDialogCreateBoard, setOpenDialogCreateBoard] = useState(false);
  const sidebarWidth = props.sidebarWidth;
  const location = useLocation();
  const tmp =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const tmp2 =
    location.pathname.split("/")[location.pathname.split("/").length - 2];

  useEffect(() => {
    if (
      user.role.isManage !== 1 ||
      (user.role.isManage === 1 && user.role.typeManage === 1)
    ) {
      const getBoards = async () => {
        try {
          let res = "";
          res = await boardApi.getAllUserId(user.id);
          dispatch(setBoards(res));
        } catch (err) {
          console.log(err);
        }
      };
      getBoards();
    }
  }, [dispatch]);

  useEffect(() => {
    const getMenus = async () => {
      try {
        const res = await menuApi.getAll();
        setMenus(res);
      } catch (err) {
        console.log(err);
      }
    };
    getMenus();
  }, []);

  useEffect(() => {
    if (user.role.isManage === 1) {
      let activeItem = boards.findIndex((e) => e.id === boardId);

      if (activeItem === -1) {
        activeItem = menus.findIndex((e) => e.url === tmp);
        if (activeItem === -1) {
          console.log(tmp2);
          if (tmp2 === "company_roadmaps") {
            activeItem = "menu-list-companyRoadmaps";
          }
        } else {
          activeItem = `menu-${activeItem}`;
        }
      }
      setActiveIndex(activeItem);
    } else {
      console.log(tmp2);
      let activeItem = "";
      if (tmp2 === "company_roadmaps") {
        activeItem = "menu-list-companyRoadmaps";
      } else if (tmp === "positions") {
        activeItem = "menu-list-companyStructures";
      } else {
        if (boards.length > 0 && boardId === undefined) {
          navigate(`/boards/${boards[0].id}`);
        }
        activeItem = boards.findIndex((e) => e.id === boardId);
      }
      setActiveIndex(activeItem);
    }
  }, [boards, boardId, navigate]);

  const onDragEnd = async ({ source, destination }) => {
    const newList = [...boards];
    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);

    const activeItem = newList.findIndex((e) => e.id === boardId);
    setActiveIndex(activeItem);
    dispatch(setBoards(newList));

    try {
      await boardApi.updatePositoin({ boards: newList });
    } catch (err) {
      console.log(err);
    }
  };

  const addBoard = async () => {
    try {
      const res = await boardApi.create();
      const newList = [res, ...boards];
      dispatch(setBoards(newList));
      navigate(`/boards/${res.id}`);
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const showDialogCreateBoard = () => {
    setOpenDialogCreateBoard(true);
  };

  const hideDialog = () => {
    setOpenDialogCreateBoard(false);
  };

  return (
    <>
      {openDialogCreateBoard && (
        <Dialog
          open={openDialogCreateBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create board
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={addBoard} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Drawer
        container={window.document.body}
        variant="permanent"
        open={true}
        sx={{
          width: sidebarWidth,
          height: "100vh",
          "& > div": {
            borderRight:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
            top: "65px",
            scrollbarWidth: "thin",
          },
        }}
      >
        <List
          disablePadding
          sx={{
            width: sidebarWidth,
            height: "100vh",
            backgroundColor:
              theme.palette.mode === "dark"
                ? assets.colors.sidebarDarkBg
                : assets.colors.sidebarLightBg,
          }}
        >
          <ListItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "10px 0px",
                justifyContent: "space-between",
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={<Typography>{user.mood.icon}</Typography>}
                >
                  <Avatar alt={user.name} src={user.image} />
                </Badge>
                {/* <Avatar alt={user.name} src={user.image} /> */}
                <Typography
                  sx={{ flex: "1 1 100%", fontSize: "14px" }}
                  color="inherit"
                  variant="h6"
                  component="div"
                >
                  {user.name}
                  <br />
                  <span style={{ fontWeight: "400", fontSize: "12px" }}>
                    {user.position.name}
                  </span>
                </Typography>
              </Stack>
            </Box>
          </ListItem>
          {user.role.isManage === 1 && (
            <>
              <Divider
                sx={{
                  marginTop: "0px",
                  borderBottom:
                    theme.palette.mode === "dark"
                      ? "0.5px solid " + assets.colors.borderDark
                      : "0.5px solid " + assets.colors.borderLight,
                }}
              ></Divider>
              <ListItem>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight="700"
                    sx={{
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.sidebarTextDark
                          : assets.colors.sidebarTextLight,
                    }}
                  >
                    Dashboard
                  </Typography>
                </Box>
              </ListItem>
              <ListItemButton
                selected={"menu-list-companyRoadmaps" === activeIndex}
                component={Link}
                to={`/dashboard/all`}
                sx={{
                  pl: "20px",
                  cursor: "pointer!important",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="400"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.sidebarTextDark
                        : assets.colors.sidebarTextLight,
                  }}
                >
                  📊 Sprint Management
                </Typography>
              </ListItemButton>
              <ListItemButton
                selected={"menu-list-companyRoadmaps" === activeIndex}
                component={Link}
                to={`/dashboard_overview/all`}
                sx={{
                  pl: "20px",
                  cursor: "pointer!important",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="400"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.sidebarTextDark
                        : assets.colors.sidebarTextLight,
                  }}
                >
                  📊 Individual Overview
                </Typography>
              </ListItemButton>
              <ListItemButton
                selected={"menu-list-companyRoadmaps" === activeIndex}
                component={Link}
                to={`/dashboard_company_kpi`}
                sx={{
                  pl: "20px",
                  cursor: "pointer!important",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="400"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.sidebarTextDark
                        : assets.colors.sidebarTextLight,
                  }}
                >
                  📊 Company's KPI
                </Typography>
              </ListItemButton>
              <ListItemButton
                selected={"menu-list-companyRoadmaps" === activeIndex}
                component={Link}
                to={`/dashboard_kpi`}
                sx={{
                  pl: "20px",
                  cursor: "pointer!important",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="400"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.sidebarTextDark
                        : assets.colors.sidebarTextLight,
                  }}
                >
                  📊 Division's KPI
                </Typography>
              </ListItemButton>
              <ListItemButton
                selected={"menu-list-companyRoadmaps" === activeIndex}
                component={Link}
                to={`/dashboard_hr`}
                sx={{
                  pl: "20px",
                  cursor: "pointer!important",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight="400"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color:
                      theme.palette.mode === "dark"
                        ? assets.colors.sidebarTextDark
                        : assets.colors.sidebarTextLight,
                  }}
                >
                  📊 HR
                </Typography>
              </ListItemButton>
            </>
          )}
          <Divider
            sx={{
              border:
                theme.palette.mode === "dark"
                  ? "0.5px solid " + assets.colors.secondary
                  : "0.5px solid " + assets.colors.borderLight,
            }}
          ></Divider>
          {user.role.isManage === 1 && user.role.typeManage !== 1 ? (
            <FavouriteList />
          ) : (
            <>
              <ListItem>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight="700"
                    sx={{
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.sidebarTextDark
                          : assets.colors.sidebarTextLight,
                    }}
                  >
                    Boards
                  </Typography>
                  {/* {user.role.isManage === 1 && ( */}
                    <IconButton onClick={showDialogCreateBoard}>
                      <AddBoxOutlinedIcon
                        fontSize="small"
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.sidebarTextDark
                              : assets.colors.sidebarTextLight,
                        }}
                      />
                    </IconButton>
                  {/* )} */}
                </Box>
              </ListItem>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  key={"list-board-droppable-key"}
                  droppableId={"list-board-droppable"}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {boards.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <ListItemButton
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              selected={index === activeIndex}
                              component={Link}
                              to={`/boards/${item.id}`}
                              sx={{
                                pl: "20px",
                                cursor: snapshot.isDragging
                                  ? "grab"
                                  : "pointer!important",
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight="400"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.sidebarTextDark
                                      : assets.colors.sidebarTextLight,
                                }}
                              >
                                {item.icon} {item.title}{" "}
                                {item.boardType && (
                                  <>
                                    <br />
                                    <span
                                      style={{
                                        marginLeft: "22px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item.boardType.name === ""
                                        ? item.boardType
                                        : item.boardType.name}
                                    </span>
                                  </>
                                )}
                              </Typography>
                            </ListItemButton>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}

          <Divider
            sx={{
              marginTop: "10px",
              borderBottom:
                theme.palette.mode === "dark"
                  ? "0.5px solid " + assets.colors.borderDark
                  : "0.5px solid " + assets.colors.borderLight,
            }}
          ></Divider>
          <ListItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="700"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.sidebarTextDark
                      : assets.colors.sidebarTextLight,
                }}
              >
                List
              </Typography>
            </Box>
          </ListItem>
          <ListItemButton
            selected={"menu-list-companyRoadmaps" === activeIndex}
            component={Link}
            to={`/dashboard/company_roadmaps/all`}
            sx={{
              pl: "20px",
              cursor: "pointer!important",
            }}
          >
            <Typography
              variant="body2"
              fontWeight="400"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.sidebarTextDark
                    : assets.colors.sidebarTextLight,
              }}
            >
              🛣️ Roadmap
            </Typography>
          </ListItemButton>
          {user.role.isManage === 0 && (
            <ListItemButton
              selected={"menu-list-companyStructures" === activeIndex}
              component={Link}
              to={`/dashboard/positions`}
              sx={{
                pl: "20px",
                cursor: "pointer!important",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="400"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.sidebarTextDark
                      : assets.colors.sidebarTextLight,
                }}
              >
                🏢 Company Structure
              </Typography>
            </ListItemButton>
          )}
          {user.role.isManage === 1 && (
            <>
              <Divider
                sx={{
                  marginTop: "10px",
                  borderBottom:
                    theme.palette.mode === "dark"
                      ? "0.5px solid " + assets.colors.borderDark
                      : "0.5px solid " + assets.colors.borderLight,
                }}
              ></Divider>
              <ListItem>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight="700"
                    sx={{
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.sidebarTextDark
                          : assets.colors.sidebarTextLight,
                    }}
                  >
                    Management
                  </Typography>
                </Box>
              </ListItem>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  key={"list-menu-droppable-key"}
                  droppableId={"list-menu-droppable"}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {menus.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={`menu-${index}`}
                        >
                          {(provided, snapshot) => (
                            <ListItemButton
                              selected={`menu-${index}` === activeIndex}
                              component={Link}
                              to={`/dashboard/${item.url}`}
                              sx={{
                                pl: "20px",
                                cursor: snapshot.isDragging
                                  ? "grab"
                                  : "pointer!important",
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontWeight="400"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color:
                                    theme.palette.mode === "dark"
                                      ? assets.colors.sidebarTextDark
                                      : assets.colors.sidebarTextLight,
                                }}
                              >
                                {item.icon} {item.title}
                              </Typography>
                            </ListItemButton>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
          <ListItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mb: 7,
                justifyContent: "space-between",
              }}
            ></Box>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
