import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './css/custom-scrollbar.css'
import CssBaseLine from '@mui/material/CssBaseline'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppLayout from './components/layout/AppLayout'
import AuthLayout from './components/layout/AuthLayout'
import Home from './pages/Home'
import Users from './pages/Dashboard/Users'
import Roles from './pages/Dashboard/Roles'
import Board from './pages/Board'
import Dashboard from './pages/Dashboard'
import DashboardOverview from './pages/DashboardOverview'
import DashboardWorkload from './pages/DashboardWorkload'
import Signup from './pages/Signup'
import Login from './pages/Login'
import CompanyRoadmap from './pages/Dashboard/CompanyRoadmap'
import CompanyRoadmapDetail from './pages/Dashboard/CompanyRoadmapDetail'
import Positions from './pages/Dashboard/Positions'
import Boards from './pages/Dashboard/Boards'
import BackgroundLogin from './pages/Dashboard/BackgroundLogin'
import Rewards from './pages/Dashboard/Rewards'
import Assessments from './pages/Dashboard/Assessments'
import SpeedIcons from './pages/Dashboard/SpeedIcon'
import DashboardKPI from './pages/DashboardKPI'
import Kpis from './pages/Dashboard/kpi'
import Divisions from './pages/Dashboard/Divisions'
import CompanyKpis from './pages/Dashboard/CompanyKpis'
import DashboardCompanyKPI from './pages/DashboardCompanyKpi'
import DashboardHr from './pages/DashboardHr'

function App() {
  return (
    <>
      <CssBaseLine />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            <Route path='signup' element={<Signup />} />
          </Route>
          <Route path='/' element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path='boards' element={<Home />} />
            <Route path='boards/:boardId' element={<Board />} />
            <Route path='dashboard_hr/' element={<DashboardHr />} />
            <Route path='dashboard_kpi/' element={<DashboardKPI />} />
            <Route path='dashboard_company_kpi/' element={<DashboardCompanyKPI />} />
            <Route path='dashboard/:boardId' element={<Dashboard />} />
            <Route path='dashboard_workload/:boardId' element={<DashboardWorkload />} />
            <Route path='dashboard_overview/:boardId' element={<DashboardOverview />} />
            <Route path='dashboard/users' element={<Users />} />
            <Route path='dashboard/kpi' element={<Kpis />} />
            <Route path='dashboard/company_kpi' element={<CompanyKpis />} />
            <Route path='dashboard/assessments' element={<Assessments />} />
            <Route path='dashboard/speed_icons' element={<SpeedIcons />} />
            <Route path='dashboard/roles' element={<Roles />} />
            <Route path='dashboard/rewards' element={<Rewards />} />
            <Route path='dashboard/divisions' element={<Divisions />} />
            <Route path='dashboard/positions' element={<Positions />} />
            <Route path='dashboard/boards' element={<Boards />} />
            <Route path='dashboard/background_login' element={<BackgroundLogin />} />
            <Route path='dashboard/company_roadmaps' element={<CompanyRoadmap />} />
            <Route path='dashboard/company_roadmaps/:noteId' element={<CompanyRoadmapDetail />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
