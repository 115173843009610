import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import divisionApi from "../../api/divisionApi";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
  height: "35%",
  color: "#999",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const DivisionModal = (props) => {
  const theme = useTheme();
  const [division, setDivision] = useState(props.division);
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [boardType, setBoardType] = useState("");
  const users= props.users;
  const boardTypes = props.boardTypes;

  useEffect(() => {
    console.log(props)
    // setUsers(props.users);
    // setBoardTypes(props.boardTypes);
    setDivision(props.division);
    setType(props.division !== undefined ? props.division.type === 0 ? "user" : "manager" : "");
    setUser(props.division !== undefined ? props.division.user.id : "");
    setBoardType(props.division !== undefined ? props.division.boardType.id : "");
    if (props.division !== undefined) {
      isModalClosed = false;
    }
  }, [props.division]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(division);
    props.onClose();
  };

  const updateUser = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await divisionApi.update(division.id, { user: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    const userIndex = users.findIndex(
      (e) => e.id === newName
    );
    division.user = users[userIndex];
    setUser(newName);
    props.onUpdate(division);
  };

  const updateBoardType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await divisionApi.update(division.id, { boardType: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    const boardTypeIndex = boardTypes.findIndex(
      (e) => e.id === newName
    );
    division.boardType = boardTypes[boardTypeIndex];
    setBoardType(newName);
    props.onUpdate(division);
  };

  const updateType = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value === "user" ? 0 : 1;
    timer = setTimeout(async () => {
      try {
        await divisionApi.update(division.id, { type: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    division.type = newName;
    setType(e.target.value);
    props.onUpdate(division);
  };

  return (
    <>
      <Modal
        open={division !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={division !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(division);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                padding: "0rem 2rem",
              }}
            >
              {/* <Box sx={{ mt: 1 }}>
                Role
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="type"
                  value={type}
                  onChange={updateType}
                >
                  {["user", "manager"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </Box> */}
              <Box sx={{ mt: 1 }}>
                Select User
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiFormControl-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                >
                  <Select
                    labelId="label-position"
                    name="user"
                    fullWidth
                    id="user"
                    value={user}
                    onChange={updateUser}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiFormControl-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiInputBase-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  >
                    {users.map((user, index) => (
                      <MenuItem key={index} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 1 }}>
                Division
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiFormControl-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                >
                  <Select
                    labelId="label-position"
                    name="boardType"
                    value={boardType}
                    fullWidth
                    id="boardType"
                    onChange={updateBoardType}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiFormControl-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiInputBase-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  >
                    {boardTypes.map((boardType, index) => (
                      <MenuItem key={index} value={boardType.id}>
                        {boardType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default DivisionModal;
